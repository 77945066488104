import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import GridItem from '../../../../layout/GridComponents/GridItem';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Rectangle,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { greys, mainColors } from '../../../../../styling/theme';
import {
  percentageToNdecialPlaces,
  percentageToNdecialPlacesAddZeros,
} from '../../../../../utilities/numberFormatters';
import { DataObject } from '../../../../../types/redux/data/dataTypes';
import { useDispatch } from 'react-redux';
import usePngFromRecharts from '../../../../../hooks/usePngFromRecharts';
import { addComponentToPdfExport } from '../../../../../redux/fileExport/actions';
import { PdfComponentType } from '../../../../../types/redux/pdfExports/FileExportsStore';
import FileSaver from 'file-saver';
import ExportButton from '../../../../feedback/ExportButton';
import ChartDownloadButton from '../../../../buttons/ChartDownloadButton';

interface SectorReturnAnalysisChartProps {
  data: any;
}

interface SectorReturnAnalysisChartData {
  sector: string;
  fund_sector_returns: number;
  benchmark_sector_returns: number;
  excess_returns: number;
}

const useStyles = makeStyles(() => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '1rem 2.5rem 1rem 1.5rem',
  },
  cardTitle: {
    color: mainColors.mainBlue,
    fontSize: '2.2rem',
    fontWeight: 400,
    margin: '1rem 1.5rem',
  },
  customTooltipContainer: {
    backgroundColor: 'white',
    padding: '1rem 1.5rem',
    borderRadius: '0.5rem',
    border: `1px solid ${mainColors.mainBlue}`,
    width: '30rem',
    color: mainColors.mainBlue,
  },
  tooltipName: {
    fontSize: '2rem',
    fontWeight: 500,
  },
  tooltipItem: {
    fontSize: '1.6rem',
    fontWeight: 600,
    color: mainColors.mainBlue,
    display: 'flex',
    justifyContent: 'space-between',
  },
  exportButtons: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const CustomTooltip = ({ active, payload, label }: any) => {
  const classes = useStyles();
  if (active && payload && payload.length) {
    return (
      <div className={classes.customTooltipContainer}>
        <div>
          <div className={classes.tooltipName}>{label}</div>
          <hr />
          <div
            className={classes.tooltipItem}
            style={{ color: mainColors.mainBlue }}
          >
            <div>Fund Returns:</div>
            <div>
              {percentageToNdecialPlacesAddZeros(
                payload.find((item: any) => item.name === 'fund_sector_returns')
                  .value / 100,
                2,
              )}
            </div>
          </div>
          <div className={classes.tooltipItem} style={{ color: '#AA3FCC' }}>
            <div>Benchmark Returns:</div>
            <div>
              {percentageToNdecialPlacesAddZeros(
                payload.find(
                  (item: any) => item.name === 'benchmark_sector_returns',
                ).value / 100,
                2,
              )}
            </div>
          </div>
          <div
            className={classes.tooltipItem}
            style={{
              color:
                payload.find((item: any) => item.name === 'excess_returns')
                  ?.value >= 0
                  ? mainColors.Pass
                  : mainColors.Fail,
            }}
          >
            <div>Active Returns:</div>
            <div>
              {percentageToNdecialPlacesAddZeros(
                payload.find((item: any) => item.name === 'excess_returns')
                  .value / 100,
                2,
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

const CustomPLBar = (props: any) => {
  let fill = props.fill;

  if (props.value >= 0) {
    fill = mainColors.Pass;
  } else {
    fill = mainColors.Fail;
  }

  return <Rectangle {...props} fill={fill} />;
};

const buildChartData = (
  inputData: DataObject,
): SectorReturnAnalysisChartData[] => {
  if (!inputData.data || !inputData.data.length) {
    return [];
  } else {
    const returnData: SectorReturnAnalysisChartData[] = [];
    inputData.data[0].sector_wise_benchmarkvsfund.map((sector: any) => {
      returnData.push({
        sector: sector.sector_name,
        fund_sector_returns: sector.fund_sector_returns,
        benchmark_sector_returns: sector.benchmark_sector_returns,
        excess_returns: sector.excess_returns,
      });
    });

    return returnData;
  }
};

const SectorReturnAnalysisChart: React.FC<SectorReturnAnalysisChartProps> = ({
  data,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const id = `Sub-Fund/Benchmark-Cumulative-Comparison-Sector-Analysis`;
  const title = `Sub-Fund / Benchmark Cumulative Comparison - Sector Analysis`;

  const builtChartData = buildChartData(data);

  const { ref, handleDownload } = usePngFromRecharts();

  useEffect(() => {
    dispatch(
      addComponentToPdfExport({
        identifier: id,
        handler: handleDownload,
        type: PdfComponentType.LINE_CHART,
        title: title,
      }),
    );
  }, [ref]);

  const saveImage = async () => {
    const imageData = await handleDownload();
    if (imageData) {
      FileSaver.saveAs(imageData.data, `${id}.png`);
    }
  };

  return (
    <GridItem xs={12} card>
      <div className={classes.toolbar}>
        <h2 className={classes.cardTitle}>{title}</h2>
        <div className={classes.exportButtons}>
          <ExportButton
            fileName={`${id}.csv`}
            exportData={builtChartData}
            fields={Object.keys(builtChartData[0])}
            fieldsMap={[
              { key: 'sector', label: 'Sector' },
              { key: 'fund_sector_returns', label: 'Fund Sector Returns' },
              {
                key: 'benchmark_sector_returns',
                label: 'Benchmark Sector Returns',
              },
              { key: 'excess_returns', label: 'Active Sector Returns' },
            ]}
          />
          <ChartDownloadButton handler={saveImage} />
        </div>
      </div>
      <ResponsiveContainer width="100%" height={500}>
        <BarChart
          width={500}
          height={400}
          barCategoryGap={'20%'}
          data={builtChartData}
          margin={{
            top: 20,
            right: 50,
            left: 30,
            bottom: 20,
          }}
          ref={ref}
        >
          <CartesianGrid strokeDasharray="5 5" />
          <XAxis
            dataKey="sector"
            interval={0}
            angle={-25}
            textAnchor="end"
            height={100}
            tick={{
              stroke: mainColors.mainBlue,
              strokeWidth: 1,
              fontSize: '1.3rem',
            }}
            tickLine={true}
          >
            <Label
              style={{
                textAnchor: 'middle',
                fontSize: '200%',
                fontWeight: 400,
                color: mainColors.mainBlue,
              }}
              value={'Sector'}
              position={'insideBottom'}
            />
          </XAxis>
          <YAxis
            width={70}
            tickFormatter={(tickItem) => {
              return percentageToNdecialPlaces(tickItem / 100, 1);
            }}
            tick={{
              stroke: mainColors.mainBlue,
              strokeWidth: 1,
              fontSize: '1.3rem',
            }}
            tickLine={true}
          >
            <Label
              style={{
                textAnchor: 'middle',
                fontSize: '200%',
                fontWeight: 400,
                color: mainColors.mainBlue,
              }}
              angle={270}
              value={'P/L %'}
              position={'insideLeft'}
            />
          </YAxis>
          <Tooltip content={<CustomTooltip />} />
          <ReferenceLine y={0} stroke={greys.grey600} />
          <Bar dataKey={'fund_sector_returns'} fill={mainColors.mainBlue} />
          <Bar dataKey={'benchmark_sector_returns'} fill={'#AA3FCC'} />
          <Bar dataKey={'excess_returns'} shape={CustomPLBar} />
        </BarChart>
      </ResponsiveContainer>
    </GridItem>
  );
};

export default SectorReturnAnalysisChart;
