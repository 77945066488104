import { KIDOverviewData } from '../../../../../types/pages/raidr.types';
import { AxiosInstance } from '../../../../../utilities/requestClient';

export const getKIDSGeneratorOverviewData = async () => {
  return (
    await AxiosInstance.get<KIDOverviewData[]>(
      'kiids_generator_get_overview_data',
    )
  ).data;
};

export const getKIDContentData = async (
  fundId: string,
  isin: string,
  documentLanguage: string,
  version?: string | null,
) => {
  return (
    await AxiosInstance.get(
      `kiid_generator_get_kiid_data/${fundId}/${isin}/${documentLanguage}/${version || ''}`,
    )
  ).data;
};

export const getKidsReviewData = async (
  fundId: string,
  isin: string,
  targetVersion: string,
  comparisonVersion: string,
  documentLanguage: string,
) => {
  return (
    await AxiosInstance.get(
      `kiid_generator_get_review_changes/${fundId}/${isin}/${targetVersion}/${comparisonVersion}/${documentLanguage}`,
    )
  ).data;
};

export const generateNewKID = async (formData: FormData) => {
  return await AxiosInstance.post(
    'risksystem_pdf_generator/generate_new_kiid',
    formData,
    {
      headers: {
        accept: 'application/pdf',
      },
      responseType: 'blob',
    },
  );
};

export const generateNewReviewKID = async (formData: FormData) => {
  return await AxiosInstance.post(
    'risksystem_pdf_generator/generate_new_review_kiid',
    formData,
    {
      headers: {
        accept: 'application/pdf',
      },
      responseType: 'blob',
    },
  );
};

export const getKidsFigureData = async (fundId: string, shareClass: string) => {
  return (
    await AxiosInstance.get(
      `get_kid_figures_data_by_share_class/${fundId}/${shareClass}`,
    )
  ).data;
};
