import {
  FunctionComponent,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { userIdSelector } from '../../../../../redux/auth/selectors';
import { useLocation } from 'react-router-dom';
import { KidsData } from '../editor/KidsEditor';

interface RaidrKidsContext {
  changesUrl: string;
  setChangesUrl: (url: string) => void;
  editUrl: string;
  setEditUrl: (url: string) => void;
  currentPage: string | null;
  figuresUpdated: boolean;
  setFiguresUpdated: React.Dispatch<React.SetStateAction<boolean>>;
  isPriips: boolean;
  setIsPriips: React.Dispatch<React.SetStateAction<boolean>>;
  hasEditPermissions: boolean;
  setHasEditPermissions: React.Dispatch<React.SetStateAction<boolean>>;
  backToOverviewLink: string;
  iframeTitle: string;
}

const RaidrKidsContext = createContext<RaidrKidsContext | undefined>(undefined);

interface RaidrKidsProviderProps {
  children: ReactNode;
}

const UCITS_KIIDS_PAGES = [
  'ucits-kiids-overview-view',
  'ucits-kiids-overview-edit',
  'ucits-kiids-review',
  'ucits-kiids-editor',
  'ucits-kiids-compare-versions',
  'ucits-kiids-view',
];

export const RaidrKidsProvider: FunctionComponent<RaidrKidsProviderProps> = ({
  children,
}) => {
  const userName = useSelector(userIdSelector);
  const location = useLocation();

  const currentPage = location.pathname.split('/').pop() || '';
  //   const isPriips = !currentPage?.includes('kiid');
  const [changesUrl, setChangesUrl] = useState<string>('');
  const [editUrl, setEditUrl] = useState<string>('');

  //   For the Kids Editor Component
  const [figuresUpdated, setFiguresUpdated] = useState(false);
  const [isPriips, setIsPriips] = useState(true);
  const [hasEditPermissions, setHasEditPermissions] = useState(true);

  const [iframeTitle, setIframeTitle] = useState('kid.pdf');

  const [backToOverviewLink, setBackToOverviewLink] = useState(
    '/raidr/priips-kids-overview',
  );

  //   This deals with setting the priips and back url based on the current page
  useEffect(() => {
    if (UCITS_KIIDS_PAGES.includes(currentPage)) {
      setIsPriips(false);
      setBackToOverviewLink(
        userName === 'coutts_product_team'
          ? '/raidr/ucits-kiids-overview-edit'
          : '/raidr/ucits-kiids-overview-view',
      );
      setHasEditPermissions(false);
      setIframeTitle('kiid.pdf');
    }
  }, [currentPage, userName]);

  return (
    <RaidrKidsContext.Provider
      value={{
        isPriips,
        setIsPriips,
        backToOverviewLink,
        iframeTitle,

        changesUrl,
        setChangesUrl,
        editUrl,
        setEditUrl,

        hasEditPermissions,
        setHasEditPermissions,

        currentPage,

        //
        figuresUpdated,
        setFiguresUpdated,
      }}
    >
      {children}
    </RaidrKidsContext.Provider>
  );
};

export const useRaidrKids = () => {
  const context = useContext(RaidrKidsContext);
  if (!context) {
    throw new Error('useRaidrKids must be used within a RaidrKidsProvider');
  }
  return context;
};
