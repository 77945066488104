import { CustomColumn } from '../../components/tables/tableTypes';

interface PdfItem {
  fullPage?: boolean;
  colWidth: number;
}

export interface PdfTableItem<T extends object> extends PdfItem {
  tableData: T[];
  tableColumns: CustomColumn<T>[];
}

export interface PdfChartItem extends PdfItem {
  src: string;
  height: number;
  width: number;
}

export enum PdfExportStoreKey {
  TABLES = 'tables',
  CHARTS = 'charts',
}

export enum PdfComponentType {
  TABLE = 'TABLE',
  LINE_CHART = 'LINE_CHART',
  BAR_CHART = 'BAR_CHART',
  AREA_CHART = 'AREA_CHART',
  GENERAL_COMPONENT_IMAGE = 'GENERAL_COMPONENT_IMAGE',
}

export type AvailableWidths = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
export type ComponentIndex = {
  handler: () => any;
  identifier: string;
  type: PdfComponentType;
  title: string;
  tablesInRow?: number;
  specifiedWidth?: AvailableWidths;
  additionalComponentDetail?: string;
  positionDate?: string;
  // These will give us more control over where we add things to the pdf
  dontMoveToNewPage?: boolean;
  specificYPosition?: number;
  specificXPosition?: number;
  groupName?: string;
  groupOrder?: number;
  alignment?: 'center' | 'left' | 'right';
  dontIncrementYPosition?: boolean;
  specificHeight?: number;
};

export type ComponentCSVIndex = {
  fileName: string;
  fields: string[];
  data: any[];
};

export type FileExportStore = {
  componentsToExport: ComponentIndex[];
  componentsToExportCSV: ComponentCSVIndex[];
  loadingPagePdf: boolean;
};
