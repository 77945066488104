import { ReactElement, useEffect } from 'react';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import {
  addCommasToNumbers,
  addCommasToNumbersAndRound,
  percentageToTwoDecimalPlaces,
} from '../../../../../utilities/numberFormatters';
import GridItem from '../../../../layout/GridComponents/GridItem';
import makeStyles from '@mui/styles/makeStyles';
import { greys, mainColors } from '../../../../../styling/theme';
import clsx from 'clsx';
import ExportButton from '../../../../feedback/ExportButton';
import { addComponentToPdfExport } from '../../../../../redux/fileExport/actions';
import { PdfComponentType } from '../../../../../types/redux/pdfExports/FileExportsStore';
import { useDispatch } from 'react-redux';

interface CounterpartySummaryTableProps {
  selectedCounterpartyData: any;
  currentlySelectedCounterparty: string;
}

type CounterpartySummaryValue = string | number;
export interface CounterpartySummaryTableData {
  /* positionDate: string; */
  /* nav: string; */
  /* netExposure: number | string; */
  /* netExposureLeverage: number | string; */
  /* grossExposure: number | string; */
  /* grossExposureLeverage: number | string; */
  /* cashExposure: number | string; */
  /* cashExposureLeverage: number | string; */
  /* derivativeCashExposure: number | string; */
  /* derivativeCashExposureLeverage: number | string; */
  /* unrealisedPL: number | string; */
  /* unrealisedPL_pc: number | string; */
  data: string;
  value: CounterpartySummaryValue;
}

export function buildCounterpartySummaryData(data: any, forExport: boolean) {
  // data coming in is an object

  const {
    nav,
    total_net_exposure,
    total_unrealised_pl,
    total_gross_exposure,
    selected_position_date,
    cash_derivative_exposure,
    cash_cash_exposure,
    counterparty_collateral,
  } = data;

  function doLeverage(input: number) {
    return input / nav;
  }

  const finalData = [
    {
      data: 'Position Date',
      value: selected_position_date,
    },
    {
      data: 'NAV',
      value: forExport ? nav : addCommasToNumbers(nav),
    },
    {
      data: 'Net Exposure',
      value: forExport
        ? total_net_exposure
        : addCommasToNumbersAndRound(total_net_exposure),
    },
    {
      data: 'Net Exposure Leverage',
      value: forExport
        ? doLeverage(total_net_exposure)
        : percentageToTwoDecimalPlaces(doLeverage(total_net_exposure)),
    },
    {
      data: 'Gross Exposure',
      value: forExport
        ? total_gross_exposure
        : addCommasToNumbersAndRound(total_gross_exposure),
    },
    {
      data: 'Gross Exposure Leverage',
      value: forExport
        ? doLeverage(total_gross_exposure)
        : percentageToTwoDecimalPlaces(doLeverage(total_gross_exposure)),
    },
    {
      data: 'Cash Exposure',
      value: forExport
        ? cash_cash_exposure
        : addCommasToNumbersAndRound(cash_cash_exposure),
    },
    {
      data: 'Cash Leverage',
      value: forExport
        ? doLeverage(cash_cash_exposure)
        : percentageToTwoDecimalPlaces(doLeverage(cash_cash_exposure)),
    },
    {
      data: 'Derivative Cash Exposure',
      value: forExport
        ? cash_derivative_exposure
        : addCommasToNumbersAndRound(cash_derivative_exposure),
    },
    {
      data: 'Derivative Cash Exposure Leverage',
      value: forExport
        ? doLeverage(cash_derivative_exposure)
        : percentageToTwoDecimalPlaces(doLeverage(cash_derivative_exposure)),
    },
    {
      data: 'Unrealised P/L',
      value: forExport
        ? total_unrealised_pl
        : addCommasToNumbersAndRound(total_unrealised_pl),
    },
    {
      data: 'Unrealised P/L (%)',
      value: forExport
        ? doLeverage(total_unrealised_pl)
        : percentageToTwoDecimalPlaces(doLeverage(total_unrealised_pl)),
    },
    {
      data: 'Collateral',
      value: forExport
        ? counterparty_collateral
        : addCommasToNumbersAndRound(counterparty_collateral),
    },
    {
      data: 'Collateral (%)',
      value: forExport
        ? doLeverage(counterparty_collateral)
        : percentageToTwoDecimalPlaces(doLeverage(counterparty_collateral)),
    },
  ];
  return finalData;
}

export interface IExposureSummaryData {
  data: string;
  value: string;
}

export const summaryColumns: CustomColumn<IExposureSummaryData>[] = [
  {
    title: 'Data',
    field: 'data',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: { textAlign: 'center' },
  },
  {
    title: 'Value',
    field: 'value',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: { textAlign: 'center' },
  },
];

const useStyles = makeStyles(() => ({
  tableCard: {
    display: 'flex',
    height: '50rem',
    marginBottom: '2rem',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  toolbar: {
    display: 'flex',
    padding: '0.5rem 2rem',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    color: mainColors.mainBlue,
    fontWeight: 400,
  },
  tables: {
    display: 'flex',
    justifyContent: 'center',
  },
  customList: {
    all: 'unset',
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    borderTop: `1px solid ${greys.grey200}`,
  },
  leftSideList: {
    borderRight: `1px solid ${greys.grey200}`,
  },
  customListItem: {
    height: '6rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem 1.5rem 1rem 1.5rem',
    gap: '1rem',
    overflow: 'hidden',
    borderBottom: `1px solid ${greys.grey200}`,
  },
  customListItemTitle: {
    fontSize: '1.6rem',
    maxWidth: '14rem',
    fontWeight: 500,
  },
  customListItemData: {
    fontSize: '1.4rem',
    fontWeight: 300,
    minWidth: '6rem',
    textAlign: 'right',
  },
}));

function CounterpartySummaryTable(
  props: CounterpartySummaryTableProps,
): ReactElement {
  const { selectedCounterpartyData, currentlySelectedCounterparty } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const tableData = selectedCounterpartyData.data.length
    ? buildCounterpartySummaryData(selectedCounterpartyData.data[0], false)
    : [];

  const exportData = selectedCounterpartyData.data.length
    ? buildCounterpartySummaryData(selectedCounterpartyData.data[0], true)
    : [];

  const tablePdfExportHandler = async () => {
    const renderColumns = summaryColumns.map((col) => ({
      header: col.title,
      dataKey: col.field,
      headerStyle: col.headerStyle,
      cellStyle: col.cellStyle,
      render: col.render,
      pdfRenderType: col.pdfRenderType,
      pdfWidth: col.pdfWidth,
    }));

    return {
      startY: 50,
      columns: renderColumns,
      body: tableData,
      dontCropHeaderRow: true,
    };
  };

  useEffect(() => {
    dispatch(
      addComponentToPdfExport({
        identifier: `counterpary${
          selectedCounterpartyData.data[0].selected_position_date
            ? `_${selectedCounterpartyData.data[0].selected_position_date}`
            : ''
        }`,
        handler: tablePdfExportHandler,
        type: PdfComponentType.TABLE,
        title: `Counterparty Summary (${currentlySelectedCounterparty})`,
        specifiedWidth: undefined,
        tablesInRow: undefined,
        additionalComponentDetail: undefined,
        positionDate: selectedCounterpartyData.data[0].selected_position_date,
        specificYPosition: undefined,
        specificXPosition: undefined,
        groupName: undefined,
        groupOrder: undefined,
        dontMoveToNewPage: true,
      }),
    );
  }, [exportData]);

  return (
    <GridItem xs={4} card className={classes.tableCard}>
      <div className={classes.container}>
        <div className={classes.toolbar}>
          <h1 className={classes.title}>{currentlySelectedCounterparty}</h1>
          <div>
            <ExportButton
              exportData={exportData}
              pdfIdentifier={`counterpary${
                selectedCounterpartyData.data[0].selected_position_date
                  ? `_${selectedCounterpartyData.data[0].selected_position_date}`
                  : ''
              }`}
              fields={['data', 'value']}
              fileName={`counterpary${
                selectedCounterpartyData.data[0].selected_position_date
                  ? `_${selectedCounterpartyData.data[0].selected_position_date}`
                  : ''
              }`}
              selectedPositionDate={
                selectedCounterpartyData.data[0].selected_position_date
              }
              allowPdfExport={true}
            />
          </div>
        </div>
        <div className={classes.tables}>
          <ul className={clsx(classes.customList, classes.leftSideList)}>
            {tableData.slice(0, Math.ceil(tableData.length / 2)).map((item) => {
              return (
                <li id={item.data} className={classes.customListItem}>
                  <div className={classes.customListItemTitle}>{item.data}</div>
                  <div className={classes.customListItemData}>{item.value}</div>
                </li>
              );
            })}
          </ul>
          <ul className={classes.customList}>
            {tableData.slice(-Math.ceil(tableData.length / 2)).map((item) => {
              return (
                <li id={item.data} className={classes.customListItem}>
                  <div className={classes.customListItemTitle}>{item.data}</div>
                  <div className={classes.customListItemData}>{item.value}</div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </GridItem>
  );
}

export default CounterpartySummaryTable;
