// Basic auth store

export interface AuthErrorObject {
  detail: string;
  account_frozen_minutes?: number;
  account_is_blocked?: boolean;
  account_is_blocked_from?: string | null;
  failed_attempts?: number;
  maximum_failed_login_attempts?: number;
}

export interface AuthStore {
  isAuthenticated: boolean | null;
  user_id: string | null;
  client_name: string | null;
  config_name: string | null;
  authenticationInProgress: boolean;
  authenticationError: AuthErrorObject | null;
  isQRCodeRequired: boolean | null;
  isEmailTokenRequired: boolean | null;
  accessToken: string | null;
  authKey: string | null;
  qrCodeEmailSent: boolean;
}

export type LoginCredentials = {
  username: string;
  password: string;
};

export type LoginCredentials2FA = {
  username: string;
  twoFactorToken: string;
  authKey: string;
};

export type VerficationDetails = {
  username: string;
  accessToken: string;
  authKey: string;
  isQrCodeRequired: boolean;
  isEmailTokenRequired: boolean;
};

export type LoginCredentials2FAWithTokens = {
  username: string;
  emailCode: string;
  qrCode: string | null;
  authKey: string | null;
  accessToken: string | null;
  isQrCodeRequired: boolean;
  isEmailTokenRequired: boolean;
};

// Constants

export const AUTHENTICATE = 'AUTHENTICATE';
export type AUTHENTICATE = typeof AUTHENTICATE;
export const UNAUTHENTICATE = 'UNAUTHENTICATE';
export type UNAUTHENTICATE = typeof UNAUTHENTICATE;
export const AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED';
export type AUTHENTICATION_FAILED = typeof AUTHENTICATION_FAILED;
export const TWO_FACTOR_AUTHENTICATION_FAILED =
  'TWO_FACTOR_AUTHENTICATION_FAILED';
export type TWO_FACTOR_AUTHENTICATION_FAILED =
  typeof TWO_FACTOR_AUTHENTICATION_FAILED;
export const AUTHENTICATION_IN_PROGRESS = 'AUTHENTICATION_IN_PROGRESS';
export type AUTHENTICATION_IN_PROGRESS = typeof AUTHENTICATION_IN_PROGRESS;
export const AUTHENTICATE_WITH_2FA = 'AUTHENTICATE_WITH_2FA';
export type AUTHENTICATE_WITH_2FA = typeof AUTHENTICATE_WITH_2FA;
export const AUTHENTICATION_WITH_2FA_IN_PROGRESS =
  'AUTHENTICATION_WITH_2FA_IN_PROGRESS';
export type AUTHENTICATION_WITH_2FA_IN_PROGRESS =
  typeof AUTHENTICATION_WITH_2FA_IN_PROGRESS;
export const CANCEL_TWO_FACTOR_AUTHENTICATION_IN_PROGRESS =
  'CANCEL_TWO_FACTOR_AUTHENTICATION_IN_PROGRESS';
export type CANCEL_TWO_FACTOR_AUTHENTICATION_IN_PROGRESS =
  typeof CANCEL_TWO_FACTOR_AUTHENTICATION_IN_PROGRESS;

// actions

export interface IAuthenticate {
  type: AUTHENTICATE;
  user_id: string;
  client_name: string;
  config_name: string;
}

export interface IUnauthenticate {
  type: UNAUTHENTICATE;
}

export interface IAuthenticationFailed {
  type: AUTHENTICATION_FAILED;
  payload: AuthErrorObject;
}

export interface ITwoFactorAuthenticationFailed {
  type: TWO_FACTOR_AUTHENTICATION_FAILED;
  user_id: string;
  client_name: string;
  config_name: string;
  errorMessage: AuthErrorObject;
  accessToken: string;
  authKey: string;
  qrCodeRequired: boolean;
  emailTokenRequired: boolean;
  emailSent: boolean;
}

export interface IAuthenticationInProgress {
  type: AUTHENTICATION_IN_PROGRESS;
}

export interface IAuthenticationWith2FAInProgress {
  type: AUTHENTICATION_WITH_2FA_IN_PROGRESS;
  qrCodeRequired: boolean;
  emailTokenRequired: boolean;
  user_id: string;
  client_name: string;
  config_name: string;
  accessToken: string;
  authKey: string;
}

export interface ICancel2FAInProgress {
  type: CANCEL_TWO_FACTOR_AUTHENTICATION_IN_PROGRESS;
}

export interface IAuthenticateWith2FA {
  type: AUTHENTICATE_WITH_2FA;
  user_id: string;
  client_name: string;
  config_name: string;
  accessToken: string;
  authKey: string;
  qrCodeRequired: boolean;
  emailTokenRequired: boolean;
  emailSent: boolean;
}

export type AuthenticationAction =
  | IAuthenticate
  | IUnauthenticate
  | IAuthenticationFailed
  | IAuthenticationInProgress
  | IAuthenticateWith2FA
  | IAuthenticationWith2FAInProgress
  | ITwoFactorAuthenticationFailed
  | ICancel2FAInProgress;
