import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react';
import GridItem from '../../../../layout/GridComponents/GridItem';
import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { greys, mainColors } from '../../../../../styling/theme';
import {
  addCommasToNumbersAndRound,
  formatMillions,
} from '../../../../../utilities/numberFormatters';
import { useDispatch } from 'react-redux';
import usePngFromRecharts from '../../../../../hooks/usePngFromRecharts';
import { addComponentToPdfExport } from '../../../../../redux/fileExport/actions';
import FileSaver from 'file-saver';
import { PdfComponentType } from '../../../../../types/redux/pdfExports/FileExportsStore';
import ExportButton from '../../../../feedback/ExportButton';
import ChartDownloadButton from '../../../../buttons/ChartDownloadButton';

interface PeProxyModelGraphProps {
  data: any;
}

interface PeProxyModelGraphData {
  date: string;
  nav: number;
}

const useStyles = makeStyles(() => ({
  toolbar: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '1rem 2.5rem 1rem 1.5rem',
  },
  cardTitle: {
    color: mainColors.mainBlue,
    fontSize: '2.2rem',
    fontWeight: 400,
    margin: '1rem 1.5rem',
  },
  buttonGroupContainer: {
    display: 'flex',
    gap: '0.2rem',
    margin: '0 3rem',
  },
  customTooltipContainer: {
    backgroundColor: 'white',
    padding: '1rem 1.5rem',
    borderRadius: '0.5rem',
    border: `1px solid ${mainColors.mainBlue}`,
    width: '25rem',
    color: mainColors.mainBlue,
  },
  tooltipDate: {
    fontSize: '2rem',
    fontWeight: 500,
  },
  tooltipFundPerf: {
    fontSize: '1.6rem',
    fontWeight: 600,
    color: mainColors.mainBlue,
  },
  tooltipBenchmark: {
    fontSize: '1.6rem',
    fontWeight: 600,
    color: mainColors.Fail,
  },
}));

const CustomTooltip = ({ active, payload, label }: any) => {
  const classes = useStyles();
  if (active && payload && payload.length) {
    let totalTime = 0;
    payload.forEach((item: any) => (totalTime = totalTime + item.value));
    const sorted = payload.sort((a: any, b: any) => {
      if (a.value < b.value) {
        return 1;
      } else if (a.value > b.value) {
        return -1;
      } else {
        return 0;
      }
    });
    return (
      <div className={classes.customTooltipContainer}>
        <div>
          <div className={classes.tooltipDate}>{label}</div>
          <hr />
          <div className={classes.tooltipFundPerf}>
            NAV:{' '}
            {addCommasToNumbersAndRound(
              payload.find((item: any) => item.name === 'nav').value,
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

const buildGraphData = (inputData: any): PeProxyModelGraphData[] => {
  if (!inputData.data || !inputData.data.length) {
    return [];
  } else {
    const returnData: any[] = [];
    inputData.data[0].map((date: any[]) => {
      returnData.push({
        date: date[0],
        nav: date[1],
      });
    });
    return returnData;
  }
};

const PeProxyModelGraph: React.FC<PeProxyModelGraphProps> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const builtGraphData = buildGraphData(props.data);
  const title = `Historical Proxy NAV`;

  const { ref, handleDownload } = usePngFromRecharts();

  useEffect(() => {
    dispatch(
      addComponentToPdfExport({
        identifier: 'proxyModelGraph',
        handler: handleDownload,
        type: PdfComponentType.BAR_CHART,
        title: title,
        dontMoveToNewPage: true,
        specificYPosition: undefined,
        specificXPosition: undefined,
        alignment: 'center',
        dontIncrementYPosition: undefined,
      }),
    );
  }, [ref]);

  const saveImage = async () => {
    const imageData = await handleDownload();
    if (imageData) {
      FileSaver.saveAs(imageData.data, title + '.png');
    }
  };

  return (
    <GridItem xs={12} card>
      <div className={classes.toolbar}>
        <h2 className={classes.cardTitle}>{title}</h2>
        <div className={classes.buttonGroupContainer}>
          <ExportButton
            fields={Object.keys(builtGraphData[0])}
            exportData={builtGraphData}
          />
          <ChartDownloadButton handler={saveImage} />
        </div>
      </div>
      <ResponsiveContainer width="100%" height={530}>
        <LineChart
          data={builtGraphData}
          margin={{ top: 20, right: 50, bottom: 20, left: 30 }}
          ref={ref}
        >
          <XAxis
            dataKey="date"
            angle={-25}
            textAnchor="end"
            height={80}
            tick={{
              stroke: mainColors.mainBlue,
              strokeWidth: 1,
              fontSize: '1.3rem',
            }}
            tickLine={true}
          >
            <Label
              style={{
                textAnchor: 'middle',
                fontSize: '200%',
                fontWeight: 400,
                color: mainColors.mainBlue,
              }}
              value={'Date'}
              position={'insideBottom'}
            />
          </XAxis>
          <YAxis
            width={70}
            tickFormatter={(tickItem) => {
              return formatMillions(tickItem);
            }}
            tick={{
              stroke: mainColors.mainBlue,
              strokeWidth: 1,
              fontSize: '1.3rem',
            }}
            tickLine={true}
            domain={['auto', 'auto']}
          >
            <Label
              style={{
                textAnchor: 'middle',
                fontSize: '200%',
                fontWeight: 400,
                color: mainColors.mainBlue,
              }}
              angle={270}
              value={'NAV'}
              position={'insideLeft'}
            />
          </YAxis>
          <Tooltip content={<CustomTooltip />} />
          <CartesianGrid stroke={greys.grey300} strokeDasharray="5 5" />
          <ReferenceLine y={0} stroke={greys.grey600} />
          <Line
            type="linear"
            dataKey="nav"
            stroke={mainColors.mainBlue}
            strokeWidth={3}
          />
          <Legend
            verticalAlign="top"
            height={30}
            wrapperStyle={{ fontSize: '1.6rem' }}
            formatter={(item) => {
              switch (item) {
                case 'nav':
                  return 'NAV';
                default:
                  return item;
              }
            }}
          />
        </LineChart>
      </ResponsiveContainer>
    </GridItem>
  );
};

export default PeProxyModelGraph;
