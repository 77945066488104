import React, { useEffect, useState } from 'react';
import GridItem from '../../../../../layout/GridComponents/GridItem';
import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import makeStyles from '@mui/styles/makeStyles';
import { greys, mainColors } from '../../../../../../styling/theme';
import {
  percentageToNdecialPlaces,
  percentageToNdecialPlacesAddZeros,
} from '../../../../../../utilities/numberFormatters';
import usePngFromRecharts from '../../../../../../hooks/usePngFromRecharts';
import { useDispatch } from 'react-redux';
import { addComponentToPdfExport } from '../../../../../../redux/fileExport/actions';
import FileSaver from 'file-saver';
import { PdfComponentType } from '../../../../../../types/redux/pdfExports/FileExportsStore';
import ExportButton from '../../../../../feedback/ExportButton';
import ChartDownloadButton from '../../../../../buttons/ChartDownloadButton';

interface SectorGraphProps {
  data: any;
}

interface SectorGraphData {
  [index: string]: string | number;
}

const useStyles = makeStyles(() => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '1rem 2.5rem 1rem 1.5rem',
  },
  cardTitle: {
    color: mainColors.mainBlue,
    fontSize: '2.2rem',
    fontWeight: 400,
    margin: '1rem 1.5rem',
  },
  customTooltipContainer: {
    backgroundColor: 'white',
    padding: '1rem 1.5rem',
    borderRadius: '0.5rem',
    border: `1px solid ${mainColors.mainBlue}`,
    width: '30rem',
    color: mainColors.mainBlue,
  },
  tooltipDate: {
    fontSize: '2rem',
    fontWeight: 500,
  },
  tooltipItem: {
    fontSize: '1.6rem',
    fontWeight: 600,
    color: mainColors.mainBlue,
    display: 'flex',
    justifyContent: 'space-between',
  },
  exportButtons: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const sectorColorMap = (sector: string) => {
  switch (sector) {
    case 'Technology':
      return '#3FBCCC';
    case 'Telecommunications':
      return '#AA3FCC';
    case 'Health Care':
      return '#3FCC4A';
    case 'Financials':
      return '#008000';
    case 'Real Estate':
      return '#0000FF';
    case 'Consumer Discretionary':
      return '#F7C851';
    case 'Consumer Staples':
      return '#FF984F';
    case 'Industrials':
      return '#8F572F';
    case 'Basic Materials':
      return '#CC3F49';
    case 'Energy':
      return '#FF00FF';
    case 'Utilities':
      return '#C7C253';
    default:
      return mainColors.mainBlue;
  }
};

const CustomTooltip = ({ active, payload, label }: any) => {
  const classes = useStyles();
  if (active && payload && payload.length) {
    type valueType = {
      name: string;
      value: number;
    };
    const values: valueType[] = payload.map((item: any) => {
      return {
        name: item.name,
        value: item.value,
      };
    });
    const sortedValues = values.sort(
      (a: valueType, b: valueType) => b.value - a.value,
    );

    return (
      <div className={classes.customTooltipContainer}>
        <div>
          <div className={classes.tooltipDate}>{label}</div>
          <hr />
          {sortedValues.map((item: any) => {
            return (
              <div
                className={classes.tooltipItem}
                key={item.name}
                style={{ color: sectorColorMap(item.name) }}
              >
                <div>{item.name}:</div>
                <div>
                  {percentageToNdecialPlacesAddZeros(item.value / 100, 2)}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

const buildGraphData = (inputData: any): SectorGraphData[] => {
  if (!inputData.data || !inputData.data.length) {
    return [];
  } else {
    const returnData: SectorGraphData[] = [];

    inputData.data[0].sector_daily_returns.map((date: any) => {
      returnData.push(date);
    });

    return returnData.sort((a: SectorGraphData, b: SectorGraphData) =>
      String(a.date).localeCompare(String(b.date), undefined, {
        numeric: true,
        sensitivity: 'base',
      }),
    );
  }
};

const SectorGraph: React.FC<SectorGraphProps> = ({ data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const builtGraphData = buildGraphData(data);

  const id = 'Sub-Fund-Historical-Performance-Sector-Daily-Returns';
  const title = 'Sub-Fund Historical Performance - Sector Daily Returns';

  const { ref, handleDownload } = usePngFromRecharts();

  useEffect(() => {
    dispatch(
      addComponentToPdfExport({
        identifier: id,
        handler: handleDownload,
        type: PdfComponentType.LINE_CHART,
        title: title,
      }),
    );
  }, [ref]);

  const saveImage = async () => {
    const imageData = await handleDownload();
    if (imageData) {
      FileSaver.saveAs(imageData.data, `${id}.png`);
    }
  };

  const [graphHeight, setGraphHeight] = useState(window.innerHeight * 0.75);

  useEffect(() => {
    setGraphHeight(window.innerHeight * 0.75);
  }, [window]);

  return (
    <GridItem xs={12} card cardStyle={{ height: '100%' }}>
      <div className={classes.toolbar}>
        <h2 className={classes.cardTitle}>{title}</h2>
        <div className={classes.exportButtons}>
          <ExportButton
            fileName={`${id}.csv`}
            exportData={builtGraphData}
            fields={Object.keys(builtGraphData[0])}
          />
          <ChartDownloadButton handler={saveImage} />
        </div>
      </div>
      <ResponsiveContainer width="100%" height={graphHeight}>
        <LineChart
          data={builtGraphData}
          margin={{ top: 20, right: 50, bottom: 20, left: 30 }}
          ref={ref}
        >
          <XAxis
            dataKey="date"
            angle={-45}
            textAnchor="end"
            height={60}
            tick={{
              stroke: mainColors.mainBlue,
              strokeWidth: 1,
              fontSize: '1.3rem',
            }}
            tickLine={true}
          />
          <YAxis
            width={70}
            tickFormatter={(tickItem) => {
              return percentageToNdecialPlaces(tickItem / 100, 1);
            }}
            tick={{
              stroke: mainColors.mainBlue,
              strokeWidth: 1,
              fontSize: '1.3rem',
            }}
            tickLine={true}
          >
            <Label
              style={{
                textAnchor: 'middle',
                fontSize: '200%',
                fontWeight: 400,
                color: mainColors.mainBlue,
              }}
              angle={270}
              value={'P/L'}
              position={'insideLeft'}
            />
          </YAxis>
          <Tooltip content={<CustomTooltip />} />
          <CartesianGrid stroke={greys.grey300} strokeDasharray="5 5" />
          <ReferenceLine y={0} stroke={greys.grey600} />
          {Object.keys(builtGraphData[0]).map((key: any) => {
            if (key !== 'date') {
              return (
                <Line
                  key={key}
                  type="linear"
                  dataKey={key}
                  stroke={sectorColorMap(key)}
                  strokeWidth={2}
                />
              );
            }
          })}
          <Legend
            verticalAlign="top"
            height={30}
            wrapperStyle={{ fontSize: '1.6rem' }}
          />
        </LineChart>
      </ResponsiveContainer>
    </GridItem>
  );
};

export default SectorGraph;
