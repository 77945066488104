import {
  ComponentCSVIndex,
  ComponentIndex,
  PdfChartItem,
  PdfTableItem,
} from './FileExportsStore';

export const ADD_TABLE_TO_PDF_EXPORT = 'ADD_TABLE_TO_PDF_EXPORT';
export type ADD_TABLE_TO_PDF_EXPORT = typeof ADD_TABLE_TO_PDF_EXPORT;
export const ADD_CHART_TO_PDF_EXPORT = 'ADD_CHART_TO_PDF_EXPORT';
export type ADD_CHART_TO_PDF_EXPORT = typeof ADD_CHART_TO_PDF_EXPORT;

export const CLEAR_PDF_EXPORT = 'CLEAR_PDF_EXPORT';
export type CLEAR_PDF_EXPORT = typeof CLEAR_PDF_EXPORT;
export const ADD_COMPONENT_TO_PDF_EXPORT = 'ADD_COMPONENT_TO_PDF_EXPORT';
export type ADD_COMPONENT_TO_PDF_EXPORT = typeof ADD_COMPONENT_TO_PDF_EXPORT;

export const ADD_COMPONENT_EXPORT_CSV = 'ADD_COMPONENT_EXPORT_CSV';
export type ADD_COMPONENT_EXPORT_CSV = typeof ADD_COMPONENT_EXPORT_CSV;

export const CLEAR_CSV_EXPORT = 'CLEAR_CSV_EXPORT';
export type CLEAR_CSV_EXPORT = typeof CLEAR_CSV_EXPORT;

export type AddTableToPdfExportAction<T extends object> = {
  type: ADD_TABLE_TO_PDF_EXPORT;
  payload: PdfTableItem<T>;
};

export type AddChartToPdfExportAction = {
  type: ADD_CHART_TO_PDF_EXPORT;
  payload: PdfChartItem;
};

export type ClearPdfExportAction = {
  type: CLEAR_PDF_EXPORT;
};

export type AddComponentToPdfExportAction = {
  type: ADD_COMPONENT_TO_PDF_EXPORT;
  payload: ComponentIndex;
};

export type AddComponentExportCSVAction = {
  type: ADD_COMPONENT_EXPORT_CSV;
  payload: ComponentCSVIndex;
};

export type ClearCSVExportAction = {
  type: CLEAR_CSV_EXPORT;
};

export type PdfExportsAction =
  | AddTableToPdfExportAction<any>
  | ClearPdfExportAction
  | AddChartToPdfExportAction
  | AddComponentToPdfExportAction
  | AddComponentExportCSVAction
  | ClearCSVExportAction;
