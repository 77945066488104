import { CustomColumn } from '../../../../../../types/components/tables/tableTypes';
import { mainColors } from '../../../../../../styling/theme';
import { FC, useEffect, useRef, useState } from 'react';
import CustomTable from '../../../../../tables/CustomTable';
import UtilityButtons from '../../../components/buttons/UtilityButtons.component';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { ContentState, Editor, EditorState, convertFromHTML } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { KidsData } from '../KidsEditor';
import KidsEditorGridItem from '../../layout/KidsEditorGridItem.component';
import { debounce } from 'lodash';
import { useEditorTileStyles } from '../../styles/KidsEditor.styles';
import { EditorTileProps } from './KidsEditorColumn.component';
import { useRaidrKids } from '../../context/RaidrKidsContext';

interface BenchmarkTableData {
  assetClass: EditorState;
  benchmark: EditorState;
  weight: EditorState;
  index: number;
}

function buildBenchmarkTableData(content: any[]): BenchmarkTableData[] {
  const tableData: BenchmarkTableData[] = [];

  for (let i = 1; i < content.length; i++) {
    // Create an editor state object for each column
    const assetClassBlocks = convertFromHTML(content[i][0]);
    const assetClassContentState = ContentState.createFromBlockArray(
      assetClassBlocks.contentBlocks,
    );
    const assetClassInitialState = EditorState.createWithContent(
      assetClassContentState,
    );

    const benchmarkBlocks = convertFromHTML(content[i][1]);
    const benchmarkContentState = ContentState.createFromBlockArray(
      benchmarkBlocks.contentBlocks,
    );
    const benchmarkInitialState = EditorState.createWithContent(
      benchmarkContentState,
    );

    const weightBlocks = convertFromHTML(content[i][2]);
    const weightContentState = ContentState.createFromBlockArray(
      weightBlocks.contentBlocks,
    );
    const weightInitialState =
      EditorState.createWithContent(weightContentState);
    tableData.push({
      assetClass: assetClassInitialState,
      benchmark: benchmarkInitialState,
      weight: weightInitialState,
      index: i,
    });
  }
  return tableData;
}

function buildColumns(
  handleChange: (
    updatedEditorState: EditorState,
    rowIndex: number,
    column: 'assetClass' | 'benchmark' | 'weight',
  ) => void,
): CustomColumn<BenchmarkTableData>[] {
  const columns: CustomColumn<BenchmarkTableData>[] = [
    {
      title: 'Asset Class',
      field: 'assetClass',
      cellStyle: {
        width: '20%',
        border: '1px solid #1875d2',
        padding: '0.5rem',
        textAlign: 'center',
        backgroundColor: 'white',
      },
      headerStyle: {
        width: '20%',
        padding: '0.5rem',
        textAlign: 'center',
        color: 'white',
        backgroundColor: mainColors.controlButtonBlue,
      },
      render: (rowData: BenchmarkTableData) => (
        <Editor
          editorState={rowData.assetClass}
          onChange={(updatedEditorState: EditorState) => {
            handleChange(updatedEditorState, rowData.index - 1, 'assetClass');
          }}
          stripPastedStyles={true}
        />
      ),
    },
    {
      title: 'Benchmark',
      field: 'benchmark',
      cellStyle: {
        width: '20%',
        border: '1px solid #1875d2',
        padding: '0.5rem',
        textAlign: 'center',
        backgroundColor: 'white',
      },
      headerStyle: {
        width: '20%',
        padding: '0.5rem',
        textAlign: 'center',
        color: 'white',
        backgroundColor: mainColors.controlButtonBlue,
      },
      render: (rowData: BenchmarkTableData) => (
        <Editor
          editorState={rowData.benchmark}
          onChange={(updatedEditorState: EditorState) => {
            handleChange(updatedEditorState, rowData.index - 1, 'benchmark');
          }}
          stripPastedStyles={true}
        />
      ),
    },
    {
      title: 'Weight',
      field: 'weight',
      cellStyle: {
        border: '1px solid #1875d2',
        padding: '0.5rem',
        textAlign: 'center',
        backgroundColor: 'white',
      },
      headerStyle: {
        padding: '0.5rem',
        textAlign: 'center',
        color: 'white',
        backgroundColor: mainColors.controlButtonBlue,
      },
      render: (rowData: BenchmarkTableData) => (
        <Editor
          editorState={rowData.weight}
          onChange={(updatedEditorState: EditorState) => {
            handleChange(updatedEditorState, rowData.index - 1, 'weight');
          }}
          stripPastedStyles={true}
        />
      ),
    },
  ];

  return columns;
}

const BenchmarkTableEditorTile: FC<EditorTileProps> = ({
  priipsKidsElement,
  moveField,
  deleteField,
  setPriipsKidsContent,
}) => {
  const classes = useEditorTileStyles();
  const [tableData, setTableData] = useState<BenchmarkTableData[]>(
    buildBenchmarkTableData(JSON.parse(priipsKidsElement.content)),
  );

  // Store the index of the current element in the overall data
  const index = priipsKidsElement.kiidIndex;
  // Store the field ID of the current element
  const fieldId = priipsKidsElement.fieldId;
  // Convert the content to a javascript object

  const debouncedSetPriipsKidsContent = useRef(
    debounce((updatedArray) => {
      setPriipsKidsContent((prevContent: KidsData[]) => {
        const newContent = [...prevContent];
        newContent[index].content = JSON.stringify(updatedArray);
        return newContent;
      });
    }, 500), // Debounce interval
  ).current;

  useEffect(() => {
    return () => {
      debouncedSetPriipsKidsContent.cancel(); // Cleanup on unmount
    };
  }, []);

  function addRemoveRow(action: 'add' | 'remove') {
    if (action === 'add') {
      const newTableData = [...tableData];
      newTableData.push({
        assetClass: EditorState.createEmpty(),
        benchmark: EditorState.createEmpty(),
        weight: EditorState.createEmpty(),
        index: newTableData.length + 1,
      });
      setTableData(newTableData);

      setPriipsKidsContent((prev: any) => {
        const content = prev[index].content;
        const parsedContent = JSON.parse(content);
        parsedContent.push(['', '', '']);
        prev[index].content = JSON.stringify(parsedContent);
        return [...prev];
      });
    } else {
      const newTableData = [...tableData];
      newTableData.pop();
      setTableData(newTableData);
      setPriipsKidsContent((prev: any) => {
        const content = prev[index].content;
        const parsedContent = JSON.parse(content);
        parsedContent.pop();
        prev[index].content = JSON.stringify(parsedContent);
        return [...prev];
      });
    }
  }

  const handleTextChange = (
    updatedEditorState: EditorState,
    rowIndex: number,
    column: 'assetClass' | 'benchmark' | 'weight',
  ) => {
    // Get the current editor state of the whole table.
    const currentEditorState = tableData;

    // logEditorState(updatedEditorState);

    // Now update the cell that was changed.
    currentEditorState[rowIndex][column] = updatedEditorState;

    // Update the table data
    const updatedTableData = [...tableData];
    updatedTableData[rowIndex][column] = updatedEditorState;
    setTableData(updatedTableData);

    // Create an array of the raw data
    const updatedArray: any[] = [['Asset Class', 'Benchmark', 'Weight']];
    tableData.forEach((row) => {
      const rowData = [];
      rowData.push(stateToHTML(row.assetClass.getCurrentContent()));
      rowData.push(stateToHTML(row.benchmark.getCurrentContent()));
      rowData.push(stateToHTML(row.weight.getCurrentContent()));
      updatedArray.push(rowData);
    });
    // Update the overall data
    // debouncedSetPriipsKidsContent(updatedArray);
    setPriipsKidsContent((prevContent: KidsData[]) => {
      const newContent = [...prevContent];
      newContent[index].content = JSON.stringify(updatedArray);
      return newContent;
    });
  };

  const tableColumns = buildColumns(handleTextChange);

  return (
    <KidsEditorGridItem xs={12}>
      <div className={classes.outerContainer}>
        <div className={classes.editorContainer}>
          <div className={classes.textEditorBox}>
            <CustomTable<BenchmarkTableData>
              columns={tableColumns}
              id={'benchmark_weight_table'}
              showToolbar={false}
              data={tableData}
              options={{
                paging: false,
                search: false,
                exportButton: false,
                sorting: false,
                draggable: false,
                toolbar: false,
              }}
            />
          </div>
          <UtilityButtons
            moveField={moveField}
            index={index}
            deleteField={deleteField}
            fieldId={fieldId}
            disableDelete
            disableDeleteMessage="You cannot delete the benchmark table"
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            marginTop: '1rem',
          }}
        >
          <Button
            className={classes.button}
            variant="contained"
            onClick={() => addRemoveRow('add')}
            endIcon={<AddCircleIcon style={{ color: 'white' }} />}
          >
            <div style={{ color: 'white' }}>Add Row</div>
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            onClick={() => addRemoveRow('remove')}
            endIcon={<RemoveCircleIcon style={{ color: 'white' }} />}
            disabled={tableData.length == 1}
          >
            <div style={{ color: 'white' }}>Remove Row</div>
          </Button>
        </div>
      </div>
    </KidsEditorGridItem>
  );
};

export default BenchmarkTableEditorTile;
