import React, { ReactElement } from 'react';
import { percentageToTwoDecimalPlacesNoHundred } from '../../../../../utilities/numberFormatters';
import { useWindowSize } from '../../../../../hooks/useWindowSize';
import { RaptorTheme, mainColors } from '../../../../../styling/theme';
import { IGenericLineChartProps } from '../../../../../types/components/charts/lineCharts';
import GenericLineChart from '../../../../charts/GenericLineChart';
import makeStyles from '@mui/styles/makeStyles';
import GridItem from '../../../../layout/GridComponents/GridItem';
import AssetDebtRatioTable from '../table/AssetDebtRatioTable.component';
import DiscountRateTable from '../table/DiscountRateTable.component';
import { Typography, useTheme } from '@mui/material';

interface ValuAtRiskProps {
  // TODO type definitions
  timeSeriesData: any;
  tableData: any;
  // dataKey: string;
  // passValue: any;
  // varFund: any;
}

export const useOverviewDetailStyles = makeStyles((theme: RaptorTheme) => ({
  chartContainerDiv: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  map: {
    textAlign: 'left',
    margin: '1.5rem 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  chart: {
    // NOTE this could be better - DRY
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    // marginTop: '1.5rem',
    padding: 8,
  },
  outerDiv: {
    textAlign: 'center',
    // height: "40rem",
    width: '80%',
    maxWidth: '60rem',
    height: '80%',
  },
  container: {
    position: 'relative',
    // height: "50rem",
    padding: '2rem 0',
    paddingBottom: 0,
  },
  mapTitle: {
    marginTop: '1rem',
    marginBottom: '2rem',
    color: theme.palette.primary.main,
    textAlign: 'center',
  },
  lineChart: {
    transform: 'translateX(2.5rem)',
    '& g:first-child': {
      backgroundColor: 'red',
    },
  },
  colorChartBox: {
    height: 100,
    width: 100,
    position: 'absolute',
    top: 10,
    left: 10,
    backgroundColor: 'red',
  },
}));

function PeValueAtRiskTimeSeries({
  timeSeriesData,
  tableData,
}: // dataKey,
// passValue,
// varFund,
ValuAtRiskProps): ReactElement {
  const theme = useTheme();
  const classes = useOverviewDetailStyles();
  // function timeSeriesFormatter(data: any, lines: any) {
  //     // ? lines looks like this:
  //     /*
  //      * {dataKey, color, yAxisID}
  //      *
  //      * lineNames then will be
  //      */
  //     const lineNames = lines.map((line: any) => {
  //         return {
  //             name: line.dataKey,
  //             formatter: line.formatter,
  //         };
  //     });

  //     return data.map((row: any) => {
  //         const obj: any = {};

  //         obj.name = row[0];
  //         // the name value will always be what goes on the y axis

  //         lineNames.forEach((lineName: any, index: number) => {
  //             obj[lineName.name] = row[index];
  //         });

  //         return obj;
  //     });
  // }
  // const secondaryDataKey = varFund ? 'Value at Risk' : 'Commitment';

  let minValue = parseFloat(timeSeriesData[0][1] as string);
  let maxValue = parseFloat(timeSeriesData[0][1] as string);
  let rightAxisMin = parseFloat(timeSeriesData[0][3] as string) * 100;
  let rightAxisMax = parseFloat(timeSeriesData[0][3] as string) * 100;
  const chartDataVar = timeSeriesData.map((value: any) => {
    if (minValue > parseFloat(value[1] as string))
      minValue = parseFloat(value[1] as string);
    if (maxValue < parseFloat(value[1] as string))
      maxValue = parseFloat(value[1] as string);
    if (minValue > parseFloat(value[2] as string))
      minValue = parseFloat(value[2] as string);
    if (maxValue < parseFloat(value[2] as string))
      maxValue = parseFloat(value[2] as string);
    if (rightAxisMin > parseFloat(value[3] as string) * 100)
      rightAxisMin = parseFloat(value[3] as string) * 100;
    if (rightAxisMax < parseFloat(value[3] as string) * 100)
      rightAxisMax = parseFloat(value[3] as string) * 100;
    return {
      name: value[0],
      VaR: value[1],
      'Liquidity Var': value[2],
      Discount: value[3] * 100,
    };
  });
  const chartDataDiscount = timeSeriesData.map((value: any) => {
    if (rightAxisMin > parseFloat(value[3] as string) * 100)
      rightAxisMin = parseFloat(value[3] as string) * 100;
    if (rightAxisMax < parseFloat(value[3] as string) * 100)
      rightAxisMax = parseFloat(value[3] as string) * 100;
    return { name: value[0], Discount: value[3] * 100 };
  });
  const { height, width } = useWindowSize();
  return (
    <>
      <AssetDebtRatioTable data={tableData} height={height / 2 - 30} />
      <GridItem
        card
        xs={7}
        className={classes.chart}
        cardStyle={{ minHeight: height / 2 - 30 }}
      >
        <Typography variant="h2" className={classes.mapTitle}>
          {'Historical VaR & L-VaR'}
        </Typography>
        <div className={classes.chartContainerDiv}>
          <TimeSeriesContainer
            title={'Value At Risk'}
            width="100%"
            height={height / 2 - 105}
            error={null}
            data={chartDataVar}
            // backgroundColor={hexToRGBA(passValue, 0.3)}
            lines={[
              {
                dataKey: 'VaR',
                color: mainColors.mainBlue,
                yAxisId: 'left',
              },
              {
                dataKey: 'Liquidity Var',
                color: mainColors.secondaryRed,
                yAxisId: 'left',
              },
            ]}
            yAxisLabel={{
              value: 'Vale At Risk',
              angle: -90,
              style: { textAnchor: 'middle', fill: theme.palette.grey[500] },

              position: 'left',
            }}
            yAxisDomain={[
              minValue - Math.abs(minValue) * 0.1,
              maxValue + Math.abs(maxValue) * 0.1,
            ]}
            yAxisTickFormatter={(val: any) => {
              return `${val}%`;
            }}
            xAxisLabel={{
              value: 'Date',
              position: 'bottom',
              style: {
                fill: theme.palette.grey[500],
              },
            }}
            tooltipFormatter={(value: any, name: any) =>
              percentageToTwoDecimalPlacesNoHundred(value)
            }
            margin={{ top: 0, left: 10, right: 40, bottom: 50 }}
            // tooltipFormatter={(value: any, name: any) => {

            //     let returnVal;
            //     if (name !== 'NAV') {
            //         returnVal = `${percentageToTwoDecimalPlaces(value)}`;
            //     } else {
            //         returnVal = addCommasToNumbers(value);
            //     }
            //     return returnVal;
            // }}
          />
        </div>
      </GridItem>
      <DiscountRateTable data={tableData} height={height / 2 - 130} />

      <GridItem
        card
        xs={7}
        className={classes.chart}
        cardStyle={{ minHeight: height / 2 - 130, maxHeight: height / 2 - 130 }}
      >
        <Typography variant="h2" className={classes.mapTitle}>
          {'Premium/Liquidity Discount'}
        </Typography>
        <div className={classes.chartContainerDiv}>
          <TimeSeriesContainer
            referenceLineY={100}
            title={'Premium/Liquidity Discount'}
            width="100%"
            height={height / 2 - 180}
            error={null}
            data={chartDataDiscount}
            // backgroundColor={hexToRGBA(passValue, 0.3)}
            lines={[
              {
                dataKey: 'Discount',
                color: mainColors.mainBlue,
                yAxisId: 'left',
              },
            ]}
            yAxisLabel={{
              value: 'Discount Rate',
              angle: -90,
              style: { textAnchor: 'middle', fill: theme.palette.grey[500] },

              position: 'left',
            }}
            yAxisDomain={[
              rightAxisMin - Math.abs(rightAxisMin) * 0.1,
              rightAxisMax + Math.abs(rightAxisMax) * 0.1,
            ]}
            yAxisTickFormatter={(val: any) => {
              return `${val}%`;
            }}
            xAxisLabel={{
              value: 'Date',
              position: 'bottom',
              style: {
                fill: theme.palette.grey[500],
              },
            }}
            tooltipFormatter={(value: any, name: any) =>
              percentageToTwoDecimalPlacesNoHundred(value)
            }
            // additionalYAxis={[
            //     {
            //         // ticks: secondaryTickValues,
            //         leftDataKey: 'Discount',
            //         yAxisId: 'right',
            //         orientation: 'right',
            //         domain: [
            //             rightAxisMin,
            //             rightAxisMax
            //         ],
            //         label: {
            //             value: "Discount",
            //             angle: -90,
            //             position: 'right',
            //             style: {
            //                 textAnchor: 'middle',
            //                 fill: theme.palette.grey[500],
            //             },
            //         },
            //         tickFormatter: (val: any) => {
            //             return `${val}%`;
            //         }
            //     },
            // ]}
            margin={{ top: 0, left: 10, right: 40, bottom: 50 }}
            // tooltipFormatter={(value: any, name: any) => {

            //     let returnVal;
            //     if (name !== 'NAV') {
            //         returnVal = `${percentageToTwoDecimalPlaces(value)}`;
            //     } else {
            //         returnVal = addCommasToNumbers(value);
            //     }
            //     return returnVal;
            // }}
          />
        </div>
      </GridItem>
    </>
  );
}

export default PeValueAtRiskTimeSeries;

const TimeSeriesContainer = React.memo((props: IGenericLineChartProps) => {
  return <GenericLineChart {...props} />;
});
