import React, { FC } from 'react';
import useTitleBarSelect from '../../../../../hooks/useTitleBarSelect';
import theme, { mainColors } from '../../../../../styling/theme';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import { DataObject, Status } from '../../../../../types/redux/data/dataTypes';
import { mapStatusToColor } from '../../../../../utilities/colorUtilities';
import { percentageToTwoDecimalPlaces } from '../../../../../utilities/numberFormatters';
import InTableTimeSeries from '../../../../charts/InTableTimeSeries';
import NoTimeSeries from '../../../../charts/NoTimeSeries';
import GeneralComponentErrorShield from '../../../../general/GeneralComponentErrorShield';
import GridItem from '../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../tables/CustomTable';
import GenericStatusCell from '../../../../tables/GenericStatusCell';
import HistoricalTable from './HistoricalTable';
import secondLevelTableWrapper from './SecondLevelTableWrapper';
import RaptorStatusBox from '../../../../feedback/RaptorStatusBox.component';
import { specifyStatus } from '../../../../../utilities/generalMappings';
import { statusSort } from '../../../../../utilities/customSorting';
import NotesComp from '../../../sharedComponents/notes/Notes';

export interface IAct40SecondLevelItem {
  issuer: string;
  exposure: number;
  limit: number;
  passOrFail: Status;
  thirdLevelItems: IAct40ThirdLevelItem[] | null;
}

export interface IAct40ThirdLevelItem {
  number: number;
  asset_name: string;
  ISIN: string;
  instrument_type: number;
  info: string;
  risk_factor: number;
  position: number;
  exposure: number;
}

export interface ILawFirstLevelTableData {
  rule_number: number;
  category: string;
  rule: string;
  positionDate: string;
  passOrFail: Status;
  timeSeriesData: any[] | null; // TODO note out the interface for the TimeSeries items
  secondLevelItems: IAct40SecondLevelItem[] | null;
  status: string;
  assets: any[];
  disabled: boolean;
  historical_time_series: any[];
  limit: Limit;
}

export interface Limit {
  aggregate: number | null;
  position: number | null;
  type: 'aggregate' | 'position' | 'both';
}

export function handleLimit(limit: any): Limit {
  if (!Array.isArray(limit))
    return { aggregate: limit, position: null, type: 'aggregate' };
  if (!limit.length)
    return { aggregate: null, position: null, type: 'aggregate' };
  if (!(limit.length === 2))
    return { aggregate: limit[0], position: null, type: 'aggregate' };
  // Case when the rule is applied at both aggregate and position level.
  let limitType: 'aggregate' | 'position' | 'both' = 'aggregate';
  if (limit[0] && limit[1]) limitType = 'both';
  else if (limit[0]) limitType = 'aggregate';
  else if (limit[1]) limitType = 'position';
  return { aggregate: limit[0], position: limit[1], type: limitType };
}

export const csvLawFirstTable = ['category', 'rule', 'passOrFail', 'status'];

function formatTimeSeriesForChart(timeSeriesData: any) {
  const [date, value, limit] = timeSeriesData;

  return {
    date,
    value,
    limit,
  };
}
export function buildFirstLevelLawData(data: any, inputCompliance_data: any) {
  if (!data.length || !inputCompliance_data.length) return [];

  try {
    const fundData = data[0];
    const sections = ['40 ACT Investment Restrictions'];
    const compliance_data = inputCompliance_data[0][0];

    const arr_of_data: any[] = [];

    let counter = 1;

    sections.forEach((section) => {
      compliance_data[section].forEach((item: any) => {
        arr_of_data.push({
          category: section,
          rule_number: counter,
          rule: item,
        });
        counter++;
      });
    });
    // TODO give proper type definitions here
    let statusCounter = 0; // this is how we keep track for the status box. If it's pass it's 0, if it's alert it's 1 and if it's fail it's 2
    let status: any;
    let assets: any;
    let currentItem: any;
    let disabled: any;
    let historical_time_series: any;
    let position_g_data: any;
    let limit: any;
    let value: any;
    const finalData: ILawFirstLevelTableData[] = arr_of_data.map((item) => {
      let timeSeriesToAdd;
      if (fundData[`rule_${item.rule_number}`]) {
        currentItem = fundData[`rule_${item.rule_number}`];
        let addTimeSeries = false;
        if (currentItem.historical_time_series) {
          for (let i = 1; i < currentItem.historical_time_series.length; i++) {
            addTimeSeries = true;
            timeSeriesToAdd = currentItem.historical_time_series;
            timeSeriesToAdd.shift();
            break;
          }
        }
        status = currentItem.status;
        assets = 'assets' in currentItem ? currentItem.assets : [];
        value = percentageToTwoDecimalPlaces(currentItem.current_value);
        limit = handleLimit(currentItem.limit);
        historical_time_series = addTimeSeries ? timeSeriesToAdd : [];
        disabled = false;
        position_g_data = fundData.position_g_data;
      } else {
        status = 'N/A';
        assets = [];
        historical_time_series = [];
        disabled = true;
      }

      if (status === 'Pass - Alert' && statusCounter <= 1) {
        statusCounter = 1;
      } else if (status === 'Fail') {
        statusCounter = 2;
      }

      return {
        rule_number: item.rule_number,
        category: item.category,
        rule: item.rule,
        value,
        limit,
        status,
        positionDate: fundData.selected_position_date,
        assets,
        historical_time_series,
        disabled,
        position_g_data,
        ...item,
      };
    });
    return [finalData, fundData.Aggregate];
  } catch (error) {
    console.error('Could not build first level data!', error);
    return [];
  }
}
export const firstLevelLawColumns = (
  fundId: string,
  fundName: string,
  positionDate: string,
): CustomColumn<ILawFirstLevelTableData>[] => [
  {
    title: '#',
    field: 'rule_number',
    width: '1%',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
      padding: 10,
    },
  },
  {
    title: 'Category',
    field: 'category',
    width: '15%',
    headerStyle: {
      padding: 10,
    },
  },
  {
    title: 'Rule',
    field: 'rule',
    cellStyle: {},
    width: '25%',
    headerStyle: {
      textAlign: 'center',
      padding: 10,
    },
  },
  {
    title: 'Value',
    field: 'value',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    width: '5%',
  },
  {
    title: 'Limit',
    field: 'limit',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    width: '10%',
    render: (rowData: ILawFirstLevelTableData) =>
      rowData.limit.type === 'position'
        ? percentageToTwoDecimalPlaces(rowData.limit.position || 0)
        : rowData.limit.type === 'both'
          ? `${percentageToTwoDecimalPlaces(
              rowData.limit.position || 0,
            )}, ${percentageToTwoDecimalPlaces(rowData.limit.aggregate || 0)}`
          : percentageToTwoDecimalPlaces(rowData.limit.aggregate || 0),
  },
  {
    title: 'Status',
    field: 'status',
    pdfRenderType: 'PageLinkButton',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: ILawFirstLevelTableData) => (
      <RaptorStatusBox status={specifyStatus(rowData.status)} />
    ),
    width: '8%',
    customSort: (a: ILawFirstLevelTableData, b: ILawFirstLevelTableData) =>
      statusSort(a, b, 'status'),
  },
  {
    title: 'Historical Time Series Data',
    field: 'time_series',
    width: '46%',

    headerStyle: {
      textAlign: 'center',
      padding: 10,
    },
    cellStyle: {
      padding: 0,
      paddingTop: 5,
      paddingBottom: 5,
      textAlign: 'center',
      height: '10rem',
    },
    sorting: false,
    render: (rowData: ILawFirstLevelTableData) => {
      if (rowData.historical_time_series.length) {
        const hist = rowData.historical_time_series;
        let chartData = hist.map((item: any) => formatTimeSeriesForChart(item));

        // set rule 5 correctly
        if (rowData.rule_number === 5) {
          chartData = chartData.map((el) => ({
            ...el,
            limit: 0.4,
          }));
        }
        // function minValue =

        return (
          <InTableTimeSeries
            export
            id={fundId + rowData.rule_number}
            error={null}
            lines={[
              {
                dataKey: 'limit',
                color: mainColors.Fail_darker,
              },
              {
                dataKey: 'value',
                color: mainColors.mainBlue,
              },
            ]}
            // yAxisTickFormatter={(val: any) => toTwoDecimalPlaces(val)}
            title={'Time Series Data'}
            yAxisDomain={[
              (dataMin: number) => {
                return dataMin - Math.abs(dataMin * 0.1);
              },
              (dataMax: number) => {
                return dataMax + dataMax * 0.1;
              },
            ]}
            formatter={(value: number) => percentageToTwoDecimalPlaces(value)}
            // formatter={(val: number) => {
            //   return percentageToTwoDecimalPlaces(val);
            // }}
            data={chartData}
          />
        );
      } else {
        return (
          <NoTimeSeries
            rowDisabled={rowData.disabled}
            customMessage="No In Scope Positions"
            tooltipMessage="In the previous 3 months the fund held no positions applicable to this rule."
          />
        );
      }
    },
  },
  {
    title: 'Notes',
    field: 'notes',
    width: '5rem',
    render: (rowData: ILawFirstLevelTableData) => (
      <NotesComp
        fundId={fundId}
        fundName={`${fundName} - Rule ${rowData.rule_number}`}
        subject="act40_act40-law"
        topic={`rule_${rowData.rule_number}`}
        positionDate={positionDate}
      />
    ),
    cellStyle: {
      padding: '1.6rem',
      textAlign: 'center',
      paddingRight: '2.4rem',
      paddingLeft: '0.8rem',
    },
    headerStyle: {
      textAlign: 'center',
      padding: 'auto 1.6rem',
      paddingRight: '2.4rem',
      paddingLeft: '0.8rem',
    },
  },
];

export interface ILawHistoricalData {
  [key: string]: any;
  number: number;
  rule: string;
}

export function buildHistoricalData(
  inputData: any[],
  pathname: string,
  fundId: string,
  cellHandler: () => void,
) {
  if (!inputData.length) return [];

  const data = inputData[0];
  let columns = [
    {
      title: '#',

      field: 'number',
      cellStyle: {
        padding: 0,
        textAlign: 'center',
      },
      width: 1,
      headerStyle: { padding: 16 },
    },
    {
      title: 'Rule',
      field: 'rule',
      cellStyle: {
        padding: 0,
        paddingLeft: '1rem',
        fontSize: 12,
      },
      headerStyle: { textAlign: 'center' },
      width: '20rem',
    },
  ];
  const tableData = data.compliance_data.map((rule: any, index: string) => {
    return {
      number: index + 1,
      rule: data.rules[index],
      assets: [],
    };
  });

  /*
You've now got this:

[
    {
        number: 1,
        rule: rule name

    }
]

so now you 've got to loop through these, and for each one you've got to loop through the dates, and make a key called the date

That date contains the compliance data for that rule
*/

  tableData.forEach((ruleItem: any, ruleIndex: string) => {
    data.dates.forEach((date: any, index: string) => {
      // ruleItem[date] = data.compliance_data[ruleIndex][index];
      ruleItem[date] = data.rule_data[date][ruleItem.rule];
    });
  });

  columns = data.dates.reduce(
    (currentColumns: CustomColumn<ILawHistoricalData>[], date: any) => {
      return [
        ...currentColumns,
        {
          title: date,
          field: date,
          width: '5rem',
          render: (rowData: ILawHistoricalData) => {
            return (
              <GenericStatusCell
                status={specifyStatus(rowData[date])}
                height="3.6rem"
                fontSize={10}
                linkToPage={`${pathname}?&fundId=${fundId}&positionDate=${date}`}
                additionalHandler={cellHandler}
              />
            );
          },
          cellStyle: {
            padding: 0,
            borderLeft: '1px solid white',
            textAlign: 'center',
          },
          headerStyle: {
            fontSize: 10,
            textAlign: 'center',
          },
        },
      ];
    },
    [...columns],
  );

  return [tableData, columns];
}

export interface LawFirstLevelTableProps {
  dataSource: {
    complianceChecklist: DataObject;
    basicDataAgg: DataObject;
    act40FundData: DataObject;
  };
  activeFund: string;
  activeFundName: string;
}

const LawFirstLevelTable: FC<LawFirstLevelTableProps> = ({
  dataSource,
  activeFund,
  activeFundName,
}) => {
  const currentOrHistorical = useTitleBarSelect('dataDisplay');
  const { complianceChecklist, act40FundData, basicDataAgg } = dataSource;

  const historical = currentOrHistorical === 'historical';

  const generatedData = act40FundData.isFetching
    ? []
    : buildFirstLevelLawData(act40FundData.data, [complianceChecklist.data]);
  const preparedTableData = generatedData.length ? generatedData[0] : [];

  const columns: CustomColumn<ILawFirstLevelTableData>[] = firstLevelLawColumns(
    activeFund,
    activeFundName,
    act40FundData.isFetching
      ? 'no_date'
      : act40FundData?.data?.[0]?.selected_position_date,
  );

  const positionDate = act40FundData?.data[0]?.selected_position_date;
  // const csvFields = ['rule_number', 'category', 'rule']
  return historical ? (
    <HistoricalTable
      // fetchUrl={FetchUcitsUrls.FETCH_UCITS_LAW_HISTORICAL}
      fetchUrl={'aggregate_monthly_act40_review_d'}
      activeFund={activeFund}
      reduxKey="act40-law"
    />
  ) : (
    <GeneralComponentErrorShield
      dataObjects={[complianceChecklist, act40FundData, basicDataAgg]}
    >
      <GridItem xs={12} card>
        <CustomTable<ILawFirstLevelTableData>
          id="act40_law_first_level"
          showToolbar={true}
          csvFields={columns
            .map((col: any) => col.field)
            .filter(
              (col: keyof ILawFirstLevelTableData) =>
                col !== 'historical_time_series',
            )}
          selectedPositionDate={positionDate}
          data={preparedTableData}
          columns={columns}
          title={`${activeFundName}: ${
            act40FundData?.data && act40FundData.data.length
              ? act40FundData.data[0]?.selected_position_date
              : ''
          }`}
          options={{
            exportButton: true,
            paging: false,
            search: true,
            rowStyle: (rowData: ILawFirstLevelTableData) => ({
              padding: 0,
              color:
                rowData.status === 'N/A'
                  ? mapStatusToColor(Status.NA)
                  : theme.palette.grey[900],
            }),
            raptorExport: true,
          }}
          detailPanel={secondLevelTableWrapper(true, {
            fundId: activeFund,
            fundName: activeFundName,
          })}
        />
      </GridItem>
    </GeneralComponentErrorShield>
  );
};

export default LawFirstLevelTable;
