import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import PieContainer from './PieContainer.component';
import { addCommasToNumbersAndRound } from '../../../../utilities/numberFormatters';
import { currency_symbols_map } from '../../../../utilities/generalMappings';
import { RaptorTheme } from '../../../../styling/theme';
import { useSelector } from 'react-redux';
import { clientNameSelector } from '../../../../redux/auth/selectors';
import { Typography } from '@mui/material';

interface RegulationContainerProps {
  sectionId: string;
  sectionTitle: string;
  data: any;
  delayIndex: number;
  currencyCode?: string;
}

const useStyles = makeStyles((theme: RaptorTheme) => ({
  regulationContainerRoot: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '1rem 3rem',
    paddingRight: 0,
    transition: 'all .3s',
    height: '100%',
    width: '100%',
    zIndex: 1,
    position: 'relative',
    cursor: 'pointer',
  },
  textSection: {
    display: 'flex',
    borderRadius: 4,
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '25%',
  },
  subTitle: {
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.8rem',
    },
  },
  subTitle_info: {
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.8rem',
    },
    fontSize: '2.4rem',
    fontWeight: 'bold',
    marginBottom: '1.5rem',
    color: theme.palette.primary.main,
  },
  pieSection: {
    cursor: 'pointer',
    display: 'flex',
    borderRadius: 4,
    width: '75%',
    justifyContent: 'space-around',
  },
}));

const RegulationContainer: FC<RegulationContainerProps> = ({
  sectionId,
  sectionTitle,
  data,
  delayIndex,
  currencyCode,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const clientName = useSelector(clientNameSelector);
  // this will need to be changed once the overview pages are refactored
  const handleClick = () => {
    // This is to handle legacy version of AIFMD code for Quayside and WCapital
    // Quayside and WCapital are the only clients using this, all future AIFMD clients will use the new version (eg. bflexion)

    // Handle the legacy aifmd page for quayside and wcapital
    if (
      sectionId === 'aifmd' &&
      (clientName === 'quayside' || clientName === 'wcapital')
    ) {
      navigate(`/${sectionId}/${sectionId}-overview-legacy`);
    } else if (sectionId === 'pe' || sectionId === 'aifmd') {
      navigate(`/${sectionId}/${sectionId}-overview`);
    } else {
      navigate(`/${sectionId}/overview`);
    }
  };

  return (
    <div className={classes.regulationContainerRoot} onClick={handleClick}>
      <div className={classes.textSection}>
        <Typography variant="h1">{sectionTitle}</Typography>
        <Typography variant="h2" className={classes.subTitle}>
          Number of Sub-Funds:
        </Typography>
        <Typography variant="h3" className={classes.subTitle_info}>
          {data.overview_data.funds}
        </Typography>
        <Typography variant="h2" className={classes.subTitle}>
          Assets Under Management:
        </Typography>
        <Typography variant="h3" className={classes.subTitle_info}>
          {(currencyCode ? currency_symbols_map[currencyCode] : '€') +
            ' ' +
            addCommasToNumbersAndRound(data.overview_data.aum)}
        </Typography>
      </div>
      <div className={classes.pieSection}>
        <PieContainer
          sectionId={sectionId}
          sectionTitle={sectionTitle}
          data={data.pies_data}
          delayIndex={delayIndex}
        />
      </div>
    </div>
  );
};

export default RegulationContainer;
