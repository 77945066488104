import makeStyles from '@mui/styles/makeStyles';
import { greys, mainColors } from '../../../../../styling/theme';
import React, { FC } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Tooltip } from '@mui/material';

interface StyleFormatButtonsProps {
  fieldId: string;
  addField: (fieldId: string) => void;
}

interface StyleProps {
  isHovered: boolean;
}

const useStyles = makeStyles<StyleProps>(() => ({
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    paddingLeft: '1rem',
    borderRadius: 8,
    marginBottom: '2rem',
    boxSizing: 'border-box',
    padding: '1rem',
  },
  arrow: {
    '&:before': {
      border: '1px solid #8e8e8e',
    },
    color: greys.grey600,
  },
  tooltip: {
    backgroundColor: greys.grey600,
    border: '1px solid #8e8e8e',
    color: 'white',
  },
}));

const AddFieldButton: FC<StyleFormatButtonsProps> = (props) => {
  const { fieldId, addField } = props;

  const [isHovered, setIsHovered] = React.useState(false);

  const classes = useStyles({ isHovered });
  return (
    <div
      className={classes.buttonsContainer}
      style={{
        backgroundColor: isHovered ? mainColors.mainBlue : 'transparent',
      }}
    >
      <Tooltip
        placement="top"
        enterDelay={2000}
        classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
        title="Insert new field"
        arrow
      >
        <AddCircleOutlineIcon
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          sx={{
            color: mainColors.mainBlue,
            fontSize: '2.8rem',
            cursor: 'pointer',
            '&:hover': {
              color: 'white',
            },
          }}
          onClick={() => addField(fieldId)}
        />
      </Tooltip>
    </div>
  );
};

export default AddFieldButton;
