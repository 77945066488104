import { FC, FunctionComponent, useEffect, useMemo, useState } from 'react';
import useFetchData from '../../../hooks/useFetchData';
import { createSectionByIdSelector } from '../../../redux/pages/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { FundType } from '../../../types/server-data/ClientConfig';
import useTitleBarSelect from '../../../hooks/useTitleBarSelect';
import { DataObject } from '../../../types/redux/data/dataTypes';
import {
  addAdditionalTitleBarComponents,
  toggleTitleBarLoading,
} from '../../../redux/pages/actions';
import { Select } from '../../../types/redux/pages/PagesStore';
import { APPEND_NEW_SELECT_TO_TITLEBAR } from '../../../types/redux/pages/pageTypes';
import { clientNameSelector } from '../../../redux/auth/selectors';
import GeneralComponentErrorShield from '../../general/GeneralComponentErrorShield';
import GridItem from '../../layout/GridComponents/GridItem';
import AggregateExposureTable from './exposureComponents/AggregateExposureTable';
import AssetGrossExposure from './exposureComponents/AssetGrossExposure';
import AssetClassGrossExposureChart from './exposureComponents/AssetClassGrossExposure';
import AssetCurrencyExposure from './exposureComponents/AssetCurrencyExposure';
import CountryCodeGrossExposure from './exposureComponents/CountryCodeGrossExposure';
import AssetCountryCodeExposure from './exposureComponents/AssetCountryCodeExposure';
import AmlLevelGrossExposure from './exposureComponents/AmlLevelGrossExposure';
import AssetAmlLevelExposure from './exposureComponents/AssetAmlLevelExposure';
import { FormControlLabel, Switch } from '@mui/material';
import { PresetExposureType } from '../../tables/ultraTable/types/column.types';
import UltraTable, {
  TableData,
  UltraTablePresetConfig,
} from '../../tables/ultraTable/UltraTable';

import { ultraTableStyles } from '../../tables/ultraTable/styles/ultratable.styles';
import { Position } from '../../tables/ultraTable/types/position.types';
import UnderlyingPositionTable from './UnderlyingPositionTable';

import { ClassNameMap } from '@mui/styles';
import {
  buildColumns,
  buildData,
} from '../../tables/ultraTable/utils/buildUltraTableData';
import {
  ALL_EXPOSURE_PRESETS,
  GeneralPreset,
} from '../../tables/ultraTable/data/ultrapresetColumnData';
import { ALL_EXPOSURE_COLUMNS } from '../../tables/ultraTable/data/presetColumnData';
import { UltraTablePreset } from '../../tables/ultraTable/types/presets.types';
import { EditableFields } from '../../tables/ultraTable/components/EditableCell';
import RaptorLoading from '../../feedback/RaptorLoading';
import { formatDateForCheckingState } from '../../../utilities/dateFormatters';
import { FundInfoComponentProps } from '../../layout/general/GeneralFundInfoWrapper';

export interface ExposureFundProps {
  fundType: FundType;
  fundId: string;
  fundName: string;
  exposureData: DataObject;
}

const standardViewOptions = [
  {
    label: 'General Exposure',
    value: 'generalExposure',
  },
  {
    label: 'Country Exposure',
    value: 'countryExposure',
  },
];
const adepaViewOptions = [
  {
    label: 'General Exposure',
    value: 'generalExposure',
  },
  {
    label: 'Country Exposure',
    value: 'countryExposure',
  },
  {
    label: 'AML Exposure',
    value: 'amlExposure',
  },
];

function getViewOptions(fundId: string, clientName: string) {
  if (clientName === 'mersenne') {
    return adepaViewOptions;
    // I know this is not ideal but the aml exposure option is required for a demo so needs to be done for now.
    // IN future this can be moved to the config file to configure differnt options for different clients. DC - 2023-11-08
  } else if (
    fundId.startsWith('adepa') ||
    fundId.startsWith('gemini') ||
    fundId.startsWith('ifp')
  ) {
    return adepaViewOptions;
  } else {
    return standardViewOptions;
  }
}

const ExposureFund: FC<FundInfoComponentProps> = ({
  positionDate,
  fundId,
  section,
  fundName,
}) => {
  // Add Select for choosing the view
  const [viewOptions, setViewOptions] = useState<any>(null);

  const fundTypes = useSelector(createSectionByIdSelector(section))?.fundTypes;

  // this is problematic, we need to combine the routes to not have aif and standard gem rotues separated,
  // there have been so many issues resrtucturing that for now this hack will have to do to progress the project, Tom Walsh [2023-05-17]
  const urlPrefix = ['AIF', 'AIFMD', 'AIF_PE'].some((r) =>
    fundTypes?.includes(r),
  )
    ? 'aif_gem_data'
    : 'fund_gem_data';

  const exposureData = useFetchData({
    url: `${urlPrefix}/${fundId}/${positionDate}`,
    keyName: `${fundId}_${urlPrefix}_exposure_data_${positionDate}`,
    makeFetch: fundTypes && positionDate ? true : false,
  });

  const classes = ultraTableStyles();

  const clientName = useSelector(clientNameSelector) || 'mersenne';

  const dispatch = useDispatch();
  useEffect(() => {
    // check if the view options need to change.
    const updatedViewOptions = getViewOptions(fundId, clientName);
    // If the options haven't changes then dont do anything
    if (updatedViewOptions === viewOptions) return;
    dispatch(toggleTitleBarLoading(true));
    if (exposureData && !exposureData.isFetching) {
      // Reset view options first
      setViewOptions(null);
      setViewOptions(updatedViewOptions);
    }
    return;
  }, [exposureData]);

  useEffect(() => {
    if (viewOptions) {
      const selectsToAdd: Select[] = [
        {
          titleBarTitle: 'Select View',
          titleBarKey: 'viewSelect',
          values: viewOptions,
          displayOnOverviewPage: false,
          aValueIsAlwaysSelected: true,
          currentValue: viewOptions[0].value,
        },
      ];
      dispatch(
        addAdditionalTitleBarComponents(
          selectsToAdd,
          APPEND_NEW_SELECT_TO_TITLEBAR,
        ),
      );
      dispatch(toggleTitleBarLoading(false));
    }
  }, [viewOptions, positionDate]);

  // Type safe the string
  const viewSelected = useTitleBarSelect('viewSelect') as PresetExposureType;

  // RF This

  if (!exposureData) {
    return null;
  }

  if (exposureData.isFetching) {
    return (
      <RaptorLoading
        centerWrap
        messages={[
          'Loading Exposure Data...',
          'This may take a few seconds...',
        ]}
      />
    );
  }

  return (
    <GeneralComponentErrorShield dataObjects={[exposureData]}>
      <GridItem card xs={4} cardStyle={{ height: '100%' }} style={{ gap: 16 }}>
        <AggregateExposureTable
          fundId={fundId}
          fundName={fundName}
          dataForRender={exposureData}
        />
      </GridItem>
      <GridItem
        xs={4}
        style={{
          alignSelf: 'stretch',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        <AssetGrossExposure
          fundId={fundId}
          fundName={fundName}
          dataForRender={exposureData}
        />

        {/* ------------------------------ */}
        {/* display based on view selected */}
        {viewSelected === 'generalExposure' ? (
          <AssetClassGrossExposureChart dataForRender={exposureData} />
        ) : null}
        {viewSelected === 'countryExposure' ? (
          <CountryCodeGrossExposure dataForRender={exposureData} />
        ) : null}
        {viewSelected === 'amlExposure' ? (
          <AmlLevelGrossExposure dataForRender={exposureData} />
        ) : null}
        {/* ------------------------------ */}
      </GridItem>
      <GridItem xs={4} card cardStyle={{ height: '100%' }}>
        {/* ------------------------------ */}
        {/* display based on view selected */}
        {viewSelected === 'generalExposure' ? (
          <AssetCurrencyExposure
            fundId={fundId}
            fundName={fundName}
            dataForRender={exposureData}
          />
        ) : null}
        {viewSelected === 'countryExposure' ? (
          <AssetCountryCodeExposure
            fundId={fundId}
            fundName={fundName}
            dataForRender={exposureData}
          />
        ) : null}
        {viewSelected === 'amlExposure' ? (
          <AssetAmlLevelExposure
            fundId={fundId}
            fundName={fundName}
            dataForRender={exposureData}
          />
        ) : null}
        {/* ------------------------------ */}
        {/* TODO: The view selected feature will NOT WORK HERE! RF TO NEW PRESET FUNCTIONALITY */}
        {/* In order to make this reusable  */}
      </GridItem>

      <HedgePositionsTableWrapper
        originalData={exposureData}
        fundId={fundId}
        fundName={fundName}
        classes={classes}
        viewSelected={viewSelected}
      />
    </GeneralComponentErrorShield>
  );
};

export default ExposureFund;

export interface UltraTableWrapperProps {
  originalData: any;
  fundId: string;
  fundName: string;
  classes: ClassNameMap;
  viewSelected?: PresetExposureType;
}

//This is only here to deal with the render more hooks issue due to the buildDataFn having a context selector hook
const HedgePositionsTableWrapper: FunctionComponent<UltraTableWrapperProps> = ({
  originalData,
  fundId,
  fundName,
  classes,
  viewSelected,
}) => {
  const [tableAggregate, setTableAggregate] = useState(true);

  const data = buildData(originalData.data[0], tableAggregate);

  const columns = buildColumns(tableAggregate, ALL_EXPOSURE_COLUMNS, classes);

  const tableData: TableData<EditablePosition> = {
    columns,
    data,
  };

  // TODO: Refactor This
  const totalAssetClasses = useMemo(() => {
    const assetClasses: string[] = [];
    tableData.data.forEach((item) => {
      if (item?.asset_class && !assetClasses.includes(item.asset_class)) {
        assetClasses.push(item.asset_class);
      }
    });
    return assetClasses;
  }, [tableData]);

  const presets: UltraTablePreset[] = [];
  totalAssetClasses.forEach((asset) => {
    const preset = ALL_EXPOSURE_PRESETS.find((preset) => preset.name === asset);
    if (preset) {
      presets.push(preset);
    }
  });

  const contextPreset = ALL_EXPOSURE_PRESETS.find(
    (preset) => preset.id === viewSelected,
  );

  const ultraTablePresets: UltraTablePresetConfig = {
    default: GeneralPreset,
    allPresets: presets,
    context: contextPreset,
  };

  const positionDate = originalData.data[0].selected_position_date;

  return (
    <UltraTable<EditablePosition>
      title="Exposure Data"
      tableData={tableData}
      getRowCanExpand={() => true}
      pdfConfig={{
        pdfIdentifier: `${fundId}_exposure_main`,
        pdfTitle: `Exposure Data - ${fundName}`,
        pdfExportGroupName: 'exposure_page',
        pdfExportGroupOrder: 5,
        pdfFileName: `exposure-${fundId}-${
          positionDate || formatDateForCheckingState(new Date())
        }`,
      }}
      columnSelector={{ select: true, group: true }} // hacky solution
      actionBtns={[
        <FormControlLabel
          control={
            <Switch
              checked={tableAggregate}
              onChange={() => setTableAggregate((prev) => !prev)}
              className={classes.switch}
            />
          }
          label={'Aggregate Table'}
          className={classes.switchContainer}
        />,
      ]}
      positionDate={positionDate}
      // nestedTables={{
      //   buildDataFn: buildUnderlyingExposureTableData,
      //   buildColumnsFn: buildUnderLyingExposureColumns,
      // }}

      nestedComponent={UnderlyingPositionTable}
      presets={ultraTablePresets}
      mancoHack={true}
    />
  );
};

export interface EditablePosition extends Position, EditableFields {}
