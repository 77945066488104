import React, { useEffect } from 'react';
import { GeneralOverviewPageRenderedComponentProps } from '../../../types/components/componentTypes';
import MarketsPage from './MarketsPage';
import { useDispatch } from 'react-redux';
import { DatePicker } from '../../../types/redux/pages/PagesStore';
import {
  addAdditionalTitleBarComponents,
  removeTitleBarComponent,
} from '../../../redux/pages/actions';
import {
  APPEND_NEW_DATE_PICKER_TO_TITLEBAR,
  REMOVE_DATE_PICKER_FROM_TITLEBAR,
} from '../../../types/redux/pages/pageTypes';
import dayjs from 'dayjs';
import useTitleBarDatePicker from '../../../hooks/useTitleBarDatePicker';
import { getAllRelevantWeekdays } from '../../../utilities/dateUtilities';
import { useMarketsOverview } from './services/queries';
import RaptorLoading from '../../feedback/RaptorLoading';
import NoDataMessage from '../../feedback/NoDataMessage.component';

function setMarketDate() {
  let currentDate = dayjs();

  // Check if the current date is a weekend
  if (currentDate.day() === 0) {
    // If it's Sunday, set to the previous Friday
    currentDate = currentDate.subtract(2, 'day');
  } else if (currentDate.day() === 6) {
    // If it's Saturday, set to the previous Friday
    currentDate = currentDate.subtract(1, 'day');
  } else {
    // Otherwise, set to the previous day
    currentDate = currentDate.subtract(1, 'day');
  }

  return currentDate.format('YYYY-MM-DD');
}

const MarketsMain: React.FC<GeneralOverviewPageRenderedComponentProps> = (
  props,
) => {
  const marketDate = useTitleBarDatePicker('marketDate');
  const dispatch = useDispatch();

  const { data, isPending, error } = useMarketsOverview(marketDate);

  useEffect(() => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    const availableDates = getAllRelevantWeekdays(
      new Date(2007, 0, 1),
      yesterday,
    );

    const datePickersToAdd: DatePicker[] = [
      {
        displayOnOverviewPage: true,
        titleBarKey: 'marketDate',
        titleBarTitle: 'Market Date:',
        currentValue: setMarketDate(),
        availableDates,
      },
    ];
    dispatch(
      addAdditionalTitleBarComponents(
        datePickersToAdd,
        APPEND_NEW_DATE_PICKER_TO_TITLEBAR,
      ),
    );

    return () => {
      dispatch(
        removeTitleBarComponent(
          'dataDisplay',
          REMOVE_DATE_PICKER_FROM_TITLEBAR,
        ),
      );
    };
  }, []);

  if (isPending) {
    return (
      <RaptorLoading
        centerWrap
        messages={[
          'Loading Market Data Funds',
          'This may take a few seconds...',
        ]}
      />
    );
  }

  if (error) {
    return <NoDataMessage message={'Error Retrieving Data'} />;
  }

  return <MarketsPage data={data} />;
};

export default MarketsMain;
