import { DraftEditorCommand, EditorState, RichUtils } from 'draft-js';
import { KidsData } from '../editor/KidsEditor';

// Sanitise the text from the editor to fit the expected format for the PDF generator
export const sanitiseEditorText = (originalText: string) => {
  // Replace specific HTML tags to fit the expected format for the PDF generator
  const replacedText = originalText
    .replaceAll('<p>', '')
    .replaceAll('</p>', '')
    .replaceAll('<strong>', '<b>')
    .replaceAll('</strong>', '</b>')
    .replaceAll('<em>', '<i>')
    .replaceAll('</em>', '</i>');

  // 'NFC' is a normalisation form for canonical composition => replace the unicode characters
  const normalizedString = replacedText.normalize('NFC');

  // Remove <br> or <br/> tags (line breaks) and replace them with a space
  const sanitisedText = normalizedString
    .replace(/<br\s*\/?>/gi, ' ')
    .replace(/\n/g, ' ')
    .trimEnd(); // Remove trailing spaces at the end

  return sanitisedText;
};

// Reusable function for handling keyboard shortcuts for styling
export const handleEditorKeyCommand = (
  command: DraftEditorCommand,
  editorState: EditorState,
  setEditorState: (state: EditorState) => void,
): 'handled' | 'not-handled' => {
  // If the command is 'split-block' (the Enter key), prevent the line break
  if (command === 'split-block') {
    return 'handled'; // Prevent line break from being inserted
  }

  const newState = RichUtils.handleKeyCommand(editorState, command);
  if (newState) {
    setEditorState(newState);
    return 'handled';
  }
  return 'not-handled';
};

// Function to swap the positions of two elements in an array
export function swapFields(data: KidsData[], index1: number, index2: number) {
  const tempElement = { ...data[index1] };
  data[index1] = { ...data[index2], kiidIndex: index1 };
  data[index2] = { ...tempElement, kiidIndex: index2 };
}

//Re-indexes the `kiidIndex` property of each item in the array starting from a specified index.
export const reIndexKiidContent = (
  content: KidsData[],
  startIndex: number,
): KidsData[] => {
  return content.map((item, index) => ({
    ...item,
    kiidIndex: index >= startIndex ? index + 1 : item.kiidIndex,
  }));
};
