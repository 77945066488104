import { useQuery } from '@tanstack/react-query';
import { getMarketNewsData, getMarketsOverviewData } from './api.routes';

export function useMarketsOverview(selectedDate: string | null) {
  return useQuery({
    queryKey: ['markets-overview', selectedDate],
    queryFn: () => getMarketsOverviewData(selectedDate!),
    enabled: !!selectedDate,
  });
}

export function useMarketNews(news: string | undefined) {
  return useQuery({
    queryKey: ['market-news', news],
    queryFn: () => getMarketNewsData(news!),
    enabled: !!news,
    refetchInterval: 60000,
  });
}
