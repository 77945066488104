import axios, { AxiosInstance } from 'axios';
import FastAPIAuthService from './risksystem3-public-auth-service';
import { FAST_API_URL } from '../utilities/requestClient';
import TokenService from './token-service';
import Cookies from 'js-cookie';

const FastAPIInstance = axios.create({
  baseURL: FAST_API_URL,
  withCredentials: true,
});

// Unsure why the interceptors are not setting the token to the correct token
export function updateAxiosHeaders(
  axiosInstance: AxiosInstance,
  token: string | null,
) {
  if (token) {
    axiosInstance.defaults.headers['Authorization'] = `Bearer ${token}`;
  } else {
    delete axiosInstance.defaults.headers['Authorization'];
  }
}

// // Before making request, do the following
FastAPIInstance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// With response data, do the following
FastAPIInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalRequest = err.config;

    if (err.response) {
      // access token expired
      if (
        err.response.status === 401 &&
        !originalRequest._retry &&
        originalRequest.url !== '/v1/token'
      ) {
        // handle infinite loop
        originalRequest._retry = true;

        try {
          const resp = await axios.get(
            FAST_API_URL +
              '/v1/refresh?refresh_token=' +
              TokenService.getLocalRefreshToken(),
          );

          const access_token = resp.data.access_token;

          TokenService.updateNewAccessToken(access_token);

          FastAPIInstance.defaults.headers.common['Authorization'] =
            `Bearer ${access_token}`;

          return FastAPIInstance(originalRequest);
        } catch (_error) {
          FastAPIAuthService.FastAPILogout();
          Cookies.remove('user_id');
          localStorage.clear();
          window.location.reload(); // When on login page, hacky solution to go back to username and password input if on 2FA code input when token expired
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  },
);

export default FastAPIInstance;
