import React, { FC } from 'react';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { roundNumberToTwoDecimalPlaces } from '../../../../utilities/numberFormatters';
import CustomTable from '../../../tables/CustomTable';

interface AggregateExposureSecondLevelTableProps {
  data: any[];
}

interface secondLevelTableData {
  data: string;
  value: string;
}

const columns: CustomColumn<secondLevelTableData>[] = [
  {
    title: 'Data',
    field: 'data',
    width: '60%',
    render: (rowData: secondLevelTableData) => (
      <span style={{ marginLeft: '12%' }}>{rowData.data}</span>
    ),
  },
  {
    title: 'Field',
    field: 'value',

    cellStyle: {
      textAlign: 'right',
    },
  },
];

function buildAggregateExposureSecondLevelTableData(
  data: any,
): secondLevelTableData[] {
  const tableData: secondLevelTableData[] = [];
  data.forEach((element: any) => {
    const key = Object.keys(element)[0];
    const value = Object.values(element)[0];
    if (key !== 'average_weighted_NAV_per_share') {
      tableData.push({
        data: key,
        value: roundNumberToTwoDecimalPlaces(value as string),
      });
    }
  });
  return tableData;
}

const AggregateExposureSecondLevelTable: FC<
  AggregateExposureSecondLevelTableProps
> = (props) => {
  const { data } = props;

  const tableData = buildAggregateExposureSecondLevelTableData(data);
  return (
    <CustomTable<secondLevelTableData>
      selectedPositionDate={data[0].selected_position_date}
      id={'agg_exposure_table_second_level'}
      title={`Exposure Aggregate Data`}
      showToolbar={false}
      columns={columns}
      data={tableData}
      options={{
        paging: false,
        search: false,
        header: false,
        exportButton: false,
        showTitle: false,
      }}
      noExport
    />
  );
};

export default AggregateExposureSecondLevelTable;
