import React, { FC, Fragment, ReactElement, useEffect, useState } from 'react';
import {
  Column,
  ColumnDef,
  ColumnOrderState,
  Header,
  Row,
  Table,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import makeStyles from '@mui/styles/makeStyles';
import GridItem from '../../../../../layout/GridComponents/GridItem';
import {
  RaptorTheme,
  greys,
  mainColors,
} from '../../../../../../styling/theme';
import clsx from 'clsx';
import { ifNumberAddCommasAndRound } from '../../../../../../utilities/numberFormatters';
import { useDrag, useDrop } from 'react-dnd';
import PositionsSubTable from './PositionsSubTable.component';
import ExportButton from '../../../../../feedback/ExportButton';
import { formatDateForCheckingState } from '../../../../../../utilities/dateFormatters';
import { useDispatch } from 'react-redux';
import { addComponentToPdfExport } from '../../../../../../redux/fileExport/actions';
import { PdfComponentType } from '../../../../../../types/redux/pdfExports/FileExportsStore';
import PositionsTableFilterNumber from './PositionsTableFilterNumber.component';
import { cloneDeep, isEqual } from 'lodash';
import {
  ColumnData,
  FilterStateType,
  MinMaxStateType,
  MinMaxType,
  PresetData,
  SortingStateType,
  SortingTypes,
} from './presetsData/DataTypes';
import StandardPreset from './presetsData/StandardPreset';
import PositionsTableFilterString from './PositionsTableFilterString.component';
import { hexToRGBA } from '../../../../../../utilities/colorUtilities';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { alpha, Button, Tooltip } from '@mui/material';
import { fuzzyFilter } from '../../../../../tables/ultraTable/utils/ultratable.utils';
import { customSortingFn } from '../../../../../tables/ultraTable/utils/ultratable.utils';

export type ByRuleData = {
  id: string;
  indicator: string;
  description: string;
  value: string;
  underlying_positions: ByRuleUnderlyingPositionsData[];
};

export type ByRuleUnderlyingPositionsData = {
  issuer: string;
  isin: string;
  sector_name: string;
  asset_class: string;
  exposure: number;
  gross_exposure: number;
  gross_exposure_pc: number;
  value: string;
};

// the props for the table main positions table
type TableProps<TData> = {
  dataForRender: ByRuleData[];
  positionDate: string;
  fundId: string;
  fundName: string;
  getRowCanExpand: (row: Row<TData>) => boolean;
};

const buildColumns = (columns: ColumnData[], classes: any) => {
  const cols: ColumnDef<ByRuleData>[] = [];

  const expandAllRows = (table: Table<ByRuleData>): void => {
    table.getRowModel().rows.map((row) => {
      row.toggleExpanded();
    });
  };

  const getIsAllRowsExpanded = (table: Table<ByRuleData>): boolean => {
    let numberExpanded = 0;
    table.getRowModel().rows.forEach((nonAggregateRow) => {
      if (nonAggregateRow.getIsExpanded()) {
        numberExpanded += 1;
      }
    });
    return numberExpanded === table.getRowModel().rows.length;
  };

  cols.push({
    id: 'all',
    header: ({ table }) => (
      <div
        onClick={() => expandAllRows(table)}
        {...{
          style: { cursor: 'pointer', width: '0.5rem' },
        }}
      >
        {getIsAllRowsExpanded(table) ? (
          <KeyboardArrowDown className={classes.expanderIcon} />
        ) : (
          <KeyboardArrowRight className={classes.expanderIcon} />
        )}
      </div>
    ),
    cell: ({ row }) => (
      <div
        {...{
          onClick: row.getToggleExpandedHandler(),
          style: { cursor: 'pointer', width: '0.5rem' },
        }}
      >
        {row.getIsExpanded() ? (
          <KeyboardArrowDown className={classes.expanderIcon} />
        ) : (
          <KeyboardArrowRight className={classes.expanderIcon} />
        )}
      </div>
    ),
  });

  cols.push({
    id: 'index',
    header: ({ table }) => (
      <div style={{ width: '0.5rem' }}>
        {table.getCoreRowModel().rows.length}
      </div>
    ),
    cell: (state) => (
      <div style={{ width: '0.5rem' }}>{state.row.index + 1}</div>
    ),
  });

  columns.forEach((dc: ColumnData) => {
    cols.push({
      id: dc.field,
      accessorKey: dc.field,
      header: dc.short_title ? dc.short_title : dc.title,
      footer: dc.field,
      cell: (props) => ifNumberAddCommasAndRound(props.getValue()),
    });
  });
  return cols;
};

// styling for the main positions table component
const useStyles = makeStyles<RaptorTheme>((theme) => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '3rem 3rem 1rem 3rem',
  },
  colSelection: {
    maxHeight: '20rem',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  mainOptions: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    gap: '0.2rem',
    '@media only screen and (max-width: 1600px)': {
      justifyContent: 'flex-end',
    },
  },
  tableContainer: {
    height: '80vh',
    overflow: 'auto',
    marginBottom: '2rem',
    width: '100%',
    '&::-webkit-scrollbar': {
      width: '0.6rem',
      height: '0.6rem',
    },
    '&::-webkit-scrollbar-track': {
      border: '0.2rem solid white',
      backgroundColor: mainColors.lightGrey,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: mainColors.hoverOverMainBlue,
      borderRadius: '1rem',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
  table: {
    tableLayout: 'fixed',
    borderCollapse: 'collapse',
    minWidth: '100%',
  },
  tableHead: {
    position: 'sticky',
  },
  tableBody: {
    paddingBottom: '2rem',
  },
  row: {
    display: 'flex',
    borderTop: `1px solid ${mainColors.lightGrey}`,
    '&:hover': {
      backgroundColor: mainColors.hoverOverWhite,
    },
  },
  aggregateRow: {
    backgroundColor: mainColors.lightGrey,
    '&:hover': {
      backgroundColor: '#D2D2D2',
    },
  },
  rowExpanded: {
    borderRight: `2px solid ${mainColors.hoverOverMainBlue}`,
    borderTop: `2px solid ${mainColors.hoverOverMainBlue}`,
    borderLeft: `2px solid ${mainColors.hoverOverMainBlue}`,
    marginTop: '-3px',
  },
  headerRow: {
    display: 'flex',
    borderBottom: '2px solid grey',
  },
  cell: {
    ...theme.typography.h3,
    fontWeight: 500,
    textAlign: 'center',
    fontSize: '1.3rem',
    width: '100%',
    minWidth: '10rem',
    minHeight: '3rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'black',
  },
  cellWithWordBreak: {
    wordBreak: 'break-word',
  },
  expander: {
    margin: '0 1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  expanderIcon: {
    transform: 'scale(0.9)',
  },
  expanderHead: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  columnHead: {
    width: '100%',
    minWidth: '7rem',
    blockSize: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '0.5rem',
    margin: '0 0.5rem 0.5rem 0.5rem',
  },
  columnHeadTitle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '6.5rem',
  },
  sortIcon: {
    cursor: 'pointer',
  },
  dragHandle: {
    fontWeight: 600,
    border: 'none',
    background: 'none',
    cursor: 'grab',
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    fontSize: 'clamp(1.2rem, 0.85vw, 1.6rem)',
    color: mainColors.mainBlue,
    position: 'relative',
  },
  columnHeadTooltip: {
    backgroundColor: mainColors.mainBlue,
    fontSize: '1.5rem',
  },
  numberInputs: {
    display: 'flex',
    justifyContent: 'center',
    gap: '1rem',
  },
  input: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 8,
    padding: '0 .8rem',
    height: '2.5rem',
    backgroundColor: theme.palette.grey[100],
    fontSize: '1.2rem',
    width: '35%',
  },
  inputRoot: {
    width: '100%',
  },
  picker: {
    width: '80%',
    backgroundColor: theme.palette.grey[100],
    borderRadius: 8,
    height: '2.5rem',
  },
  pickerContent: {
    fontSize: '1.2rem',
  },
  pickerTextField: {
    color: theme.palette.primary.main,
    fontSize: '1rem',
    paddingLeft: '0.8rem',
  },
  switchContainer: {
    marginRight: '3rem',
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: mainColors.mainBlue,
      '&:hover': {
        backgroundColor: alpha(
          mainColors.mainBlue_lighter,
          theme.palette.action.hoverOpacity,
        ),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: mainColors.mainBlue_lighter,
    },
  },
  resetButton: {
    transition: 'width .2s',
    borderRadius: '.8rem',
    padding: '0.5 3rem',
    margin: '0 1rem',
    color: theme.palette.grey[800],
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  assetClassButton: {
    transition: 'width .2s',
    borderRadius: '.8rem',
    padding: '0.5 3rem',
    margin: '0 0.5rem',
    fontSize: 'clamp(1rem, 0.9vw, 1.5rem)',
    backgroundColor: greys.grey400,
    height: '3rem',
    color: 'white',
    fontWeight: 500,
    blockSize: 'fit-content',
    filter: `drop-shadow(0.1rem 0.1rem 0.1rem ${greys.grey400})`,
    '&:hover': {
      backgroundColor: hexToRGBA(mainColors.mainBlue, 0.5),
    },
  },
  activeAssetClassButton: {
    backgroundColor: mainColors.mainBlue,
  },
  noDataMessage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '3rem',
    marginTop: '20vh',
    fontWeight: 500,
    color: mainColors.mainBlue,
  },
  paginationControls: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '1rem 2rem 0 2rem',
  },
  paginationButtons: {
    display: 'flex',
  },
  pageToggleButton: {
    all: 'unset',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    userSelect: 'none',
    border: 'none',
    color: 'white',
    padding: '0.5rem 1rem',
    margin: '0 0.5rem',
    cursor: 'pointer',
    borderRadius: '0.5rem',
    transition: 'background-color .2s ease-in-out',
    backgroundColor: mainColors.mainBlue,
    '&:hover': {
      backgroundColor: mainColors.hoverOverMainBlue,
    },
  },
  pageToggleButtonInactive: {
    pointerEvents: 'none',
    backgroundColor: mainColors.inactiveGrey,
  },
  pageCounts: {
    fontSize: '1.2rem',
    fontWeight: 600,
    margin: '1.5rem 1rem 0 1rem',
  },
  pageInput: {
    width: '5rem',
  },
  itemsPerPageSelect: {
    width: '5rem',
  },
}));

// this function is used to handle the reordering of columns
const reorderColumn = (
  draggedColumnId: string,
  targetColumnId: string,
  columnOrder: string[],
): ColumnOrderState => {
  columnOrder.splice(
    columnOrder.indexOf(targetColumnId),
    0,
    columnOrder.splice(columnOrder.indexOf(draggedColumnId), 1)[0] as string,
  );
  return [...columnOrder];
};

// this component is used to create a draggable column header
const DraggableColumnHeader: FC<{
  header: Header<ByRuleData, unknown>;
  title: string;
  tooltip: string;
  table: Table<ByRuleData>;
  classes: any;
}> = ({ header, title, tooltip, table, classes }) => {
  const { getState, setColumnOrder } = table;
  const { columnOrder } = getState();
  const { column } = header;

  const [, dropRef] = useDrop({
    accept: 'column',
    drop: (draggedColumn: Column<ByRuleData>) => {
      const newColumnOrder = reorderColumn(
        draggedColumn.id,
        column.id,
        columnOrder,
      );
      setColumnOrder(newColumnOrder);
    },
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => column,
    type: 'column',
  });

  return (
    <div
      ref={dropRef}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      className={classes.cell}
    >
      <Tooltip classes={{ tooltip: classes.columnHeadTooltip }} title={tooltip}>
        <div ref={previewRef}>
          {header.isPlaceholder ? null : (
            <button ref={dragRef} className={classes.dragHandle}>
              {title}
            </button>
          )}
        </div>
      </Tooltip>
    </div>
  );
};

// The main positions table component, which will be exported from the file
const ByRuleTable = <T extends object>({
  dataForRender,
  positionDate,
  fundId,
  fundName,
  getRowCanExpand,
}: TableProps<ByRuleData>): ReactElement => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // function to get all unique values from a column
  const getColumnUniqueVals = (columnId: string) => {
    const uniqueVals: string[] = [];
    Array.from(originalData).forEach((row: any) => {
      if (!uniqueVals.includes(row[columnId])) {
        uniqueVals.push(row[columnId]);
      }
    });
    if (typeof uniqueVals[0] === 'number') {
      return [];
    } else {
      return uniqueVals.sort();
    }
  };

  // basic state init items
  const [pdfIdentifier] = useState<string>(`${fundId}_esg_exposure_by_rule`);

  // Here the columns are programatically built, using deault column selections and any default columns
  const [columns, setColumns] = useState(
    buildColumns(StandardPreset.columns, classes),
  );

  const [activePreset, setActivePreset] = useState<PresetData>(StandardPreset);

  const [originalData, setOriginalData] = useState<ByRuleData[]>(dataForRender);
  const [data, setData] = useState<ByRuleData[]>(originalData);

  // this is neded to update the original data states every time the data changes
  useEffect(() => {
    setOriginalData(dataForRender);
  }, [dataForRender]);

  const [currentSortedColumn, setCurrentSortedColumn] = useState<string>('');

  // column selections state, this will be the default selections array until user makes changes
  const [columnSelections, setColumnSelections] = useState<ColumnData[]>(
    cloneDeep(StandardPreset.columns),
  );

  // setup sorting state
  const originalSortingState: SortingStateType = {};
  columnSelections.forEach((col) => (originalSortingState[col.field] = ''));
  const [sortingState, setSortingState] =
    useState<SortingStateType>(originalSortingState);

  // setup filter states
  const originalFilterState: FilterStateType = {};
  Object.keys(originalData[0]).forEach((column) => {
    if (typeof originalData[0][column as keyof ByRuleData] === 'string') {
      originalFilterState[column] = getColumnUniqueVals(column);
    }
  });
  const [filterState, setFilterState] =
    useState<FilterStateType>(originalFilterState);

  // setup min/max states
  const originalMinMaxState: MinMaxStateType = {};
  Object.keys(originalData[0]).forEach((column) => {
    if (typeof originalData[0][column as keyof ByRuleData] === 'number') {
      originalMinMaxState[column] = ['', ''];
    }
  });
  const [minMax, setMinMax] = useState<MinMaxStateType>(originalMinMaxState);

  // filter active state
  const [filterActive, setFilterActive] = useState<boolean>(false);

  // column visibility object required for react-table
  const [columnVisibility, setColumnVisibility] = useState(() => {
    // the column with id 'all' is always set to be shown
    const vis: any = {
      all: true,
      index: true,
    };
    // all other columns visibilities are set by the column selections state
    columnSelections.forEach((col) => {
      vis[col.field] = col.show;
    });
    return vis;
  });

  // column order array required for react-table
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(() => {
    const order: any[] = [columns[0].id, columns[1].id];
    columnSelections.map((column) => order.push(column.field as string));
    return order;
  });

  // function to get an array of chosen column id's in selected order
  const getChosenColumsInOrder = (): string[] => {
    const orderedChoices: string[] = [];
    columnOrder.forEach((col) => {
      if (columnVisibility[col] === true && col !== 'all') {
        orderedChoices.push(col);
      }
    });
    return orderedChoices;
  };

  // state of which assetclass button should be highlighted, if any
  const [activeAssetClass, setActiveAssetClass] = useState<string | null>();

  // build the react-table object, with all required options
  const table = useReactTable<ByRuleData>({
    data,
    columns,
    state: {
      columnVisibility,
      columnOrder,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    getRowCanExpand,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: true,
    filterFns: {
      fuzzy: fuzzyFilter, //define as a filter function that can be used in column definitions
    },
    sortingFns: {
      customSortingFn: customSortingFn,
    },
  });

  // function to convert react-table row model data to export ready data
  const convertRowModelForExport = (rows: Row<ByRuleData>[]) =>
    rows.map((row) => row.original);

  // function to handle pdf export
  const tablePdfExportHandler = async () => {
    const renderColumns: any = [];
    const chosenColumns = getChosenColumsInOrder();
    chosenColumns.map((chosCol) => {
      const item = columns.filter((col) => col.id === chosCol)[0];
      renderColumns.push({
        header: item.header,
        dataKey: item.id,
        render: item.cell,
      });
    });
    return {
      startY: 50,
      columns: renderColumns,
      body: convertRowModelForExport(table.getRowModel().rows),
    };
  };

  // when column selections update, dispatch changes in pdf export data to redux
  useEffect(() => {
    dispatch(
      addComponentToPdfExport({
        identifier: pdfIdentifier,
        handler: tablePdfExportHandler,
        type: PdfComponentType.TABLE,
        title: `ESG Exposure Data By Rule - ${fundName}${
          filterActive ? ' - (Filtered)' : ''
        }`,
      }),
    );
  }, [columnSelections, columnOrder, filterActive]);

  // update filters based on changes made in the popovers
  const handleFiltersChanged = (colId: string, newFilters: string[]) => {
    setFilterState((prev) => ({ ...prev, [colId]: newFilters }));
  };

  // update filters based on min max changes made in the popovers
  const handleMinMaxChanged = (
    colId: string,
    min: MinMaxType,
    max: MinMaxType,
  ) => {
    const temp = [min, max];
    setMinMax((prev) => ({ ...prev, [colId]: temp }));
  };

  // custom filtering code
  useEffect(() => {
    const filteredData: ByRuleData[] = [];
    const columnTypes: Record<string, string | null> = {}; // cache column types

    for (let i = 0; i < originalData.length; i++) {
      const row: any = originalData[i];
      let rowOK = true;

      // filter string columns
      Object.keys(filterState).forEach((column) => {
        const found = filterState[column].some((el) => {
          if (
            typeof el === 'string' &&
            typeof row[column as keyof ByRuleData] === 'string'
          ) {
            return (
              el.toLowerCase() === row[column as keyof ByRuleData].toLowerCase()
            );
          } else {
            return el === row[column as keyof ByRuleData];
          }
        });
        if (!Object.prototype.hasOwnProperty.call(filterState, column)) {
          return;
        }
        if (!found) {
          rowOK = false;
        }
      });

      // filter minMax columns
      Object.keys(minMax).forEach((column) => {
        if (!Object.prototype.hasOwnProperty.call(minMax, column)) {
          return;
        }
        const [minValue, maxValue] = minMax[column];
        if (minValue !== '' && row[column] < minValue) {
          rowOK = false;
        } else if (maxValue !== '' && row[column] > maxValue) {
          rowOK = false;
        }
      });

      if (rowOK) filteredData.push(row);
    }

    // sort filteredData
    if (sortingState[currentSortedColumn] === 'Ascending') {
      filteredData.sort((first: any, second: any) => {
        let a = first[currentSortedColumn];
        let b = second[currentSortedColumn];
        if (columnTypes[currentSortedColumn] === 'string') {
          a = a.toLowerCase();
          b = b.toLowerCase();
        }
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      });
    } else if (sortingState[currentSortedColumn] === 'Descending') {
      filteredData.sort((first: any, second: any) => {
        let a = first[currentSortedColumn];
        let b = second[currentSortedColumn];
        if (columnTypes[currentSortedColumn] === 'string') {
          a = a.toLowerCase();
          b = b.toLowerCase();
        }
        if (a > b) {
          return -1;
        }
        if (a < b) {
          return 1;
        }
        return 0;
      });
    }

    setData(filteredData);
  }, [filterState, minMax, sortingState]);

  const getTypeOfColumn = (columnId: string) => {
    if (originalData.length) {
      return typeof originalData[0][columnId as keyof ByRuleData];
    } else {
      return null;
    }
  };

  // handler for when a colum sort is toggled
  // sorting a column first cancels any other sorts
  const handleSortChanged = (columnId: string) => {
    const order: SortingTypes[] = ['', 'Ascending', 'Descending'];
    const sortType = sortingState[columnId];
    const index = order.indexOf(sortType);
    const newState = cloneDeep(sortingState);
    if (columnId === currentSortedColumn) {
      if (index === 2) {
        newState[columnId] = order[0];
        setSortingState(newState);
      } else {
        newState[columnId] = order[index + 1];
        setSortingState(newState);
      }
    } else {
      setCurrentSortedColumn(columnId);
      Object.keys(newState).forEach((key) => (newState[key] = ''));
      newState[columnId] = 'Ascending';
      setSortingState(newState);
    }
  };

  // handler for when a colum is set to a certain sort value
  // sorting a column first cancels any other sorts
  const handleSortSet = (columnId: string, sortValue: SortingTypes) => {
    setCurrentSortedColumn(columnId);
    const newState = cloneDeep(sortingState);
    Object.keys(newState).forEach((key) => (newState[key] = ''));
    newState[columnId] = sortValue;
    setSortingState(newState);
  };

  // update active filter state by comparing data with original data
  useEffect(() => {
    if (isEqual(originalData, data)) {
      setFilterActive(false);
    } else {
      setFilterActive(true);
    }
  }, [data]);

  // handle change columns
  const columnChoiceHandler = (selections: ColumnData[]) => {
    setColumnSelections(selections);
    // compare column visibilty in the table with new selections, and toggle accordingly
    table.getAllLeafColumns().map((col) => {
      const newCol = selections.filter(
        (selection) => selection.field === col.id,
      );
      if (newCol.length && newCol[0].show !== col.getIsVisible()) {
        col.toggleVisibility();
      }
    });
  };

  // handle the selection of new presets
  const presetChoiceHandler = (preset: PresetData) => {
    setActivePreset(preset);
    // handle columns, first toggle visibility then update column order
    columnChoiceHandler(preset.columns);
    setColumnSelections(preset.columns);
    const order: any[] = [columns[0].id, columns[1].id];
    preset.columns.map((column) => order.push(column.field as string));
    setColumnOrder(order);
    setColumns(buildColumns(preset.columns, classes));
    // handle sorting state, only one column can be sorted at a time so if multiple column sorts are changed in the preset, last one is taken
    if (Object.keys(preset.sortingState).length) {
      Object.keys(preset.sortingState).forEach((column) => {
        if (preset.sortingState[column] !== sortingState[column]) {
          handleSortSet(column, preset.sortingState[column]);
        }
      });
    } else {
      handleSortSet(currentSortedColumn, '');
    }
    // handle filter state, set to original filter state, adding any filters provided in the preset
    const newFilterState = cloneDeep(originalFilterState);
    if (Object.keys(preset.filterState).length) {
      Object.keys(preset.filterState).forEach((column) => {
        newFilterState[column] = preset.filterState[column];
        setFilterState(newFilterState);
      });
    } else {
      setFilterState(newFilterState);
    }
    // handle minMax state, set to original minMax state, adding any filters provided in the preset
    const newMinMaxState = cloneDeep(originalMinMaxState);
    if (Object.keys(preset.minMaxState).length) {
      Object.keys(preset.minMaxState).forEach((column) => {
        newMinMaxState[column] = preset.minMaxState[column];
        setMinMax(newMinMaxState);
      });
    } else {
      setMinMax(newMinMaxState);
    }
  };

  // function to hanlde click events on row
  const handleRowClicked = (
    event: React.MouseEvent<HTMLElement>,
    rowId: string,
  ) => {
    switch (event.detail) {
      // in case of double click, expand row (unless aggregate row)
      case 2: {
        table.getRowModel().rows[Number(rowId)].toggleExpanded();
      }
    }
  };

  // reorder columns when columOrder changes
  useEffect(() => {
    const newOrderColumns: ColumnDef<ByRuleData>[] = [];
    columnOrder.forEach((columnId) => {
      newOrderColumns.push(columns.filter((col) => col.id === columnId)[0]);
    });
    setColumns(newOrderColumns);
  }, [columnOrder]);

  const getColumnName = (
    columns: ColumnData[],
    id: string,
    type: 'long' | 'short',
  ): string => {
    const result = columns.find((col) => {
      return col.field === id;
    });
    if (result) {
      if (type === 'short') {
        if (result?.short_title) {
          return result.short_title;
        } else {
          return result.title;
        }
      } else {
        return result.title;
      }
    } else {
      return '';
    }
  };

  return (
    <GridItem xs={12} card>
      <div className={classes.toolbar}>
        <div className={classes.mainOptions}>
          <Button
            aria-describedby={'reset'}
            variant="text"
            disableElevation
            onClick={() => presetChoiceHandler(StandardPreset)}
            className={classes.resetButton}
          >
            Reset Filters
          </Button>
          <ExportButton
            exportData={convertRowModelForExport(table.getRowModel().rows)}
            pdfIdentifier={`${pdfIdentifier}`}
            fields={getChosenColumsInOrder()}
            fileName={`exposure-${fundId}-${
              positionDate || formatDateForCheckingState(new Date())
            }`}
            selectedPositionDate={positionDate}
            allowPdfExport={true}
          />
        </div>
      </div>
      <GridItem xs={12} className={classes.tableContainer}>
        <table className={classes.table}>
          <thead className={classes.tableHead}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className={classes.headerRow}>
                {headerGroup.headers.map((header) => {
                  return header.id === 'all' || header.id === 'index' ? (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className={clsx(classes.expander, classes.expanderHead)}
                    >
                      {header.isPlaceholder ? null : (
                        <>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </>
                      )}
                    </th>
                  ) : (
                    <th className={classes.columnHead} key={header.id}>
                      <div className={classes.columnHeadTitle}>
                        <div onClick={() => handleSortChanged(header.id)}>
                          <DraggableColumnHeader
                            classes={classes}
                            key={header.id}
                            header={header}
                            table={table}
                            title={getColumnName(
                              activePreset.columns,
                              header.id,
                              'short',
                            )}
                            tooltip={getColumnName(
                              activePreset.columns,
                              header.id,
                              'long',
                            )}
                          />
                        </div>
                      </div>
                      {getTypeOfColumn(header.id) === 'number' ? (
                        <PositionsTableFilterNumber
                          columnID={header.column.id}
                          originalMinMaxState={originalMinMaxState}
                          minMaxState={minMax}
                          onMinMaxChange={handleMinMaxChanged}
                          sortState={sortingState}
                          onSortChange={() =>
                            handleSortChanged(header.column.id)
                          }
                        />
                      ) : (
                        <PositionsTableFilterString
                          columnID={header.column.id}
                          originalFilterState={originalFilterState}
                          filterState={filterState}
                          onFilterChange={handleFiltersChanged}
                          sortState={sortingState}
                          onSortChange={() =>
                            handleSortChanged(header.column.id)
                          }
                        />
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody className={classes.tableBody}>
            {table.getRowModel().rows.length ? (
              table.getRowModel().rows.map((row) => {
                return (
                  <Fragment key={row.id}>
                    <tr
                      className={
                        row.getIsExpanded()
                          ? clsx(classes.row, classes.rowExpanded)
                          : classes.row
                      }
                      onClick={(event) => handleRowClicked(event, row.id)}
                    >
                      {row.getVisibleCells().map((cell) => {
                        return cell.id.includes('all') ||
                          cell.id.includes('index') ? (
                          <td key={cell.id} className={classes.expander}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </td>
                        ) : (
                          <td
                            key={cell.id}
                            className={
                              cell.id.includes('name') &&
                              !cell.id.includes('sector')
                                ? clsx(classes.cell, classes.cellWithWordBreak)
                                : classes.cell
                            }
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </td>
                        );
                      })}
                    </tr>
                    {row.getIsExpanded() ? (
                      <tr>
                        <td colSpan={row.getVisibleCells().length}>
                          <PositionsSubTable row={row} />
                        </td>
                      </tr>
                    ) : null}
                  </Fragment>
                );
              })
            ) : (
              <div className={classes.noDataMessage}>
                No Matches For Current Filters
              </div>
            )}
          </tbody>
        </table>
      </GridItem>
    </GridItem>
  );
};

export default ByRuleTable;
