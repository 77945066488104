import React, { Dispatch, FC, useEffect } from 'react';
import TextEditorTile from './TextEditorTile.component';
import { v4 as uuidv4 } from 'uuid';
import TemplateTextEditorTile from './TemplateTextEditorTile.component';
import SRIChartEditorTile from '../../../components/editorTiles/SRIChartEditorTile.component';
import Raptor2Loading from '../../../../../feedback/Raptor2Loading';
import ChargesTableEditorTile from './ChargesTableEditorTile.component';
import PastPerformanceChartEditorTile from './PastPerformanceChartEditorTile.component';
import BenchmarkTableEditorTile from './BenchmarkTableEditorTile.component';
import { KidsData, Tag } from '../KidsEditor';
import AddFieldButton from '../../../components/buttons/AddFieldbutton.component';
import PageBreakEditorTile from '../../../components/editorTiles/PageBreakEditorTile.component';
import CostsOverTimeTableEditorTile from '../../../components/editorTiles/CostsOverTimeTableEditorTile.component';
import CompositionOfCostsTableEditorTile from '../../../components/editorTiles/CompositionOfCostsTableEditorTile.component';
import PerformanceScenariosEditorTile from '../../../components/editorTiles/PerformanceScenarioTableEditorTile.component';
import withJSONParsing from '../../../../../../hoc/withJSONParsing';
import { reIndexKiidContent, swapFields } from '../../utils/editor.utils';
import { useGenerateNewKIDPdf } from '../../services/mutations';
import {
  convertToPdfVersion,
  priipsKidsDataPdfVersion,
} from '../viewer/EditorPdfViewer.component';
import { useRaidrKids } from '../../context/RaidrKidsContext';

interface PriipsKidsEditorColumnProps {
  priipsKidsData: KidsData[];
  setPriipsKidsContent: Dispatch<React.SetStateAction<KidsData[]>>;
  filter: string;
}
// The editor tile props are stored here as they are generalised for each type of editor tile.
// This allows us to only need to edit in one place if props need to be updated.
export interface EditorTileProps {
  // Contains all the infor required for deciding what editor tile to render
  priipsKidsElement: KidsData;
  setPriipsKidsContent: Dispatch<React.SetStateAction<KidsData[]>>;
  // Allows us to update the field type of the element at the specified index.
  updateFieldType: (
    index: number,
    newFieldType: string,
    defualtText: string,
  ) => void;
  // Allows us to move the element at the specified index up or down in the list of priips kids data.
  moveField: (index: number, direction: 'up' | 'down') => void;
  // Allows us to delete a new field to the list of priips kids data.
  deleteField: (fieldId: string) => void;
  refreshPdf?: () => void;
}
interface GeneralEditorTileProps {
  // Contains all the infor required for deciding what editor tile to render
  priipsKidsElement: KidsData;

  priipsKidsData: KidsData[];
  // Allows us to edit the overall list of the priips kids data.
  setPriipsKidsContent: Dispatch<React.SetStateAction<KidsData[]>>;
  // Allows us to update the field type of the element at the specified index.
  updateFieldType: (
    index: number,
    newFieldType: string,
    defualtText: string,
  ) => void;
  // Allows us to move the element at the specified index up or down in the list of priips kids data.
  moveField: (index: number, direction: 'up' | 'down') => void;
  // Allows us to add a new field to the list of priips kids data.
  addField: (fieldId: string) => void;
  // Allows us to delete a new field to the list of priips kids data.
  deleteField: (fieldId: string) => void;
  // If there is a filter set then we don't want to allow new fields to be added.
  filter: string;
}

const filterMap: { [key: string]: string } = {
  'No Filter': '',
  ManCo: 'manco',
  'Sub Fund': 'sub_fund',
  'Fund Manager': 'fund_manager',
};

// This is a generalised editor tile component that will decide what editor tile to render based on the field type.
const GeneralEditorTile: FC<GeneralEditorTileProps> = ({
  priipsKidsElement,
  updateFieldType,
  moveField,
  addField,
  deleteField,
  filter,
  setPriipsKidsContent,
  priipsKidsData,
}: GeneralEditorTileProps) => {
  const createNewKIDPdf = useGenerateNewKIDPdf();
  const { iframeTitle } = useRaidrKids();

  // State used for deciding what editor tile to render (will be updated when the field type select is changed)
  const [fieldType, setFieldType] = React.useState<String>(
    priipsKidsElement.tag,
  );
  const [kiidIndex, setKiidIndex] = React.useState<number>(
    priipsKidsElement.kiidIndex,
  );
  const fieldId = priipsKidsElement.fieldId;
  // State used for storing the editor tile that will be rendered
  const [editorTile, setEditorTile] = React.useState<JSX.Element>(<></>);
  // This function will update the field type state and also update the overall priips kids data.
  function handleFieldTypeChange(
    index: number,
    newFieldType: string,
    defualtText: string,
  ) {
    setFieldType(newFieldType);
    updateFieldType(index, newFieldType, defualtText);
  }

  function handleMoveField(index: number, direction: 'up' | 'down') {
    moveField(index, direction);
    if (direction === 'up') {
      setKiidIndex(index - 1);
    } else {
      setKiidIndex(index + 1);
    }
  }

  const refreshPdf = () => {
    // Check if the required fields have been filled in.
    if (!priipsKidsData || !priipsKidsData.length) {
      return;
    }
    // Convert the data to the correct format needed for the pdf generator/ the database
    const content: priipsKidsDataPdfVersion[] = [];
    priipsKidsData.forEach((element: any) => {
      content.push(convertToPdfVersion(element));
    });

    // Create an object of formData
    const formData = new FormData();
    // Convert the content to json
    const priipsKidsJson = JSON.stringify(content);
    // Get required data from the first element of the content array
    const fundId = content[0].fund_id_string;
    const fundName = content[0].fund_name;
    const isin = content[0].share_class;
    const documentLanguage = content[0].document_language;
    // Update the formData object.
    formData.append('contents', priipsKidsJson);
    formData.append('fund_id', fundId);
    formData.append('fund_name', fundName);
    formData.append('isin', isin);
    formData.append('document_language', documentLanguage);
    formData.append(
      'params',
      JSON.stringify([
        'contents',
        'fund_id',
        'fund_name',
        'isin',
        'document_language',
      ]),
    );

    createNewKIDPdf
      .mutateAsync(formData)
      .then((response: { data: BlobPart }) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const file = window.URL.createObjectURL(blob);
        const iframe = document.querySelector('iframe');
        if (iframe?.src) {
          iframe.src = file;
          iframe.title = iframeTitle;
        }
      });
  };

  // Wrap the BenchmarkTableEditorTile component with the HOC
  const WrappedBenchmarkTableEditorTile = withJSONParsing(
    BenchmarkTableEditorTile,
  );

  const WrappedPerformanceScenariosEditorTile = withJSONParsing(
    PerformanceScenariosEditorTile,
  );

  const WrappedChargesTableEditorTile = withJSONParsing(ChargesTableEditorTile);

  const WrappedPastPerformanceChartEditorTile = withJSONParsing(
    PastPerformanceChartEditorTile,
  );

  const WrappedCostsOverTimeTableEditorTile = withJSONParsing(
    CostsOverTimeTableEditorTile,
  );

  const WrappedCompositionOfCostsTableEditorTile = withJSONParsing(
    CompositionOfCostsTableEditorTile,
  );

  // Update the editor tile whenever the field type changes
  useEffect(() => {
    switch (fieldType) {
      case 'text_full':
        // Additional check to see if the content is a template field
        // If it is then we need to render the template editor tile
        if (priipsKidsElement.templateId && priipsKidsElement.templateType) {
          setEditorTile(
            <div
              key={`editorTile${priipsKidsElement.kiidIndex}_${priipsKidsElement.fieldId}`}
            >
              <TemplateTextEditorTile
                priipsKidsElement={priipsKidsElement}
                updateFieldType={handleFieldTypeChange}
                moveField={handleMoveField}
                deleteField={deleteField}
                setPriipsKidsContent={setPriipsKidsContent}
              />
              {filter === 'No Filter' &&
              priipsKidsElement.documentLanguage !== 'Swiss English' ? (
                <AddFieldButton addField={addField} fieldId={fieldId} />
              ) : null}
            </div>,
          );
        } else {
          // Otherwise just use the regular text editor tile
          setEditorTile(
            <div
              key={`editorTile${priipsKidsElement.kiidIndex}_${priipsKidsElement.fieldId}`}
            >
              <TextEditorTile
                priipsKidsElement={priipsKidsElement}
                updateFieldType={handleFieldTypeChange}
                moveField={handleMoveField}
                deleteField={deleteField}
                setPriipsKidsContent={setPriipsKidsContent}
                refreshPdf={refreshPdf}
              />
              {priipsKidsElement.documentLanguage !== 'Swiss English' ? (
                <AddFieldButton addField={addField} fieldId={fieldId} />
              ) : null}
            </div>,
          );
        }

        break;
      case 'text_col':
        // Additional check to see if the content is a template field
        // If it is then we need to render the template editor tile
        if (priipsKidsElement.templateId && priipsKidsElement.templateType) {
          setEditorTile(
            <div
              key={`editorTile${priipsKidsElement.kiidIndex}_${priipsKidsElement.fieldId}`}
            >
              <TemplateTextEditorTile
                priipsKidsElement={priipsKidsElement}
                updateFieldType={handleFieldTypeChange}
                moveField={handleMoveField}
                deleteField={deleteField}
                setPriipsKidsContent={setPriipsKidsContent}
              />
              {filter === 'No Filter' &&
              priipsKidsElement.documentLanguage !== 'Swiss English' ? (
                <AddFieldButton addField={addField} fieldId={fieldId} />
              ) : null}
            </div>,
          );
        } else {
          // Otherwise just use the regular text editor tile
          setEditorTile(
            <div
              key={`editorTile${priipsKidsElement.kiidIndex}_${priipsKidsElement.fieldId}`}
            >
              <TextEditorTile
                priipsKidsElement={priipsKidsElement}
                updateFieldType={handleFieldTypeChange}
                moveField={handleMoveField}
                deleteField={deleteField}
                setPriipsKidsContent={setPriipsKidsContent}
                refreshPdf={refreshPdf}
              />
              {priipsKidsElement.documentLanguage !== 'Swiss English' ? (
                <AddFieldButton addField={addField} fieldId={fieldId} />
              ) : null}
            </div>,
          );
        }

        break;
      case 'section_header':
        // Header will use th same editor component as text_full
        // Additional check to see if the content is a template field
        // If it is then we need to render the template editor tile
        if (priipsKidsElement.templateId && priipsKidsElement.templateType) {
          setEditorTile(
            <div
              key={`editorTile${priipsKidsElement.kiidIndex}_${priipsKidsElement.fieldId}`}
            >
              <TemplateTextEditorTile
                priipsKidsElement={priipsKidsElement}
                updateFieldType={handleFieldTypeChange}
                moveField={handleMoveField}
                deleteField={deleteField}
                setPriipsKidsContent={setPriipsKidsContent}
              />
              {filter === 'No Filter' &&
              priipsKidsElement.documentLanguage !== 'Swiss English' ? (
                <AddFieldButton addField={addField} fieldId={fieldId} />
              ) : null}
            </div>,
          );
        } else {
          // Otherwise just use the regular text editor tile
          setEditorTile(
            <div
              key={`editorTile${priipsKidsElement.kiidIndex}_${priipsKidsElement.fieldId}`}
            >
              <TextEditorTile
                priipsKidsElement={priipsKidsElement}
                updateFieldType={handleFieldTypeChange}
                moveField={handleMoveField}
                deleteField={deleteField}
                setPriipsKidsContent={setPriipsKidsContent}
                refreshPdf={refreshPdf}
              />
              {priipsKidsElement.documentLanguage !== 'Swiss English' ? (
                <AddFieldButton addField={addField} fieldId={fieldId} />
              ) : null}
            </div>,
          );
        }
        break;
      case 'srri_chart':
        setEditorTile(
          <div
            key={`editorTile${priipsKidsElement.kiidIndex}_${priipsKidsElement.fieldId}`}
          >
            <SRIChartEditorTile
              priipsKidsElement={priipsKidsElement}
              updateFieldType={handleFieldTypeChange}
              moveField={handleMoveField}
              deleteField={deleteField}
              setPriipsKidsContent={setPriipsKidsContent}
            />
            {priipsKidsElement.documentLanguage !== 'Swiss English' ? (
              <AddFieldButton addField={addField} fieldId={fieldId} />
            ) : null}
          </div>,
        );
        break;
      case 'page_break':
        setEditorTile(
          <div
            key={`editorTile${priipsKidsElement.kiidIndex}_${priipsKidsElement.fieldId}`}
          >
            <PageBreakEditorTile
              priipsKidsElement={priipsKidsElement}
              updateFieldType={handleFieldTypeChange}
              moveField={handleMoveField}
              deleteField={deleteField}
              setPriipsKidsContent={setPriipsKidsContent}
            />
            {priipsKidsElement.documentLanguage !== 'Swiss English' ? (
              <AddFieldButton addField={addField} fieldId={fieldId} />
            ) : null}
          </div>,
        );
        break;
      case 'table_style4':
        setEditorTile(
          <div
            key={`editorTile${priipsKidsElement.kiidIndex}_${priipsKidsElement.fieldId}`}
          >
            <WrappedPerformanceScenariosEditorTile
              priipsKidsElement={priipsKidsElement}
              updateFieldType={handleFieldTypeChange}
              moveField={handleMoveField}
              deleteField={deleteField}
            />
            {priipsKidsElement.documentLanguage !== 'Swiss English' ? (
              <AddFieldButton addField={addField} fieldId={fieldId} />
            ) : null}
          </div>,
        );
        break;
      case 'table_style1_col':
      case 'table_style1':
      case 'table_style3_col':
      case 'table_style3':
        setEditorTile(
          <div
            key={`editorTile${priipsKidsElement.kiidIndex}_${priipsKidsElement.fieldId}`}
          >
            <WrappedBenchmarkTableEditorTile
              priipsKidsElement={priipsKidsElement}
              updateFieldType={handleFieldTypeChange}
              moveField={handleMoveField}
              deleteField={deleteField}
            />
            {priipsKidsElement.documentLanguage !== 'Swiss English' ? (
              <AddFieldButton addField={addField} fieldId={fieldId} />
            ) : null}
          </div>,
        );
        break;
      case 'table_style2_col':
      case 'table_style2':
        setEditorTile(
          <div
            key={`editorTile${priipsKidsElement.kiidIndex}_${priipsKidsElement.fieldId}`}
          >
            <WrappedChargesTableEditorTile
              priipsKidsElement={priipsKidsElement}
              updateFieldType={handleFieldTypeChange}
              moveField={handleMoveField}
              deleteField={deleteField}
            />
            {priipsKidsElement.documentLanguage !== 'Swiss English' ? (
              <AddFieldButton addField={addField} fieldId={fieldId} />
            ) : null}
          </div>,
        );
        break;
      case 'benchmark_chart':
        setEditorTile(
          <div
            key={`editorTile${priipsKidsElement.kiidIndex}_${priipsKidsElement.fieldId}`}
          >
            <WrappedPastPerformanceChartEditorTile
              priipsKidsElement={priipsKidsElement}
              updateFieldType={handleFieldTypeChange}
              moveField={handleMoveField}
              deleteField={deleteField}
            />
            {priipsKidsElement.documentLanguage !== 'Swiss English' ? (
              <AddFieldButton addField={addField} fieldId={fieldId} />
            ) : null}
          </div>,
        );
        break;
      case 'table_style5':
        setEditorTile(
          <div
            key={`editorTile${priipsKidsElement.kiidIndex}_${priipsKidsElement.fieldId}`}
          >
            <WrappedCostsOverTimeTableEditorTile
              priipsKidsElement={priipsKidsElement}
              updateFieldType={handleFieldTypeChange}
              moveField={handleMoveField}
              deleteField={deleteField}
            />
            {priipsKidsElement.documentLanguage !== 'Swiss English' ? (
              <AddFieldButton addField={addField} fieldId={fieldId} />
            ) : null}
          </div>,
        );
        break;
      case 'table_style6':
        setEditorTile(
          <div
            key={`editorTile${priipsKidsElement.kiidIndex}_${priipsKidsElement.fieldId}`}
          >
            <WrappedCompositionOfCostsTableEditorTile
              priipsKidsElement={priipsKidsElement}
              updateFieldType={handleFieldTypeChange}
              moveField={handleMoveField}
              deleteField={deleteField}
            />
            {priipsKidsElement.documentLanguage !== 'Swiss English' ? (
              <AddFieldButton addField={addField} fieldId={fieldId} />
            ) : null}
          </div>,
        );
        break;
      default:
        setEditorTile(
          <div
            key={`editorTile${priipsKidsElement.kiidIndex}_${priipsKidsElement.fieldId}`}
          >
            <TextEditorTile
              priipsKidsElement={priipsKidsElement}
              updateFieldType={handleFieldTypeChange}
              moveField={moveField}
              deleteField={deleteField}
              setPriipsKidsContent={setPriipsKidsContent}
              refreshPdf={refreshPdf}
            />
            {priipsKidsElement.documentLanguage !== 'Swiss English' ? (
              <AddFieldButton addField={addField} fieldId={fieldId} />
            ) : null}
          </div>,
        );
        break;
    }
  }, [fieldType]);
  return (
    <div key={`editorTile${priipsKidsElement.kiidIndex}_${fieldType}`}>
      {editorTile}
    </div>
  );
};

const PriipsKidsEditorColumn: FC<PriipsKidsEditorColumnProps> = ({
  priipsKidsData,
  setPriipsKidsContent,
  filter,
}: PriipsKidsEditorColumnProps) => {
  // This function will be used to update the field type of element at the specified index when the select is updated.
  // Content will also be set to some defualt value for the new field type.
  function updateFieldType(
    index: number,
    newFieldType: string,
    defualtText: string,
  ) {
    setPriipsKidsContent((currentPriipsKidsData) => {
      const updatedKidsData: KidsData[] = [...currentPriipsKidsData];
      updatedKidsData[index].tag = newFieldType as Tag;
      updatedKidsData[index].content = defualtText;
      return updatedKidsData;
    });
  }
  // This function will be used to move a field up or down in the list of priips kids data.
  function moveField(index: number, direction: 'up' | 'down') {
    setPriipsKidsContent((currentPriipsKidsData) => {
      const updatedKidsData: KidsData[] = [...currentPriipsKidsData];

      if (direction === 'up' && index > 0) {
        swapFields(updatedKidsData, index, index - 1);
      } else if (direction === 'down' && index < updatedKidsData.length - 1) {
        swapFields(updatedKidsData, index, index + 1);
      }

      return [...updatedKidsData];
    });
  }

  // This function will be used to add a new field to the list of priips kids data.
  function addField(fieldId: string) {
    setPriipsKidsContent((currentPriipsKidsData: KidsData[]) => {
      // Copy the current priips kids data
      const updatedKidsData: KidsData[] = JSON.parse(
        JSON.stringify(currentPriipsKidsData),
      );

      // Find the index where the new field should be inserted
      const index = updatedKidsData.findIndex(
        (element: KidsData) => element.fieldId === fieldId,
      );

      // Create the new field
      const newField: KidsData = {
        formatOptions: '',
        mancoId: currentPriipsKidsData[0].mancoId,
        tag: 'text_full',
        content: '',
        version: currentPriipsKidsData[0].version,
        editedByName: currentPriipsKidsData[0].editedByName,
        editTimestamp: currentPriipsKidsData[0].editTimestamp,
        editedBy: currentPriipsKidsData[0].editedBy,
        fundName: currentPriipsKidsData[0].fundName,
        commentId: null,
        fieldId: uuidv4(),
        publishedBy: currentPriipsKidsData[0].publishedBy,
        kiidId: currentPriipsKidsData[0].kiidId,
        kiidIndex: index + 1,
        shareClassName: currentPriipsKidsData[0].shareClassName,
        shareClass: currentPriipsKidsData[0].shareClass,
        fundIdString: currentPriipsKidsData[0].fundIdString,
        hasUnresolvedComment: false,
        documentLanguage: currentPriipsKidsData[0].documentLanguage,
        publishedByName: currentPriipsKidsData[0].publishedByName,
        fundId: currentPriipsKidsData[0].fundId,
        isPublished: false,
      };

      // Re-index content and insert the new field
      const reIndexedContent = reIndexKiidContent(updatedKidsData, index + 1);
      reIndexedContent.splice(index + 1, 0, newField);

      return reIndexedContent;
    });
  }
  // This function will be used to delete a field from the list of priips kids data.
  function deleteField(fieldId: string) {
    setPriipsKidsContent((currentPriipsKidsData) => {
      const updatedKidsData = currentPriipsKidsData.filter(
        (element) => element.fieldId !== fieldId,
      );
      return reIndexKiidContent(updatedKidsData, 0);
    });
  }

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      {priipsKidsData.length > 0 &&
        filter === 'No Filter' &&
        priipsKidsData[0].documentLanguage !== 'Swiss English' && (
          <AddFieldButton addField={addField} fieldId={''} />
        )}

      {priipsKidsData.length > 0 ? (
        priipsKidsData
          .filter((priipsKidsElement: KidsData) => {
            const filterValue = filterMap[filter];
            if (filterValue === '') {
              return true;
            } else {
              // First check if the field is a template field
              if (priipsKidsElement.templateType) {
                // If it is then we need to check if the template field is the same as the filter
                // If it is then we need to render the editor tile
                if (priipsKidsElement.templateType === filterValue) {
                  return true;
                } else {
                  // Otherwise we don't render the editor tile
                  return false;
                }
              } else {
                // Otherwise it is a different type of template and we do not want to render it
                return false;
              }
            }
          })
          .map((priipsKidsElement: KidsData, index) => {
            return (
              <GeneralEditorTile
                key={`editorTile${index}_${priipsKidsElement.fieldId}`}
                priipsKidsElement={priipsKidsElement}
                setPriipsKidsContent={setPriipsKidsContent}
                updateFieldType={updateFieldType}
                moveField={moveField}
                addField={addField}
                deleteField={deleteField}
                filter={filter}
                priipsKidsData={priipsKidsData}
              />
            );
          })
      ) : (
        <div
          style={{
            justifyContent: 'space-around',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Raptor2Loading messages={['Loading Editor...']} />
        </div>
      )}
    </div>
  );
};

export default PriipsKidsEditorColumn;
