import React, { FC, Fragment, FunctionComponent, useEffect } from 'react';
import { useRaidrKids } from '../context/RaidrKidsContext';
import { useKIDSOverviewData } from '../services/queries';
import { KIDOverviewData } from '../../../../../types/pages/raidr.types';
import TableLoader from '../../../../ui/TableLoader';
import { useKidsOverviewStyles } from '../styles/KidsOverview.styles';
import ErrorBoundaryMessage from '../../../../ui/ErrorBoundaryMessage';
import NoDataMessage from '../../../../feedback/NoDataMessage.component';
import RaptorSelect from '../../../../selects/RaptorSelect.component';
import { mainColors } from '../../../../../styling/theme';
import { Card, Tooltip } from '@mui/material';
import {
  ColumnDef,
  FilterFn,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { getExpandedRowModel } from '@tanstack/react-table';
import { getFacetedRowModel } from '@tanstack/react-table';
import {
  customSortingFn,
  fuzzyFilter,
} from '../../../../tables/ultraTable/utils/ultratable.utils';
import {
  compactUltraStyles,
  ultraTableStyles,
} from '../../../../tables/ultraTable/styles/ultratable.styles';
import TableSearch from '../../../../tables/ultraTable/components/TableSearch';
import {
  buildOverviewColumns,
  buildSecondLevelTableColumns,
} from './kids-overview-columns';
import { useLocation, useNavigate } from 'react-router-dom';
import { rankItem } from '@tanstack/match-sorter-utils';
import DownloadFileButton from '../../components/buttons/DownloadFilesButton.component';
import {
  KidsOverviewSecondLevelTableData,
  KidsOverviewTableData,
} from '../types/KidsData.types';
import PublishDocumentDialogBox from '../../components/buttons/PublishDocumentDialogBox.component';

// Function to display a message based on the version number of the document
function getVersionStatus(version: string): string {
  // Split the version number into major, minor and patch
  const versionNumbers = version.split('.');
  const major = parseInt(versionNumbers[0]);
  const minor = parseInt(versionNumbers[1]);
  const patch = parseInt(versionNumbers[2]);
  // If the patch is greate than 0 then edits have been made.
  if (patch > 0) return 'Edits made to document, review is available.';
  // If the minor is greater than 0 and the path is 0 then a review is available
  if (minor > 0) return 'Review completed, document is availbe for publishing.';
  // Otherwise the document has been published (minor and patch are 0)
  return 'Latest Published version, no changes have been made.';
}

function buildKidsEditorOverviewTableData(
  data: any[],
  selectedLanguage: string | undefined,
): KidsOverviewTableData[] {
  if (!selectedLanguage) return [];
  const kiidsData = data;
  // Create an object for storing the data.
  type DataStore = {
    [key: string]: KidsOverviewTableData;
  };
  const dataStore: DataStore = {};
  // Loop through the data and add to the data store.
  kiidsData
    .filter((value: any) => value.document_language === selectedLanguage)
    .forEach((value: any, index: number) => {
      // Build the second level data first
      const secondLevelData: KidsOverviewSecondLevelTableData = {
        fundId: value.fund_id_string,
        fundName: value.fund_name,
        isin: value.share_class,
        publicationDate: value.publication_timestamp,
        lasteEditedDate: value.last_edit_timestamp,
        lastEditedBy: value.last_edited_by_name,
        version: value.version,
        fund_uuid: value.fund_id,
        kiid_uuid: value.kiid_id,
        unresolvedComments: value.num_unresolved_comments,
        versionNumbers: value.version_numbers,
        index: index,
        documentLanguage: value.document_language,
        shareClassName: value.share_class_name,
        versionStatus: getVersionStatus(value.version),
      };
      // Check if this fund is NOT already in the data store
      if (!(value.fund_id_string in dataStore)) {
        secondLevelData.index = 0;
        // Create a new entry in the datastore
        dataStore[value.fund_id_string] = {
          fundId: value.fund_id_string,
          fundUUID: value.fund_id,
          fundName: value.fund_name,
          numShareClasses: 1,
          unresolvedComments: value.num_unresolved_comments,
          secondLevelData: [secondLevelData],
        };
      } else {
        // Otherwise just increment number of share classes and append to second level data.
        dataStore[value.fund_id_string].numShareClasses += 1;
        dataStore[value.fund_id_string].unresolvedComments +=
          value.num_unresolved_comments;
        secondLevelData.index =
          dataStore[value.fund_id_string].secondLevelData.length;
        dataStore[value.fund_id_string].secondLevelData.push(secondLevelData);
      }
    });
  // Get just the values form the datastore and return this.
  const dataStoreValues = Object.values(dataStore);

  return dataStoreValues;
}

// Define a function for finding the primary language in the data
function findPrimaryLanguage(data: KIDOverviewData[]): string | undefined {
  const primary = data.find(
    (value) =>
      value.document_language === 'English' || value.is_primary_language,
  );
  return primary?.document_language;
}
const KidsOverview: FC = () => {
  const { currentPage, isPriips } = useRaidrKids();
  const classes = useKidsOverviewStyles();

  // Create objects for storing the selected and available languages in the data
  const [selectedLanguage, setSelectedLanguage] = React.useState<string>();

  const { data: priipsOverviewData, isPending, error } = useKIDSOverviewData();

  const navigate = useNavigate();

  useEffect(() => {
    if (priipsOverviewData) {
      setSelectedLanguage(findPrimaryLanguage(priipsOverviewData));
    }
  }, [priipsOverviewData]);

  if (isPending) {
    return (
      <TableLoader
        title={`${isPriips ? 'PRIIPs KIDs overview' : 'UCITS KIIDs overview'} loading...`}
      />
    );
  }

  if (error) {
    return <ErrorBoundaryMessage />;
  }

  if (!priipsOverviewData) {
    return <NoDataMessage />;
  }

  const languageOptions = priipsOverviewData
    ? [
        ...new Set(
          priipsOverviewData.map((value: any) => value.document_language),
        ),
      ]
    : [];

  const primaryLanguage = findPrimaryLanguage(priipsOverviewData);

  const tableData = buildKidsEditorOverviewTableData(
    priipsOverviewData,
    selectedLanguage,
  );

  if (!selectedLanguage) return <h1>Please Select A Language</h1>;

  const columns = buildOverviewColumns(
    priipsOverviewData[0].manco_id,
    selectedLanguage,
  );

  function handleSelectedLanguageChange(language: string) {
    setSelectedLanguage(language);
    navigate(location.pathname.replace(/^\/[^/]+/, ''), { state: null });
  }

  // Check if isPriips, if so then the view only is only for swiss, if not priips then view only is based on the page url
  const viewOnly = isPriips
    ? !(
        selectedLanguage === primaryLanguage ||
        selectedLanguage === 'Swiss English'
      )
    : currentPage === 'ucits-kiids-overview-view';

  const Header = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      {selectedLanguage && (
        <Tooltip
          classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
          title="Switch between the different languages available for the documents."
          placement={'top'}
          arrow
          enterNextDelay={500}
          enterDelay={500}
        >
          <div
            style={{
              height: '2.5rem',
              width: '30rem',
            }}
          >
            <RaptorSelect
              options={languageOptions}
              handleChoice={handleSelectedLanguageChange}
              defaultOption={selectedLanguage}
              textColor="white"
              backgroundColor={mainColors.mainBlue}
              hoverColor={mainColors.mainBlue_lighter}
            />
          </div>
        </Tooltip>
      )}
      <div
        style={{
          fontSize: '2rem',
          fontWeight: 500,
          letterSpacing: '0.1rem',
          color: mainColors.mainBlue,
        }}
      >
        {isPriips ? 'PRIIPs KIDs Overview' : 'UCITS KIIDs Overview'}
      </div>
    </div>
  );

  return (
    <CompactUltra
      header={Header}
      data={tableData}
      columns={columns}
      NestedComponent={SecondLevelTable}
      viewOnly={viewOnly}
      selectedLanguage={selectedLanguage}
      priipsOverviewData={priipsOverviewData}
    />
  );
};

export default KidsOverview;

// TODO: Ideally, I will figure out a way to pass the threshold value as a prop, but for now, I haven't figured it out, so we create a new function with the lowest threshold value.
export const fuzzyFilterCustom: FilterFn<any> = (
  row,
  columnId,
  value,
  addMeta,
) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

interface CompactUltraProps {
  header: JSX.Element;
  data: any;
  columns: ColumnDef<any>[];
  NestedComponent?: FC<{ data: any; viewOnly: boolean }>;
  viewOnly: boolean;
  selectedLanguage?: string;
  priipsOverviewData: KIDOverviewData[];
}

const CompactUltra: FunctionComponent<CompactUltraProps> = ({
  header,
  data,
  columns,
  NestedComponent,
  viewOnly,
  priipsOverviewData,
  selectedLanguage,
}) => {
  const compactClasses = compactUltraStyles();
  const [globalFilter, setGlobalFilter] = React.useState('');
  const tableContainerRef = React.useRef<HTMLDivElement>(null);

  const location = useLocation();
  const stateFundId = location.state?.fundId;
  const mainRenderedTable = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
    },

    initialState: {
      columnPinning: {
        left: ['index'],
      },
    },

    filterFns: {
      fuzzy: fuzzyFilterCustom,
    },

    sortingFns: {
      customSortingFn: customSortingFn, // Add custom sorting function
    },

    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: 'fuzzy', // Apply fuzzy filter to the global filter (most common use case for fuzzy filter)

    getRowCanExpand: () => true,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  // Expand Row if fundId is Present
  useEffect(() => {
    if (stateFundId) {
      const rowToExpand = mainRenderedTable
        .getRowModel()
        .rows.find((row) => row.original.fundId === stateFundId);

      rowToExpand?.toggleExpanded();
    } else {
      mainRenderedTable.getRowModel().rows.forEach((row) => {
        row.toggleExpanded(false);
      });
    }
  }, [stateFundId, mainRenderedTable, selectedLanguage]);

  return (
    <Card>
      <header
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '2rem',
          borderBottom: '1px solid #e0e0e0',
        }}
      >
        {header}
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            gap: '1rem',
          }}
        >
          <TableSearch onChange={setGlobalFilter} />
          {selectedLanguage && (
            <DownloadFileButton
              mancoId={
                priipsOverviewData && priipsOverviewData.length
                  ? priipsOverviewData[0].manco_id
                  : ''
              }
              buttonText="Download All Files"
              tooltipMessage="Download all available documents. This may take a few moments to complete."
              documentLanguage={selectedLanguage}
            />
          )}
        </div>
      </header>

      <div ref={tableContainerRef} className={compactClasses.tableContainer}>
        <table className={compactClasses.table}>
          <thead className={compactClasses.tableHead}>
            {mainRenderedTable.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      id={header.id}
                      key={header.id}
                      colSpan={header.colSpan}
                      className={compactClasses.headerCell}
                    >
                      {header.isPlaceholder ? null : (
                        <div>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {mainRenderedTable.getRowModel().rows.map((row) => {
              return (
                <Fragment key={row.id}>
                  <tr id={row.original.fundId}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td key={cell.id} className={compactClasses.tableCell}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </td>
                      );
                    })}
                  </tr>

                  {row.getIsExpanded() && (
                    <tr>
                      <td colSpan={row.getVisibleCells().length}>
                        {NestedComponent && (
                          <NestedComponent
                            data={row.original}
                            viewOnly={viewOnly}
                          />
                        )}
                      </td>
                    </tr>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

interface SecondLevelTableProps {
  data: any;
  viewOnly: boolean;
}

const SecondLevelTable: FunctionComponent<SecondLevelTableProps> = ({
  data,
  viewOnly,
}) => {
  const classes = ultraTableStyles();
  const compactClasses = compactUltraStyles();
  const { isPriips } = useRaidrKids();

  const [tableData, setTableData] = React.useState<
    KidsOverviewSecondLevelTableData[]
  >(data.secondLevelData);

  const [globalFilter, setGlobalFilter] = React.useState('');

  const [publishDialogIsShowing, setPublishDialogIsShowing] =
    React.useState<boolean>(false);
  // Create a state object that will store the fundId and shareClass of the documentLangauge that is being published
  const [publishingDocument, setPublishingDocument] = React.useState<{
    fundId: string;
    shareClass: string;
    documentLanguage: string;
    fundName: string;
  }>({ fundId: '', shareClass: '', documentLanguage: '', fundName: '' });

  const location = useLocation();
  const stateFundId = location.state?.fundId;
  const stateIsin = location.state?.isin;

  function updateVersionNumber(
    fundId: string,
    shareClass: string,
    documentLanguage: string,
  ) {
    // Find the index of the document in the table data
    const index = tableData.findIndex(
      (document) =>
        document.fundId === fundId &&
        document.isin === shareClass &&
        document.documentLanguage === documentLanguage,
    );
    // Create a copy of the table data
    const tableDataCopy = [...tableData];
    // Update the version number in the copy
    tableDataCopy[index].version = `${
      parseInt(tableDataCopy[index].version.split('.')[0]) + 1
    }.0.0`;
    // Update the table data
    setTableData(tableDataCopy);
  }

  const columns = buildSecondLevelTableColumns(
    setPublishDialogIsShowing,
    setPublishingDocument,
  );

  const columnVisibility = createColumnVisibility(isPriips, viewOnly);

  useEffect(() => {
    if (stateIsin && stateFundId) {
      const element = document.getElementById(`${stateFundId}-${stateIsin}`);

      if (element) {
        element.scrollIntoView({ behavior: 'auto', block: 'center' });
      }
    }
  }, [stateIsin, stateFundId]);

  const mainRenderedTable = useReactTable({
    data: tableData,
    columns,
    state: {
      globalFilter,
      columnVisibility,
    },

    filterFns: {
      fuzzy: fuzzyFilter, // Define as a filter function that can be used in column definitions
    },

    sortingFns: {
      customSortingFn: customSortingFn, // Add custom sorting function
    },

    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: 'fuzzy', // Apply fuzzy filter to the global filter (most common use case for fuzzy filter)

    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <div style={{ marginBlock: '1rem' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          paddingInline: '2rem',
        }}
      >
        <TableSearch onChange={setGlobalFilter} />
      </div>

      <table className={compactClasses.table}>
        <thead className={compactClasses.tableHead}>
          {mainRenderedTable.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={compactClasses.headerCell}
                  >
                    {header.isPlaceholder ? null : (
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {mainRenderedTable.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td
                      id={`${row.original.fundId}-${row.original.isin}`}
                      key={cell.id}
                      className={compactClasses.tableCell}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {publishDialogIsShowing && (
        <PublishDocumentDialogBox
          setDialogIsShowing={setPublishDialogIsShowing}
          fundId={publishingDocument.fundId}
          shareClass={publishingDocument.shareClass}
          documentLanguage={publishingDocument.documentLanguage}
          fundName={publishingDocument.fundName}
          updateVersionNumber={updateVersionNumber}
        />
      )}
    </div>
  );
};

function createColumnVisibility(
  isPriips: boolean,
  viewOnly: boolean,
): VisibilityState {
  if (!viewOnly) {
    //If user is in full mode then only these columns depends on PRIIPS or not
    return {
      review: isPriips,
      publishChanges: isPriips,
    };
  } else {
    //If user is in view only mode then only these columns are visible
    return {
      versionStatus: false,
      edit: false,
      review: !isPriips,
      publishChanges: !isPriips,
    };
  }
}
