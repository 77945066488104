// This component deals with both the PRIIPS KIDS and UCITS KIIDs Viewers. It is used to view the KID in a PDF format.

import { FC, useEffect } from 'react';
import useRouteParams from '../../../../../hooks/useRouteParams';
import { useDispatch } from 'react-redux';
import {
  toggleControlBarShowing,
  updateControlBarComponents,
} from '../../../../../redux/ui/controlbar/actions';
import { useNavigate } from 'react-router-dom';
import KidsEditorGridItem from '../layout/KidsEditorGridItem.component';
import Raptor2Loading from '../../../../feedback/Raptor2Loading';
import RevertVersionNumberButton from '../../components/buttons/RevertVersionNumberButton.component';
import { Typography } from '@mui/material';
import BackToOverviewBtn from '../../components/buttons/BackToOverviewBtn';
import { useRaidrKids } from '../context/RaidrKidsContext';
import { useKIDSContentData } from '../services/queries';
import { useGenerateNewKIDPdf } from '../services/mutations';
import { useRaidrViewStyles } from '../styles/Raidr.styles';
import ErrorBoundaryMessage from '../../../../ui/ErrorBoundaryMessage';

const KidsView: FC = () => {
  const classes = useRaidrViewStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { backToOverviewLink, iframeTitle } = useRaidrKids();

  // Get route params
  const fundId = useRouteParams('fundId')['fundId'];
  const isin = useRouteParams('isin')['isin'];
  const documentLanguage =
    useRouteParams('documentLanguage')['documentLanguage'];
  const version = useRouteParams('version')['version'];
  const latestVersion = useRouteParams('latestVersion')['latestVersion'];

  const { data, isPending, error } = useKIDSContentData(
    fundId,
    isin,
    documentLanguage,
    version,
  );

  const createNewKIDPdf = useGenerateNewKIDPdf();

  const backToOverview = () => {
    dispatch(toggleControlBarShowing(false));
    navigate(backToOverviewLink, { state: { fundId } });
  };

  // Why I cant figure to remove this useEffect? Eventually Mr Barneveld
  useEffect(() => {
    if (!isPending && data && data.length) {
      getPDF();
      updateControlBar();
    }
  }, [data]);

  if (isPending) {
    return (
      <Raptor2Loading centerWrap messages={[`Loading Raidr ${fundId}...`]} />
    );
  }

  if (error) {
    return <ErrorBoundaryMessage />;
  }

  const getPDF = () => {
    // Create an object of formData
    const formData = new FormData();
    // Convert the content to json
    // sort data by kiid_index
    data.sort((a: any, b: any) => {
      return a.kiid_index - b.kiid_index;
    });
    const kidsJson = JSON.stringify(data);
    const fundId = data[0].fund_id_string;
    const fundName = data[0].fund_name;
    const isin = data[0].share_class;
    const documentLanguage = data[0].document_language;
    // Update the formData object.
    formData.append('contents', kidsJson);
    formData.append('fund_id', fundId);
    formData.append('fund_name', fundName);
    formData.append('isin', isin);
    formData.append('document_language', documentLanguage);
    formData.append(
      'params',
      JSON.stringify([
        'contents',
        'fund_id',
        'fund_name',
        'isin',
        'document_language',
      ]),
    );

    createNewKIDPdf
      .mutateAsync(formData)
      .then((response: { data: BlobPart }) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const file = window.URL.createObjectURL(blob);
        const iframe = document.querySelector('iframe');
        if (iframe) {
          iframe.src = file;
          iframe.title = iframeTitle;
        }
      });
  };

  const updateControlBar = () => {
    const componentsList = [
      <BackToOverviewBtn
        backToOverview={backToOverview}
        dontSetPosition
        key="back_to_overview"
      />,
      <div className={classes.fundNameContainer}>
        <Typography variant="h6">{`${data[0].fund_name} - ${isin}`}</Typography>
      </div>,
      <div className={classes.versionNumberContainer}>
        <Typography variant="h6">{`Version: ${version}`}</Typography>
      </div>,
    ];
    if (version !== latestVersion) {
      componentsList.push(
        <RevertVersionNumberButton
          fundId={fundId || ''}
          shareClass={isin || ''}
          version={version || ''}
          documentLanguage={documentLanguage || ''}
          backToOverviewLink={backToOverviewLink}
        />,
      );
    }
    dispatch(updateControlBarComponents(componentsList));
    dispatch(toggleControlBarShowing(true));
  };

  return (
    <KidsEditorGridItem xs={12}>
      <div className={classes.viewerContainer}>
        <div className={classes.iframeContainer}>
          <iframe
            src=""
            width={createNewKIDPdf.isPending ? '0%' : '100%'}
            height="100%"
            title={'kid.pdf'}
          ></iframe>
          {createNewKIDPdf.isPending ? (
            <Raptor2Loading centerWrap messages={['Generating Document...']} />
          ) : null}
        </div>
      </div>
    </KidsEditorGridItem>
  );
};

export default KidsView;
