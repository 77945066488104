import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import MarketsAssets from './subComponents/MarketsAssets';
import MarketsGraph, { MarketsGraphProp } from './subComponents/MarketsGraph';
import MarketsNews from './subComponents/MarketsNews';
import { useSelector } from 'react-redux';
import { componentsForExportCSVSelector } from '../../../redux/fileExport/selectors';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '2em 2em 0 2em',
    width: '100%',
    height: '100%',
    gap: '2em',
  },
  bottom: {
    display: 'flex',
    gap: '2em',
  },
});

const mapTimeseriesData = (res: any): MarketsGraphProp[] => {
  return Object.keys(res.timeseries_graphs).map((key) => {
    const assetData_Graph = res.timeseries_graphs[key];

    if (
      assetData_Graph.price_timeseries &&
      Array.isArray(assetData_Graph.price_timeseries) &&
      assetData_Graph.return_timeseries &&
      Array.isArray(assetData_Graph.return_timeseries)
    ) {
      const price_numbers = assetData_Graph.price_timeseries.map(
        (item: [number, number]) => item[1],
      );
      const price_times = assetData_Graph.price_timeseries.map(
        (item: [number, number]) => item[0],
      );
      const return_numbers = assetData_Graph.return_timeseries.map(
        (item: [number, number]) => item[1],
      );
      const return_times = assetData_Graph.return_timeseries.map(
        (item: [number, number]) => item[0],
      );

      return {
        asset: key,
        price: price_numbers,
        price_time: price_times,
        return: return_numbers,
        return_time: return_times,
      };
    } else {
      return {
        asset: key,
        price: [],
        price_time: [],
        return: [],
        return_time: [],
      };
    }
  });
};

interface MarketsPageProps {
  data: any;
}

const MarketsPage: React.FC<MarketsPageProps> = ({ data }) => {
  const classes = useStyles();
  const [displayedNews, setDisplayedNews] = useState<string>('all');
  const [displayedGraph, setDisplayedGraph] = useState<string>(
    String(data.data_table[0].asset_name),
  );

  return (
    <div className={classes.container}>
      <MarketsAssets
        marketAssetData={data.data_table}
        columns={data.table_columns}
        setGraph={setDisplayedGraph}
        setNews={setDisplayedNews}
      />
      <div className={classes.bottom}>
        <MarketsGraph
          market_graph_data={mapTimeseriesData(data)}
          which_asset={displayedGraph}
        />
        <MarketsNews selectedNews={displayedNews} />
      </div>
    </div>
  );
};

export default MarketsPage;
