import {
  ADD_COMPONENT_TO_PDF_EXPORT,
  CLEAR_PDF_EXPORT,
  CLEAR_CSV_EXPORT,
  ADD_COMPONENT_EXPORT_CSV,
  PdfExportsAction,
} from '../../types/redux/pdfExports/exportTypes';
import { FileExportStore } from '../../types/redux/pdfExports/FileExportsStore';

export default function FileExportReducer(
  state: FileExportStore = {
    componentsToExport: [],
    componentsToExportCSV: [],
    loadingPagePdf: false,
  },
  action: PdfExportsAction,
) {
  switch (action.type) {
    case CLEAR_PDF_EXPORT:
      return {
        ...state,
        componentsToExport: [],
      };
    case ADD_COMPONENT_TO_PDF_EXPORT:
      const currentComponents = state.componentsToExport.filter(
        (comp) => comp.identifier !== action.payload.identifier,
      );

      return {
        ...state,
        componentsToExport: [...currentComponents, action.payload],
      };

    case CLEAR_CSV_EXPORT:
      return {
        ...state,
        componentsToExportCSV: [],
      };
    case ADD_COMPONENT_EXPORT_CSV:
      const currentCSVComponents = state.componentsToExportCSV.filter(
        (comp) => comp.fileName !== action.payload.fileName,
      );
      return {
        ...state,
        componentsToExportCSV: [...currentCSVComponents, action.payload],
      };
    default:
      return state;
  }
}
