import makeStyles from '@mui/styles/makeStyles';
import { ReactElement, useEffect } from 'react';
import { RaptorTheme } from '../../../../../../styling/theme';
import usePngFromComponent from '../../../../../../hooks/usePngFromComponent';
import { useDispatch } from 'react-redux';
import { addComponentToPdfExport } from '../../../../../../redux/fileExport/actions';
import { PdfComponentType } from '../../../../../../types/redux/pdfExports/FileExportsStore';
import IndividualPie from '../IndividualPie/individualPie.component';
import { Typography } from '@mui/material';

const useMancoSummaryContainerStyles = makeStyles((theme: RaptorTheme) => ({
  regulationContainerRoot: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '1rem 3rem',
    paddingRight: 0,
    transition: 'all .3s',
    height: '100%',
    width: '100%',
    zIndex: 1,
    position: 'relative',
    cursor: 'pointer',
  },
  textSection: {
    display: 'flex',
    borderRadius: 4,
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '25%',
  },
  subTitle: {
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.8rem',
    },
  },
  subTitle_info: {
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.8rem',
    },
    fontSize: '2.4rem',
    fontWeight: 'bold',
    marginBottom: '1.5rem',
    color: theme.palette.primary.main,
  },
  pieSection: {
    cursor: 'pointer',
    display: 'flex',
    borderRadius: 4,
    width: '75%',
    justifyContent: 'space-around',
  },
}));

interface MancoSummaryProps {
  data: any;
  selectedKey: string;
  setSelectedKeyFunction: (key: string) => void;
}
export interface StyleProps {
  title: string;
}

const EMIRKeys = [
  'comodity_derivatives',
  'credit_derivatives',
  'equity_derivatives',
  'fx_derivatives',
  'interest_rate_derivatives',
];

function getNumberSubFunds(data: any): number {
  if (data.data[0].funds) return Object.keys(data.data[0].funds).length;
  return 0;
}

function MancoSummaryContainer({
  data,
  selectedKey,
  setSelectedKeyFunction,
}: MancoSummaryProps): ReactElement {
  const classes = useMancoSummaryContainerStyles();
  const numberSubFunds = getNumberSubFunds(data);
  const positionDate = data.data[0].manco.selected_position_date || null;

  const dispatch = useDispatch();
  const { ref, handleDownload } = usePngFromComponent();

  useEffect(() => {
    dispatch(
      addComponentToPdfExport({
        identifier: 'emir-manco-summary',
        handler: handleDownload,
        type: PdfComponentType.GENERAL_COMPONENT_IMAGE,
        title: '',
        dontMoveToNewPage: true,
        specificYPosition: undefined,
        specificXPosition: undefined,
        alignment: undefined,
        dontIncrementYPosition: false,
        groupOrder: 0,
      }),
    );
  }, [data, ref]);

  return (
    <div className={classes.regulationContainerRoot} ref={ref}>
      <div className={classes.textSection}>
        <Typography variant="h1" style={{ borderBottom: '1rem' }}>
          {'Manco Summary'}
        </Typography>
        <div>
          <Typography variant="h2" className={classes.subTitle}>
            Number of Sub-Funds:
          </Typography>
          <Typography variant="h3" className={classes.subTitle_info}>
            {numberSubFunds}
          </Typography>
          {positionDate && (
            <>
              <Typography variant="h2" className={classes.subTitle}>
                Position Date:
              </Typography>
              <Typography variant="h3" className={classes.subTitle_info}>
                {positionDate}
              </Typography>
            </>
          )}
        </div>
      </div>
      <div className={classes.pieSection}>
        <IndividualPie
          handlePieClick={() => setSelectedKeyFunction('interest_rate_section')}
          isSelectedKey={selectedKey === 'interest_rate_section'}
          title={'Interest Rate Derivatives'}
          data={data.data[0].manco['interest_rate_section_status']}
        />
        <IndividualPie
          handlePieClick={() => setSelectedKeyFunction('credit_section')}
          isSelectedKey={selectedKey === 'credit_section'}
          title={'Credit Derivatives'}
          data={data.data[0].manco['credit_section_status']}
        />
        <IndividualPie
          handlePieClick={() => setSelectedKeyFunction('equity_section')}
          isSelectedKey={selectedKey === 'equity_section'}
          title={'Equity Derivatives'}
          data={data.data[0].manco['equity_section_status']}
        />
        <IndividualPie
          handlePieClick={() => setSelectedKeyFunction('commodity_section')}
          isSelectedKey={selectedKey === 'commodity_section'}
          title={'Commodity Derivatives'}
          data={data.data[0].manco['commodity_section_status']}
        />
        <IndividualPie
          handlePieClick={() => setSelectedKeyFunction('fx_section')}
          isSelectedKey={selectedKey === 'fx_section'}
          title={'Fx Derivatives'}
          data={data.data[0].manco['fx_section_status']}
        />
      </div>
    </div>
  );
}

export default MancoSummaryContainer;
