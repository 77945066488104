import { mainColors } from '../../../../../../styling/theme';

// Create a component for displaying the version number with color coding
export const VersionNumberDisplay = ({ version }: { version: string }) => {
  // Split the version number into major, minor and patch
  const versionNumbers = version.split('.');
  const major = parseInt(versionNumbers[0]);
  const minor = parseInt(versionNumbers[1]);
  const patch = parseInt(versionNumbers[2]);
  // Decide what color to be used based on the version number
  let color = mainColors.Pass_darker;
  // If Edits have been made then display Amber
  if (patch > 0) color = mainColors.accentOrange;
  // If a review is available then display blue
  if (minor > 0) color = mainColors.mainBlue_lighter;
  // Otherwise the document is published and we leave it green.
  return (
    <div
      style={{
        backgroundColor: color,
        color: 'white',
        borderRadius: '0.5rem',
        padding: '0.2rem',
        // width: '5rem',
        fontWeight: 400,
        // textAlign: 'center',
      }}
    >
      {version}
    </div>
  );
};
