import { ColumnDef } from '@tanstack/react-table';
import { VersionNumberDisplay } from './components/VersionNumberDisplay';
import EditIcon from '@mui/icons-material/Edit';
import PreviewIcon from '@mui/icons-material/Preview';
import PublishIcon from '@mui/icons-material/Publish';
import { greys, mainColors } from '../../../../../styling/theme';
import { IconButton, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useRaidrKids } from '../context/RaidrKidsContext';
import { makeStyles } from '@mui/styles';
import { Dispatch } from 'react';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import {
  KidsOverviewSecondLevelTableData,
  KidsOverviewTableData,
} from '../types/KidsData.types';
import DownloadFileButton from '../../components/buttons/DownloadFilesButton.component';
import TrackedChangesButton from '../../components/buttons/TrackedChangesButton.component';
import ViewSpecificVersionButton from '../../components/buttons/ViewSpecificVersionButton.component';

const useStyles = makeStyles(() => ({
  arrow: {
    '&:before': {
      border: '1px solid #8e8e8e',
    },
    color: greys.grey600,
  },
  tooltip: {
    backgroundColor: greys.grey600,
    border: '1px solid #8e8e8e',
    color: 'white',
  },
}));

export function buildOverviewColumns(
  mancoId: string,
  selectedLanguage: string,
): ColumnDef<KidsOverviewTableData>[] {
  return [
    {
      accessorKey: 'index',
      id: 'index',
      header: ({ table }) => (
        <span style={{ fontSize: '1.4rem' }}>{table.getRowCount()}</span>
      ),
      cell: ({ row }) => {
        return (
          <div
            {...{
              onClick: row.getToggleExpandedHandler(),
            }}
          >
            {row.getIsExpanded() ? (
              <IconButton>
                <KeyboardArrowDown />
              </IconButton>
            ) : (
              <IconButton>
                <KeyboardArrowRight />
              </IconButton>
            )}
          </div>
        );
      },
    },

    {
      accessorKey: 'fundId',
      id: 'fundId',
      header: 'Fund ID',
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: 'fundName',
      id: 'fundName',
      header: 'Fund Name',
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: 'numShareClasses',
      id: 'numShareClasses',
      header: '#Share Classes',
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: 'download',
      id: 'download',
      header: 'Download',
      cell: ({ row }) => {
        return (
          <DownloadFileButton
            mancoId={mancoId}
            fundId={row.original.fundId}
            fundName={row.original.fundName}
            buttonText="Download Relevant Files"
            tooltipMessage="Download documents for all share classes in this fund. This may take a few moments to complete."
            documentLanguage={selectedLanguage}
          />
        );
      },
    },
  ];
}

export function buildSecondLevelTableColumns(
  setDialogIsShowing: Dispatch<boolean>,
  setPublishingDocument: Dispatch<{
    fundId: string;
    shareClass: string;
    documentLanguage: string;
    fundName: string;
  }>,
): ColumnDef<KidsOverviewSecondLevelTableData>[] {
  const navigate = useNavigate();
  const classes = useStyles();
  const { isPriips } = useRaidrKids();

  return [
    {
      accessorKey: 'shareClassName',
      id: 'shareClassName',
      header: 'Share Class ID',
      meta: {
        filterVariant: 'range',
      },
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: 'isin',
      id: 'isin',
      header: 'ISIN',
      filterFn: 'arrIncludesSome',
      meta: {
        filterVariant: 'select',
      },
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: 'version',
      id: 'version',
      header: 'Version',
      cell: ({ row }) => (
        <VersionNumberDisplay version={row.original.version} />
      ),
    },
    {
      accessorKey: 'versionStatus',
      id: 'versionStatus',
      header: 'Version Status',
    },
    {
      accessorKey: 'publicationDate',
      id: 'publicationDate',
      header: 'Publication Timestamp',
      cell: ({ row }) =>
        row.original.publicationDate.substring(0, 16).replace('T', ' '),
    },
    {
      accessorKey: 'lasteEditedDate',
      id: 'lasteEditedDate',
      header: 'Last Edited Timestamp',
      cell: ({ row }) =>
        row.original.lasteEditedDate.substring(0, 16).replace('T', ' '),
    },

    {
      accessorKey: 'lastEditedBy',
      id: 'lastEditedBy',
      header: 'Last Edited Timestamp',
    },
    {
      accessorKey: 'edit',
      id: 'edit',
      header: 'Edit',
      cell: ({ row }) => (
        <Tooltip
          classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
          title="Edit Document"
          placement={'top'}
          arrow
          enterNextDelay={1000}
          enterDelay={1000}
        >
          <EditIcon
            sx={{
              fontSize: '3rem',
              color: 'white',
              backgroundColor: mainColors.mainBlue,
              borderRadius: 1,
              padding: '0.2rem',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: mainColors.mainBlue_lighter,
              },
            }}
            onClick={() => {
              navigate(
                isPriips
                  ? `/raidr/priips-kids-editor?fundId=${row.original.fundId}&isin=${row.original.isin}&documentLanguage=${row.original.documentLanguage}`
                  : `/raidr/ucits-kiids-editor?fundId=${row.original.fundId}&isin=${row.original.isin}&documentLanguage=${row.original.documentLanguage}`,
              );
            }}
          />
        </Tooltip>
      ),
    },
    {
      accessorKey: 'compareVersions',
      id: 'compareVersions',
      header: 'Compare Versions',
      cell: ({ row }) => (
        <TrackedChangesButton
          fundId={row.original.fundId}
          shareClass={row.original.isin}
          documentLanguage={row.original.documentLanguage}
          versionNumbers={row.original.versionNumbers}
          initialComparisonVersion={`${row.original.version.split('.')[0]}.0.0`}
          initialTargetVersion={row.original.version}
        />
      ),
    },
    {
      accessorKey: 'viewVersion',
      id: 'viewVersion',
      header: 'View Version',
      cell: ({ row }) => (
        <ViewSpecificVersionButton
          fundId={row.original.fundId}
          shareClass={row.original.isin}
          documentLanguage={row.original.documentLanguage}
          versionNumbers={row.original.versionNumbers}
          latestVersionNumber={row.original.version}
        />
      ),
    },
    {
      accessorKey: 'review',
      id: 'review',
      header: 'Review',
      cell: ({ row }) => {
        const isDisabled = row.original.version.split('.')[2] === '0';

        return (
          <Tooltip
            classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
            title={
              isDisabled
                ? 'There are currently no changes for review'
                : 'Click here to review latest changes against the most recent published version'
            }
            placement={'top'}
            arrow
          >
            <PreviewIcon
              sx={{
                fontSize: '3rem',
                color: 'white',
                backgroundColor: isDisabled
                  ? greys.grey400
                  : mainColors.mainBlue,
                borderRadius: 1,
                padding: '0.2rem',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor:
                    row.original.version.split('.')[2] === '0'
                      ? greys.grey400
                      : mainColors.mainBlue_lighter,
                },
              }}
              onClick={() => {
                if (!isDisabled) {
                  const root = isPriips
                    ? 'priips-kids-review'
                    : 'ucits-kids-review';

                  navigate(
                    `/raidr/${root}?fundId=${row.original.fundId}&isin=${
                      row.original.isin
                    }&documentLanguage=${
                      row.original.documentLanguage
                    }&currentVersion=${row.original.version}&publishedVersion=${
                      row.original.version.split('.')[0] + '.0.0'
                    }`,
                  );
                }
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      accessorKey: 'publishChanges',
      id: 'publishChanges',
      header: 'Publish Changes',
      cell: ({ row }) => {
        const isDisabled =
          row.original.version.split('.')[1] === '0' ||
          (row.original.version.split('.')[2] === '0' &&
            row.original.version.split('.')[1] === '0');

        return (
          <Tooltip
            classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
            title={
              isDisabled
                ? 'Review required before document can be published'
                : 'Click here to Publish the latest version of the document.'
            }
            placement={'top'}
            arrow
          >
            <PublishIcon
              sx={{
                fontSize: '3rem',
                color: 'white',
                backgroundColor: isDisabled
                  ? greys.grey400
                  : mainColors.mainBlue,
                borderRadius: 1,
                padding: '0.2rem',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: isDisabled
                    ? greys.grey400
                    : mainColors.mainBlue,
                },
              }}
              onClick={() => {
                if (!isDisabled) {
                  setDialogIsShowing(true);
                  setPublishingDocument({
                    fundId: row.original.fundId,
                    shareClass: row.original.isin,
                    documentLanguage: row.original.documentLanguage,
                    fundName: row.original.fundName,
                  });
                }
              }}
            />
          </Tooltip>
        );
      },
    },
  ];
}
