import React, { useEffect } from 'react';
import { DataObject } from '../../../../types/redux/data/dataTypes';
import GridItem from '../../../layout/GridComponents/GridItem';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Rectangle,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { greys, mainColors } from '../../../../styling/theme';
import makeStyles from '@mui/styles/makeStyles';
import { hexToRGBA } from '../../../../utilities/colorUtilities';
import ExportButton from '../../../feedback/ExportButton';
import { percentageToNdecialPlaces } from '../../../../utilities/numberFormatters';
import ChartDownloadButton from '../../../buttons/ChartDownloadButton';
import { useDispatch } from 'react-redux';
import usePngFromRecharts from '../../../../hooks/usePngFromRecharts';
import { addComponentToPdfExport } from '../../../../redux/fileExport/actions';
import { PdfComponentType } from '../../../../types/redux/pdfExports/FileExportsStore';
import FileSaver from 'file-saver';

interface TopTenExposuresBarChartProps {
  data: DataObject;
}

interface TopTenExposuresBarChartData {
  name: string;
  value: number;
}

const useStyles = makeStyles(() => ({
  toolbar: {
    width: '100%',
    marginLeft: '3rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '1rem 2.5rem 1rem 1.5rem',
  },
  cardTitle: {
    color: mainColors.mainBlue,
    fontSize: '2.2rem',
    fontWeight: 400,
    margin: '1rem 1.5rem',
  },
  buttonGroupContainer: {
    display: 'flex',
    gap: '0.2rem',
    margin: '0 2rem',
  },
  customTooltipContainer: {
    backgroundColor: 'white',
    padding: '1rem 1.5rem',
    borderRadius: '0.5rem',
    border: `1px solid ${mainColors.mainBlue}`,
    width: '20rem',
    color: mainColors.mainBlue,
  },
  tooltipAsset: {
    fontSize: '2rem',
    fontWeight: 500,
  },
  tooltipValue: {
    fontSize: '1.6rem',
    fontWeight: 600,
  },
  tooltipValueBelowZero: {
    color: mainColors.Fail,
  },
  toggleButton: {
    all: 'unset',
    transition: 'width .2s',
    borderRadius: '.8rem',
    padding: '0.2rem 2rem',
    fontSize: 'clamp(1rem, 0.9vw, 1.5rem)',
    backgroundColor: greys.grey400,
    height: '3rem',
    color: 'white',
    fontWeight: 500,
    cursor: 'pointer',
    filter: `drop-shadow(0.1rem 0.1rem 0.1rem ${greys.grey400})`,
  },
  toggleButtonBlue: {
    '&:hover': {
      backgroundColor: hexToRGBA(mainColors.mainBlue, 0.5),
    },
  },
  toggleButtonGreen: {
    '&:hover': {
      backgroundColor: hexToRGBA(mainColors.Pass, 0.5),
    },
  },
  activeButtonBlue: {
    backgroundColor: mainColors.mainBlue,
  },
  activeButtonGreen: {
    backgroundColor: mainColors.Pass,
  },
}));

const CustomTooltip = ({ active, payload, label }: any) => {
  const classes = useStyles();
  if (active && payload && payload.length) {
    return (
      <div className={classes.customTooltipContainer}>
        <div>
          <div className={classes.tooltipAsset}>{label}</div>
          <hr />
          <div className={classes.tooltipValue}>
            {percentageToNdecialPlaces(payload[0].value, 2)}
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

const CustomBar = (props: any) => {
  return <Rectangle {...props} fill={mainColors.mainBlue} />;
};

const buildChartData = (data: any): TopTenExposuresBarChartData[] => {
  if (!data.data.length) return [];
  const chartData: TopTenExposuresBarChartData[] = [];
  data.data[0].map((asset: any[]) => {
    chartData.push({
      name: asset[0],
      value: asset[1],
    });
  });
  return chartData.sort((a, b) => b.value - a.value);
};

const TopTenExposuresBarChart: React.FC<TopTenExposuresBarChartProps> = ({
  data,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const builtChartData = buildChartData(data);
  const title = `Top 10 Exposures`;

  const { ref, handleDownload } = usePngFromRecharts();

  useEffect(() => {
    dispatch(
      addComponentToPdfExport({
        identifier: 'peTopTenExposuresBarChart',
        handler: handleDownload,
        type: PdfComponentType.BAR_CHART,
        title: title,
        dontMoveToNewPage: true,
        specificYPosition: undefined,
        specificXPosition: undefined,
        alignment: 'center',
        dontIncrementYPosition: undefined,
      }),
    );
  }, [ref]);

  const saveImage = async () => {
    const imageData = await handleDownload();
    if (imageData) {
      FileSaver.saveAs(imageData.data, title + '.png');
    }
  };

  return (
    <GridItem
      card
      xs={12}
      cardStyle={{
        height: '100%',
        maxHeight: '50rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <div className={classes.toolbar}>
        <h2 className={classes.cardTitle}>{title}</h2>
        <div className={classes.buttonGroupContainer}>
          <ExportButton
            fields={Object.keys(builtChartData[0])}
            exportData={builtChartData}
          />
          <ChartDownloadButton handler={saveImage} />
        </div>
      </div>
      <ResponsiveContainer width="100%">
        <BarChart
          width={500}
          height={200}
          data={builtChartData}
          margin={{
            top: 20,
            right: 50,
            left: 30,
            bottom: 20,
          }}
          ref={ref}
        >
          <CartesianGrid strokeDasharray="5 5" vertical={false} />
          <XAxis
            dataKey="name"
            interval={0}
            angle={-20}
            textAnchor="end"
            height={110}
            tick={{
              stroke: mainColors.mainBlue,
              strokeWidth: 1,
              fontSize: '1.3rem',
            }}
            tickLine={true}
          >
            <Label
              style={{
                textAnchor: 'middle',
                fontSize: '200%',
                fontWeight: 400,
                color: mainColors.mainBlue,
              }}
              value={'Country'}
              position={'insideBottom'}
            />
          </XAxis>
          <YAxis
            width={100}
            tickFormatter={(tickItem) => {
              return percentageToNdecialPlaces(tickItem, 1);
            }}
            tick={{
              stroke: mainColors.mainBlue,
              strokeWidth: 1,
              fontSize: '1.3rem',
            }}
            tickLine={true}
          >
            <Label
              style={{
                textAnchor: 'middle',
                fontSize: '200%',
                fontWeight: 400,
                color: mainColors.mainBlue,
              }}
              angle={270}
              value={'Exposure %'}
              position={'insideLeft'}
            />
          </YAxis>
          <Tooltip content={<CustomTooltip />} />
          <ReferenceLine y={0} stroke={greys.grey600} />
          <Bar dataKey={'value'} shape={CustomBar} />
        </BarChart>
      </ResponsiveContainer>
    </GridItem>
  );
};

export default TopTenExposuresBarChart;
