import { ReactElement, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { RaptorTheme, mainColors } from '../../../../../styling/theme';
import GridItem from '../../../../layout/GridComponents/GridItem';
import GenericGaugeChart from '../../../../charts/GenericGaugeChart';
import { useWindowSize } from 'react-use';
import { percentageToTwoDecimalPlaces } from '../../../../../utilities/numberFormatters';
import { useDispatch } from 'react-redux';
import { addComponentToPdfExport } from '../../../../../redux/fileExport/actions';
import usePngFromComponent from '../../../../../hooks/usePngFromComponent';
import { PdfComponentType } from '../../../../../types/redux/pdfExports/FileExportsStore';
import { Typography } from '@mui/material';

const useMancoSummaryContainerStyles = makeStyles((theme: RaptorTheme) => ({
  regulationContainerRoot: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '1rem 3rem',
    paddingRight: 0,
    transition: 'all .3s',
    height: '100%',
    width: '100%',
    zIndex: 1,
    position: 'relative',
    cursor: 'pointer',
  },
  textSection: {
    display: 'flex',
    borderRadius: 4,
    flexDirection: 'column',
    width: '20%',
  },
  subTitle: {
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.8rem',
    },
  },
  subTitle_info: {
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.8rem',
    },
    fontSize: '2.4rem',
    fontWeight: 'bold',
    marginBottom: '1.5rem',
    color: theme.palette.primary.main,
  },
  pieSection: {
    cursor: 'pointer',
    display: 'flex',
    borderRadius: 4,
    width: '80%',
    justifyContent: 'space-around',
    marginTop: '1rem',
  },
}));

interface MancoSummaryProps {
  data: any;
  periodStart: string | null;
  periodEnd: string | null;
}
export interface StyleProps {
  title: string;
}

function getNumberSubFunds(data: any): number {
  // Account for summary row in data
  if (data.data[0].funds.length) return data.data[0].funds.length - 1;
  return 0;
}

function getMancoLevelData(data: any): any {
  if (!data.data.length) return undefined;
  if (!('manco' in data.data[0])) return undefined;
  return data.data[0].manco;
}

function MancoSummaryContainer({
  data,
  periodStart,
  periodEnd,
}: MancoSummaryProps): ReactElement {
  const dispatch = useDispatch();

  const classes = useMancoSummaryContainerStyles();
  const { width } = useWindowSize();

  const numberSubFunds = getNumberSubFunds(data);
  const mancoData = getMancoLevelData(data);

  const { ref, handleDownload } = usePngFromComponent();

  useEffect(() => {
    dispatch(
      addComponentToPdfExport({
        identifier: 'kpi-monitoring-summary-container',
        handler: handleDownload,
        type: PdfComponentType.GENERAL_COMPONENT_IMAGE,
        title: '',
        dontMoveToNewPage: true,
        specificYPosition: undefined,
        specificXPosition: undefined,
        alignment: undefined,
        dontIncrementYPosition: false,
        specificHeight: 55,
        groupOrder: 0,
      }),
    );
  }, [ref]);

  return (
    <GridItem
      card
      key="manco-overview"
      item
      cardStyle={{ maxHeight: '30rem' }}
      xs={12}
    >
      <div className={classes.regulationContainerRoot} ref={ref}>
        <div className={classes.textSection}>
          <Typography
            variant="h1"
            style={{
              marginBottom: '3rem',
            }}
          >
            {'Manco Summary'}
          </Typography>
          <div>
            <Typography variant="h2" className={classes.subTitle}>
              Number of Sub-Funds:
            </Typography>
            <Typography variant="h3" className={classes.subTitle_info}>
              {numberSubFunds}
            </Typography>
            <Typography variant="h2" className={classes.subTitle}>
              Period Start Date
            </Typography>
            <Typography variant="h3" className={classes.subTitle_info}>
              {periodStart}
            </Typography>
            <Typography variant="h2" className={classes.subTitle}>
              Period End Date:
            </Typography>
            <Typography variant="h3" className={classes.subTitle_info}>
              {periodEnd}
            </Typography>
          </div>
        </div>
        <div className={classes.pieSection}>
          <GenericGaugeChart
            width={width / 4}
            height={250}
            fillValue={mancoData ? mancoData['T Delivery (Reports)'] : 0}
            fullValue={mancoData ? mancoData['Total Reports'] : 0}
            title={'T Delivery'}
            chartColor={mainColors.Pass_darker}
            centerText={
              mancoData
                ? percentageToTwoDecimalPlaces(
                    mancoData['T Delivery (Percentage)'],
                  )
                : '0.00%'
            }
            startLabel="0%"
            endLabel="100%"
            textBelow={
              mancoData
                ? `${mancoData['T Delivery (Reports)']}/${mancoData['Total Reports']} Reports`
                : ''
            }
          />
          <GenericGaugeChart
            width={width / 4}
            height={250}
            fillValue={mancoData ? mancoData['T+1 Delivery (Reports)'] : 0}
            fullValue={mancoData ? mancoData['Total Reports'] : 0}
            title={'T+1 Delivery'}
            chartColor={mainColors.Alert_darker}
            centerText={
              mancoData
                ? percentageToTwoDecimalPlaces(
                    mancoData['T+1 Delivery (Percentage)'],
                  )
                : '0.00%'
            }
            startLabel="0%"
            endLabel="100%"
            textBelow={
              mancoData
                ? `${mancoData['T+1 Delivery (Reports)']}/${mancoData['Total Reports']} Reports`
                : ''
            }
          />
          <GenericGaugeChart
            width={width / 4}
            height={250}
            fillValue={mancoData ? mancoData['T+2 Delivery (Reports)'] : 0}
            fullValue={mancoData ? mancoData['Total Reports'] : 0}
            title={'T+2 Delivery'}
            chartColor={mainColors.Fail_darker}
            centerText={
              mancoData
                ? percentageToTwoDecimalPlaces(
                    mancoData['T+2 Delivery (Percentage)'],
                  )
                : '0.00%'
            }
            startLabel="0%"
            endLabel="100%"
            textBelow={
              mancoData
                ? `${mancoData['T+2 Delivery (Reports)']}/${mancoData['Total Reports']} Reports`
                : ''
            }
          />
        </div>
      </div>
    </GridItem>
  );
}

export default MancoSummaryContainer;
