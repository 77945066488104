/*
 * The buildCsv function needs an array of strings detailing which parameters from the data to use
 */

export function buildCsv(csvFields: string[], data: any[]) {
  // step 1: map over each row
  return data.map((datum) => {
    const newObj: { [key: string]: any } = {};
    csvFields.forEach((field) => {
      if (datum[field] !== null) {
        newObj[field] = datum[field];
      } else {
        newObj[field] = '';
      }
    });
    return newObj;
  });
}
