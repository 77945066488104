import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  activeDateSelector,
  activePageDisplayNameSelector,
  activePageIdSelector,
  activeSectionSelector,
  createActiveFundSelectorBySection,
  createPageDetailsSelector,
  createSectionByIdSelector,
  createSectionFundIdsSelector,
  createSectionFundNamesSelector,
  isTitlebarLoadingSelector,
  titleBarDatePickersSelector,
  titleBarInputsSelector,
  titleBarSelectsSelector,
} from '../../../redux/pages/selectors';
import FundPicker from '../../selects/FundPicker';
import TitleBarSelect from '../../selects/TitleBarSelect';
import {
  DatePicker,
  Input,
  Select,
} from '../../../types/redux/pages/PagesStore';
import RaptorLoading from '../../feedback/RaptorLoading';
import {
  updateActiveDate,
  updateActiveDateRangeEnd,
  updateActiveDateRangeStart,
  updateAvailableDates,
  updateSpecificTitleBarComponent,
  updateSpecificTitlebarInput,
} from '../../../redux/pages/actions';
import GeneralInput from '../../inputs/GeneralInput';

import { useNavigate, useLocation } from 'react-router-dom';
import { GeneralFileExportButton } from './components/FileExportButton';
import RaptorDatePicker from '../../selects/RaptorDatePicker.component';
import dayjs from 'dayjs';
import useRouteParams from '../../../hooks/useRouteParams';
import {
  getAvailableDateXMonthsAgo,
  getMostRecentAvailableDate,
} from '../../../utilities/dateUtilities';
import { mainColors } from '../../../styling/theme';
import { zIndexes } from '../../../styling/zIndexes';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
import {
  DATE_FORMAT,
  getAllRelevantWeekdays,
} from '../../../utilities/dateUtilities';
import { getPositionsDates } from '../../../routes/endpoints/general.routes';

// This functions is used to generate all the dates between a start date and the current date for adding to RaptorDatePicker

// For some reason Raptor date picker is using the sunday as the first day of the week

const useStyles = makeStyles({
  container: {
    height: '6rem',
    width: '100%',
    transition: 'transform .2s',
    backgroundColor: 'white',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: mainColors.mainBlue,
    zIndex: zIndexes.titleBar,
  },
  pageTitle: {
    fontSize: '3rem',
    fontWeight: 500,
    backgroundColor: 'yellow',
  },
  content: {
    flex: '1',
    backgroundColor: 'red',
  },
  actions: {
    backgroundColor: 'yellow',
  },
});

const TitleBar: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();

  const pageName = useSelector(activePageDisplayNameSelector);

  const activeSection = useSelector(activeSectionSelector);
  const pageId = useSelector(activePageIdSelector);
  const pageDetailSelector = createPageDetailsSelector(pageId, activeSection);
  const fundIdsSelector = createSectionFundIdsSelector(activeSection || '');
  const fundNamesSelector = createSectionFundNamesSelector(activeSection || '');
  const fundIds = useSelector(fundIdsSelector);

  const fundNames = useSelector(fundNamesSelector);
  const pageDetails = useSelector(pageDetailSelector);
  const activeFundSelector = createActiveFundSelectorBySection(activeSection);
  const activeFund = useSelector(activeFundSelector);
  const selects = useSelector(titleBarSelectsSelector);
  const inputs = useSelector(titleBarInputsSelector);
  const datePickers = useSelector(titleBarDatePickersSelector);
  const activeDate = useSelector(activeDateSelector);
  const titleBarIsLoading = useSelector(isTitlebarLoadingSelector);
  const routeParams = useRouteParams('positionDate');
  const sectionDetails = useSelector(
    createSectionByIdSelector(activeSection ?? ''),
  );

  // necessary states for handling dates
  const [weekdays] = useState<string[]>(getAllRelevantWeekdays());
  const [positionDates, setPositionsDates] = useState<string[]>(weekdays);
  const [positionDatesLoading, setPositionDatesLoading] =
    useState<boolean>(true);

  const [dateRangeStart, setDateRangeStart] = useState<string>('');
  const [dateRangeEnd, setDateRangeEnd] = useState<string>('');

  useEffect(() => {
    dispatch(updateActiveDateRangeStart(dateRangeStart));
  }, [dateRangeStart]);

  useEffect(() => {
    dispatch(updateActiveDateRangeEnd(dateRangeEnd));
  }, [dateRangeEnd]);

  const dates = getPositionsDates(sectionDetails);

  // handle the asyncronous nature of the fetches for dates
  // position dates loading is used to show loading circle while fetches in progress
  useEffect(() => {
    setPositionDatesLoading(true);
    if (dates && !dates.isFetching) {
      setPositionDatesLoading(false);
      if (activeFund) {
        setPositionsDates(dates.data[0][activeFund.id]);
        dispatch(updateAvailableDates(dates.data[0][activeFund.id]));
        if (dateRangeStart === '') {
          setDateRangeStart(
            getAvailableDateXMonthsAgo(3, dates.data[0][activeFund.id]),
          );
        }
        if (dateRangeEnd === '') {
          setDateRangeEnd(
            getMostRecentAvailableDate(dates.data[0][activeFund.id]),
          );
        }
      } else {
        setPositionsDates(dates.data[0]['overview']);
        dispatch(updateAvailableDates(dates.data[0]['overview']));
        if (dateRangeStart === '') {
          setDateRangeStart(
            getAvailableDateXMonthsAgo(3, dates.data[0]['overview']),
          );
        }
        if (dateRangeEnd === '') {
          setDateRangeEnd(
            getMostRecentAvailableDate(dates.data[0]['overview']),
          );
        }
      }
    }
  }, [dates, activeFund]);

  // every time the page changes, update the available dates.
  useEffect(() => {
    if (dates && !dates.isFetching) {
      if (activeFund) {
        setPositionsDates(dates.data[0][activeFund.id]);
        dispatch(updateAvailableDates(dates.data[0][activeFund.id]));
      } else {
        setPositionsDates(dates.data[0]['overview']);
        dispatch(updateAvailableDates(dates.data[0]['overview']));
      }
    }
    dispatch(updateActiveDateRangeStart(dateRangeStart));
    dispatch(updateActiveDateRangeEnd(dateRangeEnd));
  }, [pageId]);

  // every time section changes changes, update the active date in redux
  useEffect(() => {
    dispatch(updateActiveDate(null));
    // dirty hack felix required in order to have esg work for adepa demo, it needs to be removed ASAP for a proper fix [Tom Walsh, 2023-05-23]
    if (
      dayjs(routeParams.positionDate, DATE_FORMAT).format(DATE_FORMAT) ===
        routeParams.positionDate &&
      routeParams.positionDate.length === 10
    ) {
      dispatch(updateActiveDate(routeParams.positionDate));
    } else if (dates && !dates.isFetching) {
      if (dates && !dates.isFetching) {
        if (activeFund) {
          dispatch(updateActiveDate(dates.data[0][activeFund.id][0]));
        } else {
          dispatch(updateActiveDate(dates.data[0]['overview'][0]));
        }
      }
    }
    dispatch(
      updateActiveDateRangeStart(getAvailableDateXMonthsAgo(3, positionDates)),
    );
    dispatch(
      updateActiveDateRangeEnd(getMostRecentAvailableDate(positionDates)),
    );
    /* Commented out for now as it was causing issues with changing pages on snif - need to investigate further DC 2024-02-24 */
    // }, [activeSection, activeFund]);
  }, [activeSection]);

  // function to handle date choice made in datepicker
  const handlePositionDateChange = (date: Date | null) => {
    // first get relevant points in the current route
    const currentUrl = location.pathname + location.search;
    const indexOfPositionDate = currentUrl.indexOf('&positionDate');
    const newUrl =
      indexOfPositionDate !== -1
        ? currentUrl.slice(0, indexOfPositionDate)
        : currentUrl;
    const question = newUrl.indexOf('?') === -1 ? '?' : '';
    const and = newUrl.indexOf('fundId') === -1 ? '' : '';

    // then build a new route with date, and push new date to redux
    if (
      activeDate !== dayjs(date).format(DATE_FORMAT) &&
      positionDates.includes(dayjs(date).format(DATE_FORMAT))
    ) {
      navigate(
        newUrl +
          question +
          and +
          '&positionDate=' +
          dayjs(date).format(DATE_FORMAT),
      );
      dispatch(updateActiveDate(dayjs(date).format(DATE_FORMAT)));
    }
  };

  const titleBarSelectsAndInputs = (
    <>
      {selects &&
        selects.length > 0 &&
        selects.map((select: Select) => {
          const show = select.show ?? true;
          if (
            select.displayOnOverviewPage ||
            (!select.displayOnOverviewPage && activeFund)
          ) {
            if (show) {
              return (
                <TitleBarSelect
                  selectTitle={select.titleBarTitle}
                  key={select.titleBarKey}
                  labelsAndValues={select.values}
                  placeholderValue={select.placeholderValue}
                  selectedValue={select.currentValue}
                  selectKeyName={select.titleBarKey}
                />
              );
            } else {
              return null;
            }
          } else {
            return null;
          }
        })}
      {inputs &&
        inputs.length > 0 &&
        inputs.map((input: Input) => {
          if (
            input.displayOnOverviewPage ||
            (!input.displayOnOverviewPage && activeFund)
          ) {
            return (
              <GeneralInput
                setInput={(val) =>
                  dispatch(updateSpecificTitlebarInput(input.titleBarKey, val))
                }
                inputValue={input.currentValue}
                inputKey={input.titleBarKey}
                key={input.titleBarKey}
                inputTitle={input.titleBarTitle}
                width="2rem"
                defaultValue={input.defaultValue || ''}
                allowedValues={input.allowedValues}
              />
            );
          } else {
            return null;
          }
        })}
      {datePickers &&
        datePickers.length > 0 &&
        datePickers.map((picker: DatePicker, index: number) => {
          return (
            <RaptorDatePicker
              key={picker.titleBarKey}
              title={picker.titleBarTitle}
              handler={(val) =>
                dispatch(
                  updateSpecificTitleBarComponent(
                    picker.titleBarKey,
                    dayjs(val).format('YYYY-MM-DD'),
                    'datePickers',
                  ),
                )
              }
              datesToInclude={picker.availableDates || weekdays}
              selectedDate={dayjs(picker.currentValue)}
              tiedToActiveDate={false}
            />
          );
        })}
    </>
  );

  return (
    <div className={classes.container}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          variant="h1"
          style={{
            marginLeft: 0,
            marginRight: '2rem',
          }}
        >
          {pageName}
        </Typography>
        {fundNames !== null ? (
          <>
            {activeSection &&
              pageDetails &&
              pageDetails.useFundPicker &&
              fundIds &&
              fundNames && (
                <FundPicker
                  currentPage={pageId || ''}
                  labels={fundNames}
                  values={fundIds}
                  currentlySelected={activeFund?.id || 'none_selected'}
                  section={activeSection}
                />
              )}
            {/* Standard date picker */}
            {pageDetails &&
              pageDetails.usePositionDatePicker &&
              (!positionDatesLoading ? (
                <>
                  <RaptorDatePicker
                    key={'date-picker'}
                    title={'Position Date: '}
                    handler={handlePositionDateChange}
                    datesToInclude={positionDates}
                    selectedDate={
                      activeDate ? dayjs(activeDate) : dayjs(positionDates[0])
                    }
                    tiedToActiveDate={true}
                  />
                </>
              ) : (
                <RaptorLoading setWidth={'4rem'} />
              ))}

            {/* Standard date range picker */}
            {pageDetails &&
              pageDetails.usePositionDatePickerRange &&
              (!positionDatesLoading ? (
                <>
                  <RaptorDatePicker
                    key={'date-range-picker-start'}
                    title={'Start Date:'}
                    handler={(date: Date | null) => {
                      setDateRangeStart(dayjs(date).format(DATE_FORMAT));
                    }}
                    datesToInclude={positionDates}
                    selectedDate={dayjs(dateRangeStart)}
                    tiedToActiveDate={false}
                  />
                  <RaptorDatePicker
                    key={'date-range-picker-end'}
                    title={'End Date:'}
                    handler={(date: Date | null) => {
                      setDateRangeEnd(dayjs(date).format(DATE_FORMAT));
                    }}
                    datesToInclude={positionDates}
                    selectedDate={dayjs(dateRangeEnd)}
                    tiedToActiveDate={false}
                  />
                </>
              ) : (
                <RaptorLoading setWidth={'4rem'} />
              ))}
          </>
        ) : pageName && pageName !== 'Dashboard' ? (
          <RaptorLoading setWidth="4rem" />
        ) : null}
        {titleBarIsLoading ? (
          <RaptorLoading setWidth={'4rem'} />
        ) : (
          titleBarSelectsAndInputs
        )}
      </div>
      {pageDetails?.exportable && <GeneralFileExportButton />}
    </div>
  );
};

export default TitleBar;
