import React, { FC, FunctionComponent, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  activeDateSelector,
  activePageSelector,
  createActiveFundSelectorBySection,
  createPageDetailsSelector,
  createSectionByIdSelector,
} from '../../../redux/pages/selectors';
import useRouteParams from '../../../hooks/useRouteParams';
import GeneralComponentErrorShield from '../../general/GeneralComponentErrorShield';
import {
  updateFund,
  updatePage,
  updateSection,
} from '../../../redux/pages/actions';
import { clearPdfExports } from '../../../redux/fileExport/actions';
import { useAppendOverviewFunds } from '../../../hooks/useAppendOverviewFunds';
import { Grid, Typography } from '@mui/material';
import { flexify } from '../../../utilities/cssMixins';
import { mainColors } from '../../../styling/theme';

interface GeneralPageWrapperProps {
  children: React.ReactNode;
  pageId: string;
}

export const GeneralPageWrapper: FC<GeneralPageWrapperProps> = ({
  pageId,
  children,
}) => {
  const { section } = useParams<any>();

  const pageDetails = useSelector(createPageDetailsSelector(pageId!, section!));
  const { fundId } = useRouteParams(['fundId']);
  const navigate = useNavigate();
  const activeFundSelector = createActiveFundSelectorBySection(section!);
  const location = useLocation();
  const sectionDetails = useSelector(createSectionByIdSelector(section!));
  const activePage = useSelector(activePageSelector);
  const activeDate = useSelector(activeDateSelector);

  const activeFund = useSelector(activeFundSelector);
  const dispatch = useDispatch();

  const fundsData = useAppendOverviewFunds(
    sectionDetails?.fundTypes,
    sectionDetails?.rsResources,
  ) ?? {
    isFetching: true,
    error: null,
    data: [],
  };

  const pageRequestedSelector = createPageDetailsSelector(pageId!, section!);
  const pageRequested = useSelector(pageRequestedSelector);

  useEffect(() => {
    dispatch(clearPdfExports());
  }, [fundId]);

  useEffect(() => {
    dispatch(updatePage(pageId!, pageDetails?.pageArea || '', section!));
    dispatch(clearPdfExports());
  }, [pageId]);

  useEffect(() => {
    dispatch(updateSection(section!));
    dispatch(clearPdfExports());
  }, [section]);

  useEffect(() => {
    if (
      pageRequested &&
      activeFund &&
      pageRequested.allowFundPages &&
      location.search.indexOf('fundId') === -1
    ) {
      navigate(
        `${location.pathname}${location.search.indexOf('?') === -1 ? '?' : ''}${
          location.search
        }&fundId=${activeFund.id}`,
      );
    }
  }, [activeFund, pageRequested]);

  useEffect(() => {
    if (!fundId) {
      dispatch(updateFund(section!, '', activeDate));
    } else {
      dispatch(updateFund(section!, fundId, activeDate));
    }
  }, [section, pageId, fundId]);

  if (pageRequested?.locked) return <PageLocked />;

  if (pageId === activePage)
    return (
      <GeneralComponentErrorShield
        dataObjects={[fundsData]}
        customLoadingMessages={[
          `Loading ${sectionDetails?.fundTypes} overview data...`,
        ]}
        customErrorMessage={'Failed to load overview data'}
      >
        <Grid container spacing={2} style={{ padding: 8 }}>
          {children}
        </Grid>
      </GeneralComponentErrorShield>
    );

  return null;
};

const PageLocked: FunctionComponent = () => {
  return (
    <div
      style={{
        ...flexify('center', 'center', 'column'),
        height: 'calc(100vh - 16rem)',
        width: '100%',
      }}
    >
      <Typography variant="h2" style={{ color: mainColors.mainBlue }}>
        Page Currently Unavailable
      </Typography>
    </div>
  );
};
