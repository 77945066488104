import {
  ComponentCSVIndex,
  ComponentIndex,
  PdfChartItem,
  PdfTableItem,
} from '../../types/redux/pdfExports/FileExportsStore';
import {
  ADD_CHART_TO_PDF_EXPORT,
  ADD_COMPONENT_EXPORT_CSV,
  ADD_COMPONENT_TO_PDF_EXPORT,
  ADD_TABLE_TO_PDF_EXPORT,
  AddChartToPdfExportAction,
  AddComponentExportCSVAction,
  AddComponentToPdfExportAction,
  AddTableToPdfExportAction,
  CLEAR_PDF_EXPORT,
  CLEAR_CSV_EXPORT,
  ClearPdfExportAction,
  ClearCSVExportAction,
} from '../../types/redux/pdfExports/exportTypes';

export function addTableToPdfExport<T extends object>(
  tableItem: PdfTableItem<T>,
): AddTableToPdfExportAction<T> {
  return {
    type: ADD_TABLE_TO_PDF_EXPORT,
    payload: tableItem,
  };
}

export function addChartToPdfExport(
  chart: PdfChartItem,
): AddChartToPdfExportAction {
  return {
    type: ADD_CHART_TO_PDF_EXPORT,
    payload: chart,
  };
}

export function clearPdfExports(): ClearPdfExportAction {
  return {
    type: CLEAR_PDF_EXPORT,
  };
}

export function addComponentToPdfExport(
  index: ComponentIndex,
): AddComponentToPdfExportAction {
  return {
    type: ADD_COMPONENT_TO_PDF_EXPORT,
    payload: index,
  };
}

export function addComponentExportCSV(
  component: ComponentCSVIndex,
): AddComponentExportCSVAction {
  return {
    type: ADD_COMPONENT_EXPORT_CSV,
    payload: component,
  };
}

export function clearCSVExports(): ClearCSVExportAction {
  return {
    type: CLEAR_CSV_EXPORT,
  };
}
