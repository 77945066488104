import FastAPIInstance from '../../../../services/risksystem3-public-api';
import { AxiosInstance } from '../../../../utilities/requestClient';

export const getMarketsOverviewData = async (selectedDate: string) => {
  return (await AxiosInstance.get(`markets/overview/${selectedDate}`)).data;
};

export interface NewsArticle {
  uuid: string;
  title: string;
  publisher: string;
  link: string;
  providerPublishTime: number;
  type: string;
  thumbnail?: any;
  relatedTickers: string[];
}

export const getMarketNewsData = async (news?: string) => {
  const endpoint = news
    ? `v1/markets/yahoo_finance_news?query=${news}`
    : `v1/markets/yahoo_finance_news`;
  return (await FastAPIInstance.get<NewsArticle[]>(endpoint)).data;
};
