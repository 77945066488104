import React, { useEffect, useState } from 'react';
import { SettingsComponentProps } from '../../SettingsRouter.component';
import makeStyles from '@mui/styles/makeStyles';
import PasswordSettings from './subComponents/PasswordSettings.component';
import LoginSettings from './subComponents/LoginSettings.component';
import InvitationSettings from './subComponents/InvitationSettings.component';
import { greys, mainColors } from '../../../../styling/theme';
import { useSelector } from 'react-redux';
import { clientNameSelector } from '../../../../redux/auth/selectors';
import TokenService from '../../../../services/token-service';
import FastAPIInstance from '../../../../services/risksystem3-public-api';

const useStyles = makeStyles(() => ({
  pageRoot: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    gap: '1rem',
  },
}));

export interface UserAuthPreferencesData {
  attribute_type: string;
  user_or_client_id: string;
  attribute_name: string;
  attribute_value: number;
  attribute_last_updating_datetime: string;
}

export const getAuthMessageColor = (authStatus: string): string => {
  switch (authStatus) {
    case 'success':
      return mainColors.Pass;
    case 'fail':
      return mainColors.Fail;
    default:
      return greys.grey600;
  }
};

const SecuritySettings: React.FC<SettingsComponentProps> = () => {
  const classes = useStyles();

  const clientName = useSelector(clientNameSelector) || 'mersenne';

  const [userAuthPreferences, setUserAuthPreferences] = useState<
    UserAuthPreferencesData[]
  >([]);

  const getUserAuthPreferences = () => {
    FastAPIInstance
      .get('/v1/settings/auth_pref_attrs/', {
        params: {
          client_id: TokenService.getUser().user_client_id,
        },
      })
      .then((response) => {
        if (response.data.length > 0) {
          setUserAuthPreferences(response.data);
        }
      });
  };
  useEffect(() => {
    getUserAuthPreferences();
  }, []);

  return (
    <div className={classes.pageRoot}>
      <PasswordSettings
        password_update_frequency_days={
          userAuthPreferences.find(
            (preference: UserAuthPreferencesData) =>
              preference.attribute_name === 'password_update_frequency_days',
          ) as UserAuthPreferencesData
        }
        reloadSettings={getUserAuthPreferences}
      />
      <LoginSettings
        maximum_failed_login_attempts={
          userAuthPreferences.find(
            (preference: UserAuthPreferencesData) =>
              preference.attribute_name === 'maximum_failed_login_attempts',
          ) as UserAuthPreferencesData
        }
        account_frozen_minutes={
          userAuthPreferences.find(
            (preference: UserAuthPreferencesData) =>
              preference.attribute_name === 'account_frozen_minutes',
          ) as UserAuthPreferencesData
        }
        two_factor_auth={
          userAuthPreferences.find(
            (preference: UserAuthPreferencesData) =>
              preference.attribute_name === '2fa_user_email',
          ) as UserAuthPreferencesData
        }
        reloadSettings={getUserAuthPreferences}
      />
      <InvitationSettings
        account_invitation_link_valid_period_minutes={
          userAuthPreferences.find(
            (preference: UserAuthPreferencesData) =>
              preference.attribute_name ===
              'account_invitation_link_valid_period_minutes',
          ) as UserAuthPreferencesData
        }
        forget_passwd_invitation_link_valid_period_minutes={
          userAuthPreferences.find(
            (preference: UserAuthPreferencesData) =>
              preference.attribute_name ===
              'forget_passwd_invitation_link_valid_period_minutes',
          ) as UserAuthPreferencesData
        }
        reloadSettings={getUserAuthPreferences}
      />
    </div>
  );
};

export default SecuritySettings;
