import React, { FC, useEffect } from 'react';
import { DataObject } from '../../../../../types/redux/data/dataTypes';
import PositionTable from './table/table.component';
import ExposureGraphics from './exposureGraphics/exposureGraphics.component';
import CreditRatingsTable from './creditRatings/creditRatings.component';
import { formatDateForCheckingState } from '../../../../../utilities/dateFormatters';
import AggregateFixedIncomeTable from './aggTable/aggTable.component';
import useTitleBarSelect from '../../../../../hooks/useTitleBarSelect';
import useFetchData from '../../../../../hooks/useFetchData';
import GeneralComponentErrorShield from '../../../../general/GeneralComponentErrorShield';
import useActivefund from '../../../../../hooks/useActiveFund';
import { useDispatch } from 'react-redux';
import { clearPdfExports } from '../../../../../redux/fileExport/actions';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../../types/components/componentTypes';
import { Select } from '../../../../../types/redux/pages/PagesStore';
import {
  addAdditionalTitleBarComponents,
  removeTitleBarComponent,
} from '../../../../../redux/pages/actions';
import {
  APPEND_NEW_SELECT_TO_TITLEBAR,
  REMOVE_SELECT_FROM_TITLEBAR,
} from '../../../../../types/redux/pages/pageTypes';

export interface FixedIncomeComponent {
  inputData: DataObject;
}

export const FIXED_INCOME_OPTIONS = [
  'Position Table',
  'Exposure Graphics',
  'Credit Ratings Table',
  'Bubble Chart',
];

export type FixedIncomePages =
  | 'positionTable'
  | 'exposureGraphics'
  | 'creditRatingsTable';

export enum FixedIncomeOption {
  PC_OF_NAV = 'Percentage of NAV',
  BASE_CURRENCY = 'Base Currency',
}

export const fiOptions = [
  FixedIncomeOption.PC_OF_NAV,
  FixedIncomeOption.BASE_CURRENCY,
];

const handleComponent = (selectedPage: string | null) => {
  switch (selectedPage) {
    case 'positionTable':
      return PositionTable;
    case 'exposureGraphics':
      return ExposureGraphics;
    case 'creditRatingsTable':
      return CreditRatingsTable;
    default:
      return PositionTable;
  }
};

const MancoFixedIncome: FC<GeneralOverviewPageRenderedComponentProps> = ({
  positionDate,
}) => {
  const dispatch = useDispatch();

  // Add the select components to the title bar
  useEffect(() => {
    const selectsToAdd: Select[] = [
      {
        titleBarTitle: 'Visualisation: ',
        titleBarKey: 'selectVisualisation',
        values: [
          {
            label: 'Position Table',
            value: 'positionTable',
          },
          {
            label: 'Exposure Graphics',
            value: 'exposureGraphics',
          },
          {
            label: 'Credit Ratings Table',
            value: 'creditRatingsTable',
          },
        ],
        displayOnOverviewPage: true,
        aValueIsAlwaysSelected: true,
        currentValue: 'positionTable',
      },
    ];

    dispatch(
      addAdditionalTitleBarComponents(
        selectsToAdd,
        APPEND_NEW_SELECT_TO_TITLEBAR,
      ),
    );

    return () => {
      dispatch(
        removeTitleBarComponent(
          'selectVisualisation',
          REMOVE_SELECT_FROM_TITLEBAR,
        ),
      );
    };
  }, []);

  const selectedPage = useTitleBarSelect('selectVisualisation');

  const SelectedComponent = handleComponent(selectedPage);

  useEffect(() => {
    dispatch(clearPdfExports());
  }, [selectedPage]);

  const fixedIncomeData = useFetchData({
    keyName: `manco_asset_class_fixed_income_data_${
      positionDate || formatDateForCheckingState(new Date())
    }`,
    url: `manco_level_asset_class_data/fixed_income${
      positionDate ? '/' + positionDate : ''
    }`,
    makeFetch: true,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[fixedIncomeData]}
      customErrorMessage={'No data found'}
    >
      <AggregateFixedIncomeTable
        currentPage={(selectedPage as FixedIncomePages) || 'positionTable'}
        inputData={fixedIncomeData}
      />
      <SelectedComponent inputData={fixedIncomeData} />
    </GeneralComponentErrorShield>
  );
};

export default MancoFixedIncome;
