const getLocalRefreshToken = () => {
  const userString = localStorage.getItem('user');
  const user = userString ? JSON.parse(userString) : null;
  return user?.refresh_token;
};

const getLocalAccessToken = () => {
  const access_token = localStorage.getItem('access_token');
  return access_token;
};

const setAccessToken = (new_token: string) => {
  localStorage.setItem('access_token', new_token);
};

const updateNewAccessToken = (token: string) => {
  const userString = localStorage.getItem('user');
  const user = userString ? JSON.parse(userString) : null;
  if (user) {
    user.access_token = token;
    localStorage.setItem('user', JSON.stringify(user));
  }
  localStorage.setItem('access_token', token);
};

const getUser = () => {
  const userString = localStorage.getItem('user');
  return userString ? JSON.parse(userString) : null;
};

const setUser = (user: any) => {
  localStorage.setItem('user', JSON.stringify(user));
  localStorage.setItem('authenticated', 'true');
};

const updateUserInfo = (userInfo: any) => {
  const userString = localStorage.getItem('user');
  let user = userString ? JSON.parse(userString) : null;
  if (user) {
    user = { ...user, ...userInfo };
    localStorage.setItem('user', JSON.stringify(user));
  }
};

const TokenService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  setAccessToken,
  updateNewAccessToken,
  getUser,
  setUser,
  updateUserInfo,
};

export default TokenService;
